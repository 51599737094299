import React, {useEffect} from 'react';
import {Divider} from "primereact/divider";
import {JSONSchema6} from "json-schema";
import {Button} from "primereact/button";
import {ActionDTO, ArgumentInfoDTO} from "../../../serviceapi";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {InputSwitch} from "primereact/inputswitch";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import notificationPopUp from "util/NotificationPopUp";
import {Tooltip} from "react-tooltip";
import Utils from "util/Utils";
import {InputTextarea} from "primereact/inputtextarea";

type ActionsProps = {
    action: ActionDTO;

    saveAction: (actions: Array<any>) => Promise<any>;
}

const inputDescriptionTooltip = (des?: string) => {
    if (des) {
        return <>
            <Tooltip id={des}/>
            <span data-tooltip-id={des} data-tooltip-content={des}>
                            <i className="pi pi-question-circle p-text-info"/>
                        </span>
        </>
    }
    return
}

const Action = ({action, saveAction}: ActionsProps) => {
    const Duration = require("duration-js");
    const Uuid = require('uuid');

    const [response, setResponse] = React.useState("");
    const [data, setData] = React.useState<any>({});
    useEffect(() => {
        console.log(data)
    }, [data]);

    useEffect(() => {
        validateAction();
    }, []);

    useEffect(() => {
        if (action.arguments && action.arguments.length > 0) {
            let defaults: any = {};
            for (let i = 0; i < action.arguments.length; i++) {
                let schema = (action.arguments[i].argumentSchema as JSONSchema6);
                let arg = action.arguments[i];


                if (schema.type === "string") {
                    if (schema.enum != null) {
                        defaults[`${arg.name}`] = ""
                        defaults[`${arg.name}` + `_enums`] = schema.enum;
                    } else if (schema.format === "date-time") {
                        defaults[`${arg.name}`] = new Date()
                    } else if (schema.format === "date") {
                        defaults[`${arg.name}`] = new Date()
                    } else {
                        defaults[`${arg.name}`] = ""
                    }
                } else if (schema.type === "number" || schema.type === "integer") {
                    defaults[`${arg.name}`] = undefined
                } else if (schema.type === "boolean") {
                    defaults[`${arg.name}`] = false
                }
            }
            setData(defaults)
        }
    }, [])

    const copyToClipboard = () => {
        navigator.clipboard.writeText(response).then(() => {
            notificationPopUp.show("Copied to clipboard");
        }).catch(() => {
            notificationPopUp.show("Failed to copy");
        });
    }

    const printArray = () => {
        if (action.arguments && action.arguments.length > 0) {
            return action.arguments.map((ob, index) => {
                const id = ob.name || "" + ob.description || "" + ob.argumentSchema;
                let arg = ob.argumentSchema as JSONSchema6;

                const renderInputField = () => {
                    if (arg.type === "string") {
                        if (arg.enum != null) {
                            return stringEnumTypeInput(ob);
                        } else if (arg.format === "date-time") {
                            return dateTimeTypeInput(ob);
                        } else if (arg.format === "date") {
                            return dateTypeInput(ob);
                        } else {
                            return stringTypeInput(ob);
                        }
                    } else if (arg.type === "number") {
                        return doubleTypeInput(ob);
                    } else if (arg.type === "integer") {
                        return integerTypeInput(ob);
                    } else if (arg.type === "boolean") {
                        return booleanTypeInput(ob);
                    } else {
                        return dateTimeTypeInput(ob);
                    }
                };

                return <div className="flex flex-column gap-1" key={index}>
                    <div className={"flex gap-1"}>
                        {ob.name && <label>{ob.name}</label>}
                        {ob.description &&
                            <>
                                <Tooltip id={id}/>
                                <i className="pi pi-info-circle" data-tooltip-id={id}
                                   data-tooltip-content={ob.description}></i>
                            </>
                        }
                    </div>
                    {renderInputField()}
                </div>
            });
        } else {
            return <></>;
        }
    };


    const stringTypeInput = (ob: ArgumentInfoDTO) => {
        if (ob.argumentSchema["x-long-string"] === true) {
            return <><InputTextarea placeholder={ob.name}
                                name={`${ob.name}`}
                                value={data[`${ob.name}`] || ""}
                                onChange={e => setData({...data, [e.target.name]: e.target.value})}
            />
                {inputDescriptionTooltip(ob.description)}
            </>
        } else {
            return <><InputText placeholder={ob.name}
                                name={`${ob.name}`}
                                value={data[`${ob.name}`] || ""}
                                onChange={e => setData({...data, [e.target.name]: e.target.value})}
            />
                {inputDescriptionTooltip(ob.description)}
            </>
        }
    }

    const stringEnumTypeInput = (ob: ArgumentInfoDTO) => {
        return <><Dropdown value={data[`${ob.name}`]}
                           options={data[`${ob.name}` + `_enums`] || []}
                           onChange={(e) => setData({...data, [`${ob.name}`]: e.target.value})}
                           placeholder={"Select a " + `${ob.name}`}/>
            {inputDescriptionTooltip(ob.description)}
        </>
    }

    const booleanTypeInput = (ob: ArgumentInfoDTO) => {
        return <><InputSwitch checked={data[`${ob.name}`]}
                              onChange={(e) => setData({...data, [`${ob.name}`]: e.target.value})}
        />
            {inputDescriptionTooltip(ob.description)}
        </>
    }

    const doubleTypeInput = (ob: ArgumentInfoDTO) => {
        return <><InputNumber value={data[`${ob.name}`]}
                              onValueChange={(e) => setData({...data, [`${ob.name}`]: e.target.value})}
                              placeholder={"Select a " + `${ob.name}`}
                              mode="decimal" maxFractionDigits={6}
        />
            {inputDescriptionTooltip(ob.description)}
        </>
    }

    const integerTypeInput = (ob: ArgumentInfoDTO) => {
        return <><InputNumber value={data[`${ob.name}`]}
                              onValueChange={(e) => setData({...data, [`${ob.name}`]: e.target.value})}
                              placeholder={"Select a " + `${ob.name}`}/>
            {inputDescriptionTooltip(ob.description)}
        </>
    }

    const dateTimeTypeInput = (ob: ArgumentInfoDTO) => {
        return <div>
            <Calendar dateFormat="yy-mm-dd"
                      value={data[`${ob.name}`]}
                      onChange={(e) => setData({...data, [`${ob.name}`]: e.value})}
                      showTime hourFormat="24"
                      placeholder={ob.name} showIcon
            >
            </Calendar>
            {inputDescriptionTooltip(ob.description)}
        </div>
    }

    const dateTypeInput = (ob: ArgumentInfoDTO) => {
        return <div>
            <Calendar dateFormat="yy-mm-dd"
                      value={data[`${ob.name}`]}
                      onChange={(e) => setData({...data, [`${ob.name}`]: e.value})}
                      placeholder={ob.name} showIcon
            >
            </Calendar>
            {inputDescriptionTooltip(ob.description)}
        </div>
    }

    const validateAction = () => {
        const returnee: any = {};

        for (const ob of action.arguments ?? []) {
            if (Object.hasOwnProperty.call(data, ob.name as string)) {
                const val = data[ob.name as string];
                const schema = ob.argumentSchema as JSONSchema6;
                console.log(schema, val, ob.name)
                if (
                    schema.type === "string" &&
                    ["duration", "uuid", "date-time", "date"].includes(schema.format!)
                ) {
                    if (schema.format === "duration") {
                        try {
                            if (val) {
                                Duration(val);
                                returnee[ob.name as string] = val;
                            }
                        } catch (e) {
                            notificationPopUp.show("Wrong duration format");
                            return;
                        }
                    } else if (schema.format === "uuid") {
                        if (val && !Uuid.validate(val)) {
                            notificationPopUp.show("Wrong UUID format");
                            return;
                        } else {
                            returnee[ob.name as string] = val;
                        }
                    } else if (schema.format === "date-time" || schema.format === "date") {
                        if (val) {
                            // const date = new Date(val);
                            // const year = date.getFullYear();
                            // const month = String(date.getMonth() + 1).padStart(2, '0');
                            // const day = String(date.getDate()).padStart(2, '0');
                            returnee[ob.name as string] = val.toISOString();
                        }
                    }
                } else {
                    if (!val && val !== false && val !== 0) {
                        continue;
                    }
                    returnee[ob.name as string] = val;
                }
            }
        }

        if (Object.keys(returnee).length === 0 && action.arguments?.length) {
            notificationPopUp.show("All fields are empty");
        } else {
            saveAction(returnee)
                .then(res => {
                    if (res != null) {
                        setResponse(typeof res === "string" ? res : JSON.stringify(res, null, 2))
                    }
                })
        }
    }

    return <div className="flex flex-column">
        {response && response.length > 0 &&
            <pre className={"m-0"}>
                <div className={"pb-2 flex gap-2"}>
                    <b>RESPONSE</b>
                    <i className="pi pi-clone"
                       onClick={copyToClipboard}
                       style={{cursor: "pointer"}}
                    />
                </div>
                {response}
                <Divider/>
            </pre>
        }

        {(action.description || action.name || action.id) && (
            <div>
                <div style={{borderRadius: '5px', background: '#20262e', whiteSpace:"break-spaces"}} className="pl-2 pr-2 pt-1 pb-1">
                    {action.name || Utils.camelToHuman(action.id)}
                    {(action.name || action.id) && action.description && " - "}
                    {action.description}
                </div>
                <Divider/>
            </div>
        )}

        <div className="flex flex-column gap-3">
            {action.arguments && action.arguments.length > 0 && (
                <div className="flex flex-column gap-2">
                    {printArray()}
                </div>
            )}

            <Button label="Execute" onClick={() => validateAction()}/>
        </div>
    </div>
};

export default Action;