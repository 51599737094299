import { Buffer } from 'buffer';

const DEV_MODE = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
const CONTEXT_ROOT = process.env.PUBLIC_URL || '/'
export default class Utils {
    static stringCutFromStart(str: string, n: number) {
        return (str.length > n) ? str.substring(0, n - 1) + "..." : str;
    }

    static editorHaveErrors = (editorHasError: boolean) => {
        if (editorHasError) {
            throw Error("Configuration is not valid")
        }
    }

    private static checkIfIdChanged = (configNew: any, configOld: any) => {
        if (configNew.id !== configOld.id) {
            throw Error(`Reader id change is not allowed.\n` +
                `Previous value: [${configOld.id}]\n` +
                `Current value: [${configNew.id}]`);
        }
    }

    private static checkIfProtocolChanged = (configNew: any, configOld: any) => {
        if (configNew.implementationKey !== configOld.implementationKey) {
            throw Error(`Reader protocol change\nis not allowed.\n` +
                `Previous value: [${configOld.implementationKey}]\n` +
                `Current value: [${configNew.implementationKey}]`)
        }
    }

    private static checkIfIdNameExists = (config: any) => {
        if (!config.id) {
            throw Error("Reader id property\n['id'] must be specified")
        } else if (!config.readerName) {
            throw Error("Reader name property\n['readerName'] must be specified")
        }
    }

    static checkConfigValidation(configNew: any, configOld?: any) {
        this.checkIfIdNameExists(configNew);
        if (configOld) {
            this.checkIfIdChanged(configNew, configOld);
            this.checkIfProtocolChanged(configNew, configOld);
        }
    }

    static timestampToReadableDate = (timestamp: number) => {
        let date = new Date(timestamp);
        let returnDate = new Date(timestamp);

        returnDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());

        let returnISO = returnDate.toISOString();

        returnISO = returnISO.replace("T", " ");
        returnISO = returnISO.split('.')[0];

        return returnISO;
    }

    static presentDateToReadableDate = () => {
        let date = new Date();
        let returnDate = new Date();

        returnDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());

        let returnISO = returnDate.toISOString();

        returnISO = returnISO.replace("T", " ");
        returnISO = returnISO.split('.')[0];

        return returnISO;
    }

    static presentDateToStringDate = () => {
        let dateString = this.presentDateToReadableDate();
        dateString = dateString.replaceAll("-", "_");
        dateString = dateString.replace(" ", "_");
        dateString = dateString.replaceAll(":", "_");
        return dateString;
    }

    static downloadFile = (file: any) => {
        const link = document.createElement('a')
        const url = URL.createObjectURL(file)

        link.href = url
        link.download = file.name
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
    }

    static formatISOToBasicDateFormat = (oldDate: string): string => {
        let dateFromString = new Date(oldDate);
        let returnDate = new Date(oldDate);

        returnDate.setMinutes(dateFromString.getMinutes() - dateFromString.getTimezoneOffset());

        let returnISO = returnDate.toISOString();

        returnISO = returnISO.replace("T", " ");
        returnISO = returnISO.split('.')[0];

        return returnISO;
    }

    static camelToHuman(str?: string): string {
        if (!str) {
            return ""
        }
        const capitalize = (word: string) => {
            return word.charAt(0).toUpperCase() + word.substring(1);
        }

        let words = str.match(/[A-Za-z][a-z]*/g) || [];

        return words.map(capitalize).join(" ");
    }

    static encodeIdentifierIntoString(str: string) {
        return str.replace(/[:_]/g, "");
    }

    //IDENTIFIERS (HARDCODE)
    static DatasetClass: Object = Object.freeze({
        1: "ADVANCED_DATASET",
        2: "CATEGORIZED_DATASET",
        3: "ELECTRICITY_DATASET",
        4: "PROCESSED_DATASET",
        5: "PROCESSED_INTEGRAL_DATASET",
        6: "PROCESSED_PRIORITY_FIRST_DATASET",
        7: "PROCESSED_PYTHON_DATASET",
        8: "CATEGORIZED_STRING_DATASET",
        9: "REMOTE_DATASET",
        10: "REMOTE_STRING_DATASET",
        11: "REMOTE_CATEGORIZED_DATASET"
    });

    static validateIdentifier = (identifier: string) => {
        let testIdentifierArr = identifier.split(":");
        if (testIdentifierArr.length === 2) {
            if (Object.values(this.DatasetClass).includes(testIdentifierArr[1]) &&
                !isNaN(+testIdentifierArr[0])) {
                return true
            }
        }
        return false
    }

    static lineOf(text: string, keyword: string) {
        let line = 0, matchedChars = 0;

        for (let i = 0; i < text.length; i++) {
            text[i] === keyword[matchedChars] ? matchedChars++ : matchedChars = 0;

            if (matchedChars === keyword.length) {
                return line;
            }
            if (text[i] === '\n') {
                line++;
            }
        }

        return -1;
    }

    static isDevMode = () => {
        return DEV_MODE;
    }

    static getContextPath = () => {
        return CONTEXT_ROOT
    }

    static setDefaultProcessingConfig = (config: any) => {
        if (config.implementationKey === "ea-scripting") {
            return {
                type: "python",
                flags: {
                    "--input_file_path": "$input_file",
                    "--output_file_path": "$output_file"
                },
                script: "",
                encoding: "BASE64",
                settings: {},
                executable: "python3",
                environment: {},
                flagSeparator: " ",
                implementationKey: "ea-scripting"
            }
        }

        return config
    }

    static downloadStringToTxt = (text: string, filename: string) => {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    static base64Encode = (input: string) => {
        return Buffer.from(input, 'utf8').toString('base64')
    }

    static base64Decode = (input: string) => {
        return Buffer.from(input, 'base64').toString('utf8')
    }
}
