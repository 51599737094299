import { Button } from 'primereact/button';
import { Menu } from "primereact/menu";
import React, { RefObject } from 'react';
import { Link } from "react-router-dom";
import DataCollector from "../../data-collector/DataCollector/DataCollector";
import DataWriter from "../../data-writer/DataWriter/DataWriter";

import { SelectButton } from "primereact/selectbutton";
import UserService from "../../../service/UserService";
import AppDialogs from "../../dialogs/AppDialogs";
import './AppHeader.scss';

type MenuType = {
    label: string;
    icon: string;
    command: (event: any) => void;
    disabled?: boolean;
}

type PageProps = {
    dataCollectorRef: RefObject<DataCollector>
    dataWriterRef: RefObject<DataWriter>
}

type AppHeaderState = {
    value: string;
    dialogName: string | undefined;
}

class AppHeader extends React.Component<PageProps, AppHeaderState> {
    private readonly menuReader = React.createRef<Menu>();
    private readonly menuWriter = React.createRef<Menu>();

    private readonly settingsReader: MenuType[]
    private readonly settingsWriter: MenuType[]
    private readonly dataPages: any

    constructor(props: any) {
        super(props);
        
        this.settingsReader = [
            {
                label: 'Reload Readers',
                icon: 'pi pi-fw pi-sync',
                command: () => {
                    console.log("RR")
                    this.props.dataCollectorRef.current?.restartCollector();
                }
            },
            {
                label: 'Server Info',
                icon: 'pi pi-fw pi-info-circle',
                command: () => {
                    this.setState({dialogName: "info"})
                }
            },
            {
                label: 'Manual',
                icon: 'pi pi-fw pi-map',
                command: () => {
                    window.open(process.env.REACT_APP_MANUAL_URL, "_blank")
                }
            },
            {
                label: 'Collector Logs',
                icon: 'pi pi-fw pi-book',
                command: () => {
                    this.setState({dialogName: "allCollectorLogs"})
                }
            },
            {
                label: 'Post Processors management',
                icon: 'pi pi-fw pi-cog',
                command: () => {
                    this.setState({dialogName: "postProcessorsManagement"})

                }
            },
            {
                label: 'Import',
                icon: 'pi pi-fw pi-download',
                command: () => {
                    this.setState({dialogName: "import"})
                }
            },
            {
                label: 'Export',
                icon: 'pi pi-fw pi-upload',
                command: () => {
                    this.setState({dialogName: "export"})
                }
            },
            {
                label: 'Ping',
                icon: 'pi pi-fw pi-refresh',
                command: () => {
                    this.setState({dialogName: "ping"})
                }
            },
            {
                label: 'Traceroute',
                icon: 'pi pi-fw pi-refresh',
                command: () => {
                    this.setState({dialogName: "traceroute"})
                }
            }, {
                label: 'Test tag remapping',
                icon: 'pi pi-fw pi-refresh',
                command: () => {
                    this.setState({dialogName: "testTagRemapping"})
                }
            },
            {
                label: 'Restart Reader',
                icon: 'pi pi-fw pi-refresh',
                command: () => {
                    this.props.dataCollectorRef.current?.shutdownReader()
                }
            }
        ];

        this.settingsWriter = [
            {
                label: 'Reload Pipelines',
                icon: 'pi pi-fw pi-sync',
                command: () => {
                    this.props.dataWriterRef.current?.restartWriter();
                }
            },
            {
                label: 'Server Info',
                icon: 'pi pi-fw pi-info-circle',
                command: () => {
                    this.setState({dialogName: "info"})
                }
            },
            {
                label: 'Manual',
                icon: 'pi pi-fw pi-map',
                command: () => {
                    window.open(process.env.REACT_APP_MANUAL_URL, "_blank")
                }
            },
            {
                label: 'Writer logs',
                icon: 'pi pi-fw pi-book',
                command: () => {
                    this.setState({dialogName: "allWriterLogs"})
                }
            },
            {
                label: 'Import',
                icon: 'pi pi-fw pi-download',
                command: () => {
                    this.setState({dialogName: "import"})
                }
            },
            {
                label: 'Export',
                icon: 'pi pi-fw pi-upload',
                command: () => {
                    this.setState({dialogName: "export"})
                }
            },
            {
                label: 'Ping',
                icon: 'pi pi-fw pi-refresh',
                command: () => {
                    this.setState({dialogName: "ping"})
                }
            },
            {
                label: 'Traceroute',
                icon: 'pi pi-fw pi-refresh',
                command: () => {
                    this.setState({dialogName: "traceroute"})
                }
            },
            {
                label: 'Base64',
                icon: 'pi pi-fw pi-qrcode',
                command: () => {
                    this.setState({dialogName: "base64"})
                }
            },
            {
                label: 'Restart Writer',
                icon: 'pi pi-fw pi-refresh',
                command: () => {
                    this.props.dataWriterRef.current?.shutdownWriter()
                }
            }
        ];

        this.dataPages = [
            {
                name: 'Data Collector',
                value: "data_collector",
                route: ""
            },
            {
                name: 'Data Writer',
                value: "data_writer",
                route: "data_writer"
            }
        ];

        this.state = {
            value: this.getPage(),
            dialogName: undefined
        }
    }

    private goToPage = (data: any) => {
        return <Link to={data.route} style={{textDecoration: "none", color: "white"}}>{data.name}</Link>
    }

    private getPage = () => {
        if (window.location.hash === "#/data_writer") {
            return "data_writer";
        } else {
            return "data_collector";
        }
    }

    render() {
        return (
            <div className="card header-full-window">
                <div className={"header-full-window-left-side"}>
                    <img alt="logo"
                         src={process.env.PUBLIC_URL + "/EASAS.png"}
                         height={"35px"}
                         className="mr-1"/>
                    <SelectButton value={this.getPage()}
                                  options={this.dataPages}
                                  onChange={(e) => {
                                      this.setState({value: e.value});
                                  }}
                                  optionLabel="name"
                                  unselectable={false}
                                  itemTemplate={this.goToPage}
                    />
                </div>
                <div className="flex justify-content-between header-full-window-right-side">
                    <Button label="Utilities"
                            className="p-button-sm button-secondary-homemade"
                            style={{transform: "scale(95%)", fontWeight: "bolder"}}
                            icon="pi pi-cog"
                            onClick={(event) => {
                                if (this.getPage() === "data_collector") {
                                    this.menuReader.current?.toggle(event)
                                } else if (this.getPage() === "data_writer") {
                                    this.menuWriter.current?.toggle(event)
                                }

                            }}
                            aria-controls="popup_menu"
                            aria-haspopup
                    />

                    {UserService.isLoggedIn() &&
                        <Button icon="pi pi-power-off"
                                className="p-button-plain p-button-rounded p-button-danger ml-2"
                                onClick={() => UserService.doLogout()}

                        />
                    }
                </div>


                <Menu model={this.settingsReader} popup ref={this.menuReader} id="popup_menu_reader"/>
                <Menu model={this.settingsWriter} popup ref={this.menuWriter} id="popup_menu_writer"/>
                <AppDialogs dialogName={this.state.dialogName}
                            close={() => {
                                this.setState({dialogName: undefined});
                            }}
                />
            </div>
        );
    }
}

export default AppHeader;
