import React, {useId, useState} from 'react';
import {Button} from "primereact/button";
import {Reader} from "reader/Reader";
import {Dialog} from "primereact/dialog";
import {DialogHeaderHandler} from "components/dialogs/dialogHeaders";
import ReaderRemove from "components/data-collector/ReaderRemove/ReaderRemove";
import {apisStore} from "context/GlobalStates";
import {Tooltip} from "react-tooltip";

type RemoveCollectorButtonProps = {
    editingReader?: Reader,
    updateReaders(): void,
}

const RemoveCollectorButton = ({editingReader, updateReaders}: RemoveCollectorButtonProps) => {
    const id = useId()
    const apis = apisStore().dataCollector as any;
    const [visible, setVisible] = useState(false);

    return <>
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <Button
            className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
            icon={"pi pi-trash"}
            data-tooltip-id={id}
            data-tooltip-content={"Remove"}
            onClick={() => setVisible(true)}
            disabled={editingReader == null}
        />

        {visible && editingReader &&
            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                style={{minWidth: "16vw"}}
                header={DialogHeaderHandler("remove", editingReader)}
            >
                <ReaderRemove reader={editingReader}
                              managementApi={apis.managementApi}
                              updateReaders={updateReaders}
                              closeDialog={() => setVisible(false)}/>

            </Dialog>
        }
    </>
};

export default RemoveCollectorButton;