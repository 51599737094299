import React from 'react';

import {DataTable} from "primereact/datatable";
import {Column, ColumnEditorOptions} from "primereact/column";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";

import NotificationPopUp from "../../../util/NotificationPopUp";
import notificationPopUp from "../../../util/NotificationPopUp";
import Utils from "../../../util/Utils";
import BasicTooltip from "../../general/BasicTooltip";

import HttpService from "../../../service/HttpService";
import {dataInfoRecent, datasetExists} from "../../../axios/DatasetDataApi";

import './PipelineInputTester.scss';
import Papa from "papaparse";


type ExportObject = {
    Key: string,
    Title: string,
    Identifier: string,
    Date: string,
    Value: string
}

type DataWriterInputTesterProps = {
    datasets?: Array<any>;
    basePath: string;
    authToken: string;
}

class DataWriterInputTesterState {
    datasets: Array<any> = [];
    buttonsDisable: boolean = false;
    identifier: string = "";
    inputText: boolean = false;
    updating: boolean = true;
}

class PipelineInputTester extends React.Component<DataWriterInputTesterProps, DataWriterInputTesterState> {
    private axiosConst: any;
    private reload: boolean = false;
    private firstReload: boolean = false;
    private mounted = false;

    constructor(props: Readonly<DataWriterInputTesterProps> | DataWriterInputTesterProps) {
        super(props);
        this.state = new DataWriterInputTesterState();
    }

    componentDidMount() {
        if (!this.mounted) {
            this.axiosConst = HttpService.get_EASASAxios(this.props.basePath, this.props.authToken);

            if (this.props.datasets) {
                this.setState({
                    datasets: [...this.props.datasets.map((obj: any) => {
                        let tempObj: any;
                        tempObj = {key: obj.key}
                        tempObj.identifier = obj.databaseId + ":" + obj.datasetClass
                        return tempObj
                    })],
                    updating: false
                })
                this.reload = true;
            } else {
                this.setState({updating: false})
            }
            this.mounted = true;
        }
    }

    componentDidUpdate(prevProps: Readonly<DataWriterInputTesterProps>, prevState: Readonly<DataWriterInputTesterState>, snapshot?: any) {
        if (this.reload || prevState.datasets.length !== this.state.datasets.length) {
            this.updateDataInfoRecent();
            this.reload = false;
        }
    }

    private updateDataInfoRecent = () => {
        this.setState({updating: true});
        let identifiers = this.state.datasets.map(ob => ob.identifier);
        if (identifiers.length === 0) {
            this.setState({updating: false})
            return
        }

        dataInfoRecent(this.axiosConst, identifiers, {strict: false})
            .then(({data, error}) => {
                if (error) {
                    notificationPopUp.show(error.message, "error")
                    return
                } else if (data) {
                    this.setState({
                        datasets: this.state.datasets.map((ob) => {
                            return {...ob, ...data.find(dataObject => dataObject.identifier === ob.identifier)}
                        })
                    })

                    if (this.firstReload) {
                        NotificationPopUp.show("Writer tester updated", "success");
                    } else {
                        this.firstReload = true
                    }
                }
            })
            .finally(() => this.setState({updating: false}));
    }

    private cellEditor = (options: ColumnEditorOptions) => {
        return <InputText type="text"
                          className={"cell-edit-class"}
                          value={options.value}
                          onChange={(e) => {
                              e.persist();
                              if (options.editorCallback) {
                                  return options.editorCallback(e.target.value);
                              }
                          }}
                          style={{width: "100%"}}
        />;
    }

    private onCellEditComplete = (e: any) => {
        if (e.newValue.length === 0) {
            NotificationPopUp.show("Field is empty")
            return
        }

        if (e.newValue === e.value) {
            NotificationPopUp.show("Identifier haven't been changed")
            return
        }

        if (!Utils.validateIdentifier(e.newValue)) {
            NotificationPopUp.show("Invalid identifier structure")
            return
        }

        datasetExists(this.axiosConst, e.newValue)
            .then(res => {
                if (!res) {
                    NotificationPopUp.show(`Identifier [${e.newValue}] doesn't exists`)
                    return
                }
                let rows = [...this.state.datasets];
                rows = rows.filter(ob => ob.identifier !== e.value);
                rows.push({identifier: e.newValue.trim()});
                this.setState({datasets: rows})
                this.reload = true;
            })

    }

    private minusRow = (rowData: any) => {
        return <i className={"pi pi-times"} onClick={() => this.deleteRow(rowData)}/>
    }

    private deleteRow = (rowData: any) => {
        if (!this.state.updating) {
            let newTempIdentifiers = [...this.state.datasets].filter(e => e.identifier !== rowData.identifier)
            this.setState({datasets: newTempIdentifiers})
        }
    }

    private plusButton = () => {
        if (this.state.inputText) {
            if (this.state.identifier.length > 0) {
                this.addRow();
            } else {
                this.setState({inputText: false})
            }
        } else {
            this.setState({inputText: true})
        }
    }

    private addRow = () => {
        this.setState({updating: true});
        if (this.state.datasets.find((ob: any) => ob.identifier === this.state.identifier.trim())) {
            NotificationPopUp.show("Identifier duplicate")
            this.setState({updating: false})
            return
        }
        if (!Utils.validateIdentifier(this.state.identifier.trim())) {
            NotificationPopUp.show("Bad identifier")
            this.setState({updating: false})
            return
        }

        datasetExists(this.axiosConst, this.state.identifier)
            .then((res) => {
                this.setState({updating: false})
                if (!res) {
                    notificationPopUp.show("Dataset doesn't exist", "error")
                    return
                }

                let rows = [...this.state.datasets];
                rows.push({identifier: this.state.identifier.trim()})
                this.setState({datasets: rows, identifier: ""})
            })
    }

    //---- Table templates -----------
    private tableTitleColumnBody = (rowData: any) => {
        let newMap = new Map();
        if (rowData.title) {
            newMap.set("Title", rowData.title)
        }
        if (rowData.identifier) {
            newMap.set("Identifier", rowData.identifier)
        }
        return <div className={"table-title-column-body"}>
            {rowData.key || "-"}
            <span id={`dataset-label` + Utils.encodeIdentifierIntoString(rowData.identifier)}>
                <i className={`pi pi-info-circle`}></i>
            </span>
            <BasicTooltip targetId={`dataset-label` + Utils.encodeIdentifierIntoString(rowData.identifier)}
                          text={newMap}/>
        </div>
    }

    private tableDateColumnBody = (data: any) => {
        if (data) {
            if (data.length > 0) {
                return Utils.formatISOToBasicDateFormat(data[0].key);
            } else {
                return "No data"
            }
        } else {
            return <div className={"not-found"}>Not found</div>
        }
    }

    private tableValueColumnBody = (rowData: any) => {
        if (rowData.data) {
            if (rowData.data.length > 0) {
                return <>
                    {rowData.data[0].value.toFixed(rowData.decimalPlaces != null ? rowData.decimalPlaces : 2)}
                    {rowData.unit ? rowData.unit : ""}
                </>;
            } else {
                return "No data"
            }

        } else {
            return <span className={"not-found"}>Not found</span>
        }
    }

    private exportCSV() {
        let tempDate: Array<ExportObject> = [...this.state.datasets.map(ob => {
            let tempOb: ExportObject = {Key: "-", Title: "-", Identifier: "-", Date: "-", Value: "-"}

            if (ob.key) {
                tempOb.Key = ob.key;
            }

            if(ob.title) {
                tempOb.Title = ob.title;
            }

            if(ob.identifier) {
                tempOb.Identifier = ob.identifier;
            }

            if (ob.data) {
                if (ob.data.length > 0) {
                    tempOb.Date = Utils.formatISOToBasicDateFormat(ob.data[0].key);
                    tempOb.Value = `${ob.data[0].value.toFixed(ob.decimalPlaces != null ? ob.decimalPlaces : 2)} ${ob.unit ? ob.unit : ""}`;
                } else {
                    tempOb.Date = "No data";
                    tempOb.Value = "No data";
                }
            } else {
                tempOb.Date = "Not found";
                tempOb.Value = "Not found";
            }

            return tempOb
        })]

        const csv = Papa.unparse(tempDate);
        const blob = new Blob([csv], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "dataWriterTesterData.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        return (
            <div className={"full-window-writer-tester"}>
                <div className={"buttons-container"}>
                    <div className={"identifier-add"}>
                        <Button className={"p-button-sm"}
                                icon={"pi pi-plus"}
                                onClick={this.plusButton}
                                tooltip={this.state.inputText ? "Add" : "Add new identifier"}
                                tooltipOptions={{position: "bottom"}}
                                disabled={this.state.buttonsDisable}
                                loading={this.state.updating}
                        />
                        {this.state.inputText &&
                            <InputText type="text"
                                       className={"identifier-input"}
                                       value={this.state.identifier}
                                       onChange={(e) => {
                                           this.setState({identifier: e.target.value})
                                       }}
                                       placeholder={"Dataset Identifier"}
                                       onKeyDown={(e) => {
                                           if (e.code === "Enter" || e.code === "NumpadEnter") {
                                               this.plusButton()
                                           }
                                       }}
                            />
                        }
                    </div>
                    <div className={"right-side-functions"}>
                        <Button className={"p-button-sm even-smaller-button"}
                                label={"Export CSV"}
                                onClick={() => this.exportCSV()}
                        />
                        <Button className={"p-button-sm"}
                                icon={"pi pi-refresh"}
                                onClick={this.updateDataInfoRecent}
                                tooltip={"Refresh table"}
                                tooltipOptions={{position: "left"}}
                                loading={this.state.updating}
                        />
                    </div>
                </div>

                <div className={"data-table-flex"}>
                    <DataTable value={this.firstReload ? this.state.datasets : []}
                               responsiveLayout="scroll"
                               showGridlines
                               size={"small"}
                               editMode={"cell"}
                    >
                        <Column header={"Key"}
                                field={"identifier"}
                                editor={(options: ColumnEditorOptions) => this.cellEditor(options)}
                                onCellEditComplete={this.onCellEditComplete}
                                body={this.tableTitleColumnBody}
                        />
                        <Column header={"Date"}
                                style={{width: "10px", whiteSpace: "nowrap"}}
                                body={(rowData) => this.tableDateColumnBody(rowData.data)}
                        />

                        <Column header={"Value"}
                                body={this.tableValueColumnBody}
                                style={{width: "10px", whiteSpace: "nowrap"}}
                        />

                        <Column style={{width: "10px", whiteSpace: "nowrap"}}
                                body={this.minusRow}
                        />
                    </DataTable>
                </div>
            </div>
        );
    }
}

export default PipelineInputTester;