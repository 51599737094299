import React, {useEffect, useId, useState} from 'react';
import {Menu} from "primereact/menu";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import Utils from "util/Utils";
import {Reader} from "reader/Reader";
import {apisStore} from "context/GlobalStates";
import {ActionDTO, ActionsApi} from "serviceapi";
import {Tooltip} from "react-tooltip";
import Action from "components/general/Actions/Action";
import notificationPopUp from "util/NotificationPopUp";
import {CLIENT_TIMEOUT, DEFAULT_OPTIONS} from "../../../../service/HttpService";

type ReaderActionsButtonProps = {
    editingReader?: Reader
}

const ReaderActionsButton = ({editingReader}: ReaderActionsButtonProps) => {
    const id = useId()
    const api = (apisStore().dataCollector as any).actionsApi as ActionsApi;
    const menu = React.useRef<Menu>(null);

    const [visible, setVisible] = useState(false);
    const [items, setItems] = useState<Array<any>>([]);
    const [selectedAction, setSelectedAction] = React.useState<ActionDTO>()

    useEffect(() => {
        if (editingReader?.readerInfo.implementationKey) {
            api.restActionsSchemaGet(editingReader.readerInfo.implementationKey, DEFAULT_OPTIONS)
                .then((e: any) => {
                    setItems(e.data.map((action: ActionDTO) => {
                        return {
                            label: Utils.camelToHuman(action.id),
                            command: () => {
                                setSelectedAction(action)
                                setVisible(true)
                            }
                        }
                    }))
                })
        }
    }, [editingReader])

    const saveAction = async (data: Array<any>): Promise<any> => {
        if (!selectedAction || selectedAction.id == null || !editingReader?.readerInfo.id) {
            notificationPopUp.show("Missing reader ID", "error");
            setVisible(false);
            return;
        }

        try {
            const response = await api?.restActionsInvokeAReaderIdBActionIdPost(
                editingReader?.readerInfo.id,
                selectedAction.id,
                data,
                DEFAULT_OPTIONS
            );

            if (response && response.status && response.status < 300) {
                const responseData = response.data;
                notificationPopUp.show("Execution successful", "success");

                return responseData;
            } else {
                notificationPopUp.show("Execution failed", "error");
            }
        } catch (error: any) {
            notificationPopUp.show(
                error?.response?.data?.message || error?.message || "An error occurred",
                "error"
            );
        }

        return null;
    };


    return <>
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <Menu model={items}
              popup
              ref={menu}
              id="overlay_menu"/>

        <Button icon={"pi pi-bolt"}
                className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
                data-tooltip-id={id}
                data-tooltip-content={"Actions"}
                onClick={(event) => {
                    editingReader?.readerInfo.enabled
                        ?
                        menu.current?.toggle(event)
                        :
                        notificationPopUp.show("Please turn it on to use", "error", "Reader is currently shut down");
                }}
                aria-haspopup
                disabled={editingReader == null || items.length === 0}
                aria-controls="overlay_menu"
        />

        {visible && editingReader && selectedAction &&
            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                header={"Action"}
                className={"flex w-30rem"}
            >
                <Action action={selectedAction} saveAction={saveAction}/>
            </Dialog>
        }
    </>
};

export default ReaderActionsButton;