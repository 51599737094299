import React, {useId} from 'react';
import {Button} from "primereact/button";
import {Tooltip} from "react-tooltip";

type WriterPipelineRefreshButtonProps = {
    selectedPipeline: any,
    restartCallback: () => void
}

const WriterPipelineRefreshButton = ({selectedPipeline,restartCallback}:WriterPipelineRefreshButtonProps) => {
    const id = useId()

    return <>
        <Button
            className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
            icon={"pi pi-refresh"}
            data-tooltip-id={id}
            data-tooltip-content="Restart"
            onClick={() => restartCallback()}
            disabled={!selectedPipeline}
        />
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
    </>
};

export default WriterPipelineRefreshButton;