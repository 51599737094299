import ToolInvoker from "components/general/ToolInvoker/ToolInvoker";
import { Dialog } from "primereact/dialog";
import React from "react";
import DataImport from "components/general/DataImport/DataImport";
import DataExport from "components/general/DataExport/DataExport";
import {
  HideLoadingIndicator,
  LogHeaderHandler,
  ShowLoadingIndicator,
  SpinnerHeader,
} from "./dialogHeaders";
import Apis, { apisType } from "serviceapi/ApiImports";
import { apisStore } from "context/GlobalStates";
import Base64 from "components/general/Base64/Base64";
import ReaderLogViewer from "components/data-collector/ReaderLogs/ReaderLogViewer";
import PipelineLogViewer from "components/data-writer/PipelineLogs/PipelineLogViewer";
import ServerInfo from "components/general/Info/ServerInfo";
import TagMappingTester from "../general/TagMappingTester/TagMappingTester";
import PostProcessorsManagement from "components/post-processors/post-processors-management/PostProcessorsManagement";

type AppDialogsProps = {
  dialogName: string | undefined;
  close(): void;
};

class AppDialogs extends React.Component<AppDialogsProps> {
  private dataCollectorApi: apisType;
  private dataPusherApi: apisType;

  constructor(props: any) {
    super(props);
    this.dataCollectorApi = apisStore.getState().dataCollector;
    this.dataPusherApi = apisStore.getState().dataPusher;
  }

  render() {
    if (this.props.dialogName) {
      if (this.props.dialogName === "ping") {
        return <PingDialog close={this.props.close} />;
      } else if (this.props.dialogName === "traceroute") {
        return <TracerouteDialog close={this.props.close} />;
      } else if (this.props.dialogName === "testTagRemapping") {
        return <TestTagRemapperDialog close={this.props.close} />;
      } else if (this.props.dialogName === "import") {
        return <ImportData close={this.props.close} />;
      } else if (this.props.dialogName === "export") {
        return <ExportData close={this.props.close} />;
      } else if (this.props.dialogName === "allCollectorLogs") {
        return <AllCollectorLogs close={this.props.close} />;
      } else if (this.props.dialogName === "postProcessorsManagement") {
        return <PostProcessorsManagement close={this.props.close} />;
      } else if (this.props.dialogName === "allWriterLogs") {
        return <AllWriterLogs close={this.props.close} />;
      } else if (this.props.dialogName === "base64") {
        return <Base64Dialog close={this.props.close} />;
      } else if (this.props.dialogName === "info") {
        return <ServerInfoDialog close={this.props.close} />;
      }
    } else {
      return;
    }
  }
}

type DialogProps = {
  close(): void;
};

class PingDialog extends React.Component<DialogProps> {
  private dataCollectorApi: apisType;
  private dataPusherApi: apisType;

  constructor(props: any) {
    super(props);
    this.dataCollectorApi = apisStore.getState().dataCollector;
    this.dataPusherApi = apisStore.getState().dataPusher;
  }

  render() {
    return (
      <Dialog
        visible
        onHide={() => this.props.close()}
        style={{ minWidth: "30vw", maxHeight: "70vh" }}
        resizable={false}
        header={"Ping"}
        breakpoints={{ "960px": "85vw", "640px": "100vw" }}
      >
        <ToolInvoker
          toolsApi={this.dataCollectorApi.toolsApi!!}
          startFunction={(toolsApi, props) => toolsApi.startPing(props)}
        />
      </Dialog>
    );
  }
}

class TracerouteDialog extends React.Component<DialogProps> {
  private dataCollectorApi: apisType;
  private dataPusherApi: apisType;

  constructor(props: any) {
    super(props);
    this.dataCollectorApi = apisStore.getState().dataCollector;
    this.dataPusherApi = apisStore.getState().dataPusher;
  }

  render() {
    return (
      <Dialog
        visible
        onHide={() => this.props.close()}
        style={{ minWidth: "30vw", maxHeight: "70vh" }}
        resizable={false}
        header={"Traceroute"}
        breakpoints={{ "960px": "85vw", "640px": "100vw" }}
      >
        <ToolInvoker
          toolsApi={this.dataCollectorApi.toolsApi!!}
          startFunction={(toolsApi, props) => toolsApi.startTraceroute(props)}
        />
      </Dialog>
    );
  }
}

class TestTagRemapperDialog extends React.Component<DialogProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <Dialog
        visible
        onHide={() => this.props.close()}
        style={{ minWidth: "50vw", maxHeight: "70vh" }}
        resizable={false}
        header={"Test Tag Remapper"}
        breakpoints={{ "960px": "85vw", "640px": "100vw" }}
      >
        <TagMappingTester />
      </Dialog>
    );
  }
}

class ImportData extends React.Component<DialogProps> {
  render() {
    return (
      <Dialog
        visible
        onHide={() => this.props.close()}
        header={"Import"}
        style={{
          minWidth: "300px",
          minHeight: "20vh",
          width: "min(1000px, 100vw)",
        }}
      >
        <DataImport />
      </Dialog>
    );
  }
}

class ExportData extends React.Component<DialogProps> {
  render() {
    return (
      <Dialog
        visible
        onHide={() => this.props.close()}
        header={"Export"}
        style={{
          minWidth: "300px",
          minHeight: "200px",
          width: "min(1000px, 100vw)",
        }}
      >
        <DataExport closeDialog={this.props.close} />
      </Dialog>
    );
  }
}

class AllWriterLogs extends React.Component<DialogProps> {
  constructor(props: any) {
    super(props);
    apisStore.setState({ dataPusher: Apis("datapusher") });
  }

  render() {
    return (
      <Dialog
        visible
        onHide={() => this.props.close()}
        style={{ width: "80vW", height: "70vh" }}
        header={SpinnerHeader({ name: "Writer logs" })}
        breakpoints={{ "960px": "85vw", "640px": "100vw" }}
      >
        <div style={{ height: "100%", overflowY: "hidden" }}>
          <PipelineLogViewer
            showProgressIndicator={ShowLoadingIndicator}
            hideProgressIndicator={HideLoadingIndicator}
            showLoggerName={true}
          />
        </div>
      </Dialog>
    );
  }
}

class AllCollectorLogs extends React.Component<DialogProps> {
  private apis: apisType;

  constructor(props: any) {
    super(props);
    this.apis = apisStore.getState().dataCollector;
  }

  render() {
    return (
      <Dialog
        visible
        onHide={() => this.props.close()}
        style={{ width: "80vW", height: "70vh" }}
        header={LogHeaderHandler()}
        breakpoints={{ "960px": "85vw", "640px": "100vw" }}
      >
        <div style={{ height: "100%", overflowY: "hidden" }}>
          <ReaderLogViewer
            api={this.apis.logsApi!!}
            showLoggerName={true}
            showProgressIndicator={ShowLoadingIndicator}
            hideProgressIndicator={HideLoadingIndicator}
          />
        </div>
      </Dialog>
    );
  }
}

class Base64Dialog extends React.Component<DialogProps> {
  render() {
    return (
      <Dialog
        visible
        onHide={() => this.props.close()}
        header={"Base64 Encrypt & Decrypt"}
        style={{ width: "min(100vw, 1000px)", height: "50vh" }}
        resizable={false}
      >
        <Base64 />
      </Dialog>
    );
  }
}

class ServerInfoDialog extends React.Component<DialogProps> {
  render() {
    return (
      <Dialog
        visible
        onHide={() => this.props.close()}
        header={"Server Info"}
        style={{ width: "min(30vw, 300px)" }}
        resizable={false}
      >
        <ServerInfo />
      </Dialog>
    );
  }
}

export default AppDialogs;
