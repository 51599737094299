import React, {useState} from 'react';
import {Tooltip} from "react-tooltip";

import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";

import OutputCreator from "./OutputCreator";

import './AddPipeOutput.scss';

type AddPipeOutputProps = {
    addNewOutput(conf: any): void,
    allOutputs?: Array<any>
}

const AddPipeOutput = ({addNewOutput, allOutputs}: AddPipeOutputProps) => {
    const outputsIds = allOutputs?.map(e => e.id)
    const [dialog, setDialog] = useState<boolean>(false)

    return <>
        <Tooltip id={"add-new-output"}/>
        <Button className="add-pipeline-button p-button-success"
                icon="pi pi-plus"
                onClick={() => setDialog(true)}
                data-tooltip-content={"Add new output"}
                data-tooltip-id={"add-new-output"}
        />
        {dialog &&
            <Dialog onHide={() => setDialog(false)}
                    visible={dialog}
                    header="Add new pipeline output"
            >
                <OutputCreator addNewOutput={(e) => {
                    addNewOutput(e);
                    setDialog(false);
                }}
                               outputsIds={outputsIds}
                />
            </Dialog>
        }

    </>
};

export default AddPipeOutput;