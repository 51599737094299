import React from "react";
import Editor from "@monaco-editor/react";
import {Button} from "primereact/button";
import {apisStore} from "../../../context/GlobalStates";
import Apis from "../../../serviceapi/ApiImports";

type TagMappingTesterProps = {}

class TagMappingTesterState {
    inputTagsString: string = "";
    mappingsString: string = "";
    outputTagsString: string = "";
}

class TagMappingTester extends React.Component<TagMappingTesterProps, TagMappingTesterState> {

    constructor(props: TagMappingTesterProps) {
        super(props);

        this.state = new TagMappingTesterState();
    }


    render() {
        return (
            <div>
                <div className={"grid"}>
                    <div className={"col-3"}>
                        <span>Input</span><br/>
                        <Editor
                            height={"500px"}
                            width={"100%"}
                            value={this.state.inputTagsString}
                            onChange={(val) => this.setState({inputTagsString: val || ""})}
                            theme={"vs-dark"}
                        />
                    </div>
                    <div className={"col-5"}>
                        <span>Mappings</span><br/>
                        <Editor
                            height={"500px"}
                            width={"100%"}
                            defaultLanguage={"json"}
                            value={this.state.mappingsString}
                            onChange={(val) => this.setState({mappingsString: val || ""})}
                            theme={"vs-dark"}
                        />
                    </div>
                    <div className={"col-4"}>
                        <span>Output</span><br/>
                        <Editor
                            height={"500px"}
                            width={"100%"}
                            value={this.state.outputTagsString}
                            onChange={(val) => this.setState({outputTagsString: val || ""})}
                            theme={"vs-dark"}
                        />
                    </div>
                </div>
                <Button label={"Run"} onClick={this.runTest}/>
            </div>
        );
    }

    runTest = async () => {
        let tags = this.state.inputTagsString.split("\n");
        let mappings = JSON.parse(this.state.mappingsString);

        let testingApi = apisStore.getState().dataCollector.testingApi;

        let testResponse = await testingApi.testTagMapper({
            tags: tags,
            mapperConfig: mappings
        });

        let responseString = JSON.stringify(testResponse.data, null, 2);
        this.setState({outputTagsString: responseString})
    }

}

export default TagMappingTester;