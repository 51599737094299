import React, {useEffect, useState} from 'react';
import NotificationPopUp from "util/NotificationPopUp";
import {ManagementApi} from "serviceapi/dataPusherApi";
import {apisStore} from "context/GlobalStates";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";

type OutputCreatorProps = {
    addNewOutput(conf: any): void
    outputsIds?: string[]
}

const OutputCreator = ({addNewOutput, outputsIds}: OutputCreatorProps) => {
    const managementApi: ManagementApi = (apisStore.getState().dataPusher as any).managementApiConst;

    const [outputId, setOutputId] = useState<string>("")
    const [selectedSchema, setSelectedSchema] = useState<string | null>(null);
    const [schemas, setSchemas] = useState<Array<any>>([])


    useEffect(() => {
        managementApi.getAllSchemas("OUTPUT")
            .then((res) => {
                setSchemas(Object.entries(res.data).map(([key, _]) => {
                    return {
                        label: key,
                        value: key
                    }
                }));
            })
            .catch((err) => {
                console.error(err);
                NotificationPopUp.show("Error trying to fetch output schemas");
            });
    }, []);

    const createEmptyOutput = async () => {
        try {
            if (selectedSchema === null) return

            const outputConfig = await managementApi.createEmptyConfig(selectedSchema, "OUTPUT");
            const conf: any = outputConfig.data;
            conf.id = outputId;
            NotificationPopUp.show(`Output [${outputId}] created successfully`, 'success');

            addNewOutput(conf);
        } catch (err) {
            console.error(err);
            NotificationPopUp.show("Error", 'error');
        }
    }
    console.log(schemas)

    return (
        <div className={"flex flex-column gap-2"}>
            <InputText placeholder={"Output ID"}
                       className={"p-inputtext-sm"}
                       onChange={event => setOutputId(event.target.value)}
                       value={outputId}
            />

            <Dropdown
                value={selectedSchema}
                options={schemas}
                onChange={(e) => setSelectedSchema(e.value)}
                filter
                showClear
                filterBy="label"
                id={"output-schema-picker"}
                style={{width: "100%", minWidth: "200px"}}
                disabled={schemas.length === 0}
                placeholder={"Select an output schema"}
            />
            <div className={"flex"}>
                <Button label={"Create"}
                        className={"p-button-sm p-button-success"}
                        disabled={outputId.length < 3 ||
                            selectedSchema === null ||
                            outputsIds?.includes(outputId)}
                        onClick={() => createEmptyOutput()}
                />
            </div>
        </div>
    );
};

export default OutputCreator;