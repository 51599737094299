import React from 'react';
import {Tooltip} from "primereact/tooltip";

type BasicTooltipProps = {
    targetId:string;
    text: Map<string, string>
    delay?:number;
}

class BasicTooltip extends React.Component<BasicTooltipProps> {
    render() {
        return (
                <Tooltip target={`#${this.props.targetId}`} position={"bottom"} showDelay={this.props.delay||0}>
                        {Array.from(this.props.text.entries()).map(([key, value]) => (
                            <div key={key}><b>{key}</b>: {value}</div>
                        ))}
                </Tooltip>
        );
    }
}

export default BasicTooltip;