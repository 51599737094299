import React, {useId} from 'react';
import PipelineLogViewer from "components/data-writer/PipelineLogs/PipelineLogViewer";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Tooltip} from "react-tooltip";

type WriterLogViewerButtonProps = {
    pipelineId: string | undefined;
    showProgressIndicator(): void;
    hideProgressIndicator(): void;

    header: (s:string) => JSX.Element;
}

const WriterLogViewerButton = ({pipelineId,showProgressIndicator,hideProgressIndicator,header}:WriterLogViewerButtonProps) => {
    const id = useId()
    const [dialog, setDialog] = React.useState(false);


    return <>
        <Tooltip id={id} style={{zIndex:9999}} place={"right"}/>
        <Button
            className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
            icon={"pi pi-book"}
            data-tooltip-id={id}
            data-tooltip-content="Logs"
            onClick={() => setDialog(true)}
            disabled={!pipelineId}
        />
        <Dialog
            visible={dialog}
            onHide={() => setDialog(false)}
            style={{width: "70vW", height: "70vh"}}
            breakpoints={{'960px': '85vw', '640px': '100vw'}}
            header={header("logs")}
        >
            <div style={{height: "100%", overflowY: "hidden"}}>
                <PipelineLogViewer pipelineId={pipelineId}
                                   showProgressIndicator={showProgressIndicator}
                                   hideProgressIndicator={hideProgressIndicator}/>
            </div>

        </Dialog>
    </>
};

export default WriterLogViewerButton;