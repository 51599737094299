import {BasicReaderInfo, ManagementApi} from "serviceapi";
import {CLIENT_TIMEOUT, DEFAULT_OPTIONS} from "../service/HttpService";

class Reader {

    readerInfo: BasicReaderInfo;
    api: ManagementApi;

    private _modified: boolean = false;
    get modified(): boolean {
        return this._modified;
    }

    private _new: boolean = false;
    get new(): boolean {
        return this._new;
    }

    private _config: string = "";
    get config(): string {
        return this._config;
    }

    set config(value: string) {
        this._modified = this._config !== value;
        this._config = value;
    }

    constructor(readerInfo: BasicReaderInfo, api: ManagementApi, config?: string) {
        this.readerInfo = readerInfo;
        this.api = api;
        if (config !== undefined) {
            this._config = config;
            this._modified = true;
            this._new = true;
        }
    }

    reloadConfig(): Promise<any> {
        if (this._new) {
            return new Promise(() => {
                throw new Error("Cannot reload readers if they are new. Save the reader first.");
            });
        }

        return this.api.getReaderConfig(this.readerInfo.id!, DEFAULT_OPTIONS)
            .then(r => {
                // noinspection SuspiciousTypeOfGuard
                let configObject = typeof r.data === 'string' ? JSON.parse(r.data) : r.data;
                this._config = JSON.stringify(configObject, null, 4)
                this._modified = false;
                this._new = false;
            })
    }

    saveConfig(): Promise<any> {
        return this.api.setReaderConfig(this.readerInfo.id!, undefined, undefined, this._config, DEFAULT_OPTIONS)
            .then(() => {
                this._modified = false;
                this._new = false;
            })
    }

    restart(): Promise<any> {
        if (this._new) {
            return new Promise(() => {
                throw new Error("Cannot restart readers if they are new. Save the reader first.");
            });
        }

        return this.api.reloadReader(this.readerInfo.id!, DEFAULT_OPTIONS);
    }
}

export {Reader}