import React, {useEffect, useId, useRef} from 'react';
import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import {ActionsApi, BasicPipelineInfo, SchemaType} from "serviceapi/dataPusherApi";
import {apisStore} from "context/GlobalStates";
import {ActionDTO} from "serviceapi";
import Utils from "util/Utils";
import {Dialog} from "primereact/dialog";
import {Tooltip} from "react-tooltip";
import Action from "components/general/Actions/Action";
import notificationPopUp from "util/NotificationPopUp";
import {CLIENT_TIMEOUT, DEFAULT_OPTIONS} from "../../../../service/HttpService";

type WriterActionsButtonWithListProps = {
    pipelineId?: string,
    enabled?: boolean,
    schemaType?: SchemaType
    editorConfig: any,
}

const WriterActionsButtonWithList = ({
                                         pipelineId,

                                         schemaType,
                                         enabled = false,
                                         editorConfig
                                     }: WriterActionsButtonWithListProps) => {
    const id = useId()
    const api: ActionsApi = (apisStore.getState().dataPusher as any).writerActionsApi as ActionsApi;
    const menu = useRef<Menu>(null);

    const partId = editorConfig?.id;
    const schema = editorConfig?.implementationKey;

    const [actions, setActions] = React.useState<any[]>([])
    const [selectedAction, setSelectedAction] = React.useState<ActionDTO>()

    useEffect(() => {
        setActions([])
        if (schema && schemaType) getWriterActions(schema, schemaType)
    }, [schema, schemaType])

    const getWriterActions = (schema: string, schemaType: SchemaType) => {
        getActions(schema, schemaType)
            .then((res) => {
                res && setActions(res.data.map((action: ActionDTO) => {
                    return {
                        ...action,
                        label: action.name || Utils.camelToHuman(action.id),
                        command: () => {
                            setSelectedAction(action)
                        }
                    }
                }))
            })
    }

    const getActions = async (schema: string, schemaType: SchemaType) => {
        try {
            return await api.getActions(schema, schemaType, DEFAULT_OPTIONS)
        } catch (e: any) {
            console.error(e.message)
        }
    }

    const saveAction = async (data: Array<any>): Promise<any> => {
        if (!selectedAction || selectedAction.id == null || !pipelineId) {
            notificationPopUp.show("Missing pipeline ID", "error");
            setSelectedAction(undefined);
            return;
        }

        try {
            const response = await api.invokeAction(pipelineId, schemaType!!, partId, selectedAction.id, data, DEFAULT_OPTIONS)

            if (response && response.status && response.status < 300) {
                const responseData = response.data;
                notificationPopUp.show("Execution successful", "success");

                return responseData;
            } else {
                notificationPopUp.show("Execution failed", "error");
            }
        } catch (error: any) {
            notificationPopUp.show(
                error?.response?.data?.message || error?.message || "An error occurred",
                "error"
            );
        }
        return null;
    }

    return <>
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <Menu id={"popup_menu"} model={actions} popup ref={menu}/>
        <Button icon={"pi pi-bolt"}
                className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
                data-tooltip-id={id}
                data-tooltip-content="Actions"
                onClick={(event) => {
                    enabled
                        ?
                        menu.current?.toggle(event)
                        :
                        notificationPopUp.show("Please turn it on to use", "error", "Pipeline is currently shut down");
                }}
                aria-haspopup
                aria-controls="popup_menu"
                disabled={actions.length === 0}
        />
        {selectedAction && pipelineId && schemaType &&
            <Dialog onHide={() => setSelectedAction(undefined)}
                    header={"Action"}
                    visible={!!selectedAction}
                    className={"flex w-30rem"}
            >
                <Action action={selectedAction} saveAction={saveAction}/>
            </Dialog>}
    </>
};

export default WriterActionsButtonWithList;