/* tslint:disable */
/* eslint-disable */
/**
 * data-collector-app API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 5.7.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ActionDTO
 */
export interface ActionDTO {
    /**
     *
     * @type {string}
     * @memberof ActionDTO
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ActionDTO
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof ActionDTO
     */
    'description'?: string;
    /**
     *
     * @type {any}
     * @memberof ActionDTO
     */
    'returnSchema'?: any;
    /**
     *
     * @type {Array<ArgumentInfoDTO>}
     * @memberof ActionDTO
     */
    'arguments'?: Array<ArgumentInfoDTO>;
}
/**
 *
 * @export
 * @interface ArgumentInfoDTO
 */
export interface ArgumentInfoDTO {
    /**
     *
     * @type {string}
     * @memberof ArgumentInfoDTO
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof ArgumentInfoDTO
     */
    'description'?: string;
    /**
     *
     * @type {any}
     * @memberof ArgumentInfoDTO
     */
    'argumentSchema'?: any;
}
/**
 *
 * @export
 * @interface BackupEntry
 */
export interface BackupEntry {
    /**
     *
     * @type {number}
     * @memberof BackupEntry
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof BackupEntry
     */
    'readerId'?: string;
    /**
     *
     * @type {string}
     * @memberof BackupEntry
     */
    'timestamp'?: string;
}
/**
 *
 * @export
 * @interface BasicMeterInfo
 */
export interface BasicMeterInfo {
    /**
     *
     * @type {string}
     * @memberof BasicMeterInfo
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof BasicMeterInfo
     */
    'meterName'?: string;
    /**
     *
     * @type {string}
     * @memberof BasicMeterInfo
     */
    'readerId'?: string;
}
/**
 *
 * @export
 * @interface BasicReaderInfo
 */
export interface BasicReaderInfo {
    /**
     *
     * @type {string}
     * @memberof BasicReaderInfo
     */
    'implementationKey'?: string;
    /**
     *
     * @type {boolean}
     * @memberof BasicReaderInfo
     */
    'enabled'?: boolean;
    /**
     *
     * @type {string}
     * @memberof BasicReaderInfo
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof BasicReaderInfo
     */
    'readerName'?: string;
    /**
     *
     * @type {Array<BasicMeterInfo>}
     * @memberof BasicReaderInfo
     */
    'meters'?: Array<BasicMeterInfo>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DataHoldMode = {
    ConsumeOnRead: 'CONSUME_ON_READ',
    ReturnMostRecent: 'RETURN_MOST_RECENT'
} as const;

export type DataHoldMode = typeof DataHoldMode[keyof typeof DataHoldMode];


/**
 *
 * @export
 * @enum {string}
 */

export const DatasetAccessType = {
    Continuous: 'CONTINUOUS',
    DataModelDay: 'DATA_MODEL_DAY',
    Normal: 'NORMAL'
} as const;

export type DatasetAccessType = typeof DatasetAccessType[keyof typeof DatasetAccessType];


/**
 *
 * @export
 * @enum {string}
 */

export const DatasetDataType = {
    Decimal: 'DECIMAL',
    Integer: 'INTEGER',
    String: 'STRING'
} as const;

export type DatasetDataType = typeof DatasetDataType[keyof typeof DatasetDataType];


/**
 *
 * @export
 * @interface DatasetDataUpdateDTO
 */
export interface DatasetDataUpdateDTO {
    /**
     *
     * @type {number}
     * @memberof DatasetDataUpdateDTO
     */
    'datasetId'?: number;
    /**
     *
     * @type {Array<DatasetValue>}
     * @memberof DatasetDataUpdateDTO
     */
    'values'?: Array<DatasetValue>;
}
/**
 *
 * @export
 * @interface DatasetMeta
 */
export interface DatasetMeta {
    /**
     *
     * @type {number}
     * @memberof DatasetMeta
     */
    'decimalPlaces'?: number;
    /**
     *
     * @type {number}
     * @memberof DatasetMeta
     */
    'maxBound'?: number;
    /**
     *
     * @type {number}
     * @memberof DatasetMeta
     */
    'minBound'?: number;
    /**
     *
     * @type {string}
     * @memberof DatasetMeta
     */
    'timeFormat'?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DatasetType = {
    Arbitrary: 'ARBITRARY',
    ArbitraryMinutes: 'ARBITRARY_MINUTES',
    ArbitrarySeconds: 'ARBITRARY_SECONDS',
    Daily: 'DAILY',
    Hourly: 'HOURLY',
    Minutes: 'MINUTES',
    Monthly: 'MONTHLY',
    Seconds: 'SECONDS',
    Weekly: 'WEEKLY'
} as const;

export type DatasetType = typeof DatasetType[keyof typeof DatasetType];


/**
 *
 * @export
 * @interface DatasetValue
 */
export interface DatasetValue {
    /**
     *
     * @type {string}
     * @memberof DatasetValue
     */
    'time'?: string;
    /**
     *
     * @type {number}
     * @memberof DatasetValue
     */
    'value'?: number;
}
/**
 *
 * @export
 * @interface DatasetValuesDTO
 */
export interface DatasetValuesDTO {
    /**
     *
     * @type {number}
     * @memberof DatasetValuesDTO
     */
    'datasetId'?: number;
    /**
     *
     * @type {Array<DatasetValue>}
     * @memberof DatasetValuesDTO
     */
    'values'?: Array<DatasetValue>;
}
/**
 *
 * @export
 * @interface InetAddress
 */
export interface InetAddress {
    /**
     *
     * @type {boolean}
     * @memberof InetAddress
     */
    'multicastAddress'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InetAddress
     */
    'anyLocalAddress'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InetAddress
     */
    'loopbackAddress'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InetAddress
     */
    'linkLocalAddress'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InetAddress
     */
    'siteLocalAddress'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InetAddress
     */
    'mCGlobal'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InetAddress
     */
    'mCNodeLocal'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InetAddress
     */
    'mCLinkLocal'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InetAddress
     */
    'mCSiteLocal'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InetAddress
     */
    'mCOrgLocal'?: boolean;
    /**
     *
     * @type {string}
     * @memberof InetAddress
     */
    'hostName'?: string;
    /**
     *
     * @type {File}
     * @memberof InetAddress
     */
    'address'?: File;
    /**
     *
     * @type {string}
     * @memberof InetAddress
     */
    'hostAddress'?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const LogLevel = {
    Off: 'OFF',
    Severe: 'SEVERE',
    Warning: 'WARNING',
    Info: 'INFO',
    Config: 'CONFIG',
    Fine: 'FINE',
    Finer: 'FINER',
    Finest: 'FINEST',
    All: 'ALL'
} as const;

export type LogLevel = typeof LogLevel[keyof typeof LogLevel];


/**
 *
 * @export
 * @interface LogMessage
 */
export interface LogMessage {
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'timestamp'?: string;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'loggerName'?: string;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'className'?: string;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'methodName'?: string;
    /**
     *
     * @type {number}
     * @memberof LogMessage
     */
    'levelInt'?: number;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'level'?: string;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'message'?: string;
    /**
     *
     * @type {boolean}
     * @memberof LogMessage
     */
    'hasException'?: boolean;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'exceptionType'?: string;
}
/**
 *
 * @export
 * @interface LogRequest
 */
export interface LogRequest {
    /**
     *
     * @type {string}
     * @memberof LogRequest
     */
    'from'?: string;
    /**
     *
     * @type {string}
     * @memberof LogRequest
     */
    'to'?: string;
    /**
     *
     * @type {string}
     * @memberof LogRequest
     */
    'duration'?: string;
    /**
     *
     * @type {number}
     * @memberof LogRequest
     */
    'limit'?: number;
    /**
     *
     * @type {LogLevel}
     * @memberof LogRequest
     */
    'minLevel'?: LogLevel;
    /**
     *
     * @type {boolean}
     * @memberof LogRequest
     */
    'requireException'?: boolean;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof LogRequest
     */
    'likeRequests'?: { [key: string]: string; };
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof LogRequest
     */
    'equalRequests'?: { [key: string]: string; };
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof LogRequest
     */
    'regexRequests'?: { [key: string]: string; };
}


/**
 *
 * @export
 * @interface Measurement
 */
export interface Measurement {
    /**
     *
     * @type {string}
     * @memberof Measurement
     */
    'meterId'?: string;
    /**
     *
     * @type {number}
     * @memberof Measurement
     */
    'timeStart'?: number;
    /**
     *
     * @type {number}
     * @memberof Measurement
     */
    'value'?: number;
    /**
     *
     * @type {string}
     * @memberof Measurement
     */
    'unit'?: string;
}
/**
 *
 * @export
 * @interface MeasurementRequest
 */
export interface MeasurementRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof MeasurementRequest
     */
    'meters'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof MeasurementRequest
     */
    'from'?: string;
    /**
     *
     * @type {string}
     * @memberof MeasurementRequest
     */
    'to'?: string;
}
/**
 *
 * @export
 * @interface PingParameters
 */
export interface PingParameters {
    /**
     *
     * @type {InetAddress}
     * @memberof PingParameters
     */
    'address'?: InetAddress;
    /**
     *
     * @type {number}
     * @memberof PingParameters
     */
    'count'?: number;
    /**
     *
     * @type {boolean}
     * @memberof PingParameters
     */
    'forceIPV4'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PingParameters
     */
    'forceIPV6'?: boolean;
    /**
     *
     * @type {number}
     * @memberof PingParameters
     */
    'ttl'?: number;
    /**
     *
     * @type {number}
     * @memberof PingParameters
     */
    'timeout'?: number;
}
/**
 *
 * @export
 * @interface PostProcessorConfigDTO
 */
export interface PostProcessorConfigDTO {
    /**
     *
     * @type {PostProcessorType}
     * @memberof PostProcessorConfigDTO
     */
    'postProcessorType'?: PostProcessorType;
    /**
     *
     * @type {Set<string>}
     * @memberof PostProcessorConfigDTO
     */
    'readers'?: Set<string>;
    /**
     *
     * @type {PostProcessorPriority}
     * @memberof PostProcessorConfigDTO
     */
    'priority'?: PostProcessorPriority;
    /**
     *
     * @type {object}
     * @memberof PostProcessorConfigDTO
     */
    'config'?: object;
}


/**
 *
 * @export
 * @interface PostProcessorDescription
 */
export interface PostProcessorDescription {
    /**
     *
     * @type {string}
     * @memberof PostProcessorDescription
     */
    'implementationKey'?: string;
    /**
     *
     * @type {string}
     * @memberof PostProcessorDescription
     */
    'name'?: string;
    /**
     *
     * @type {boolean}
     * @memberof PostProcessorDescription
     */
    'deprecated'?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const PostProcessorPriority = {
    Before: 'BEFORE',
    After: 'AFTER'
} as const;

export type PostProcessorPriority = typeof PostProcessorPriority[keyof typeof PostProcessorPriority];


/**
 *
 * @export
 * @enum {string}
 */

export const PostProcessorType = {
    Measurements: 'MEASUREMENTS',
    Products: 'PRODUCTS'
} as const;

export type PostProcessorType = typeof PostProcessorType[keyof typeof PostProcessorType];


/**
 *
 * @export
 * @interface ProductEntries
 */
export interface ProductEntries {
    /**
     *
     * @type {string}
     * @memberof ProductEntries
     */
    'templateRefName'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ProductEntries
     */
    'overwriteOnSameKey'?: boolean;
    /**
     *
     * @type {Array<{ [key: string]: any; }>}
     * @memberof ProductEntries
     */
    'entries'?: Array<{ [key: string]: any; }>;
}
/**
 *
 * @export
 * @interface ProductItem
 */
export interface ProductItem {
    /**
     *
     * @type {string}
     * @memberof ProductItem
     */
    'template'?: string;
    /**
     *
     * @type {string}
     * @memberof ProductItem
     */
    'timestamp'?: string;
    /**
     *
     * @type {{ [key: string]: any; }}
     * @memberof ProductItem
     */
    'values'?: { [key: string]: any; };
}
/**
 *
 * @export
 * @interface ProductRequest
 */
export interface ProductRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof ProductRequest
     */
    'templates'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ProductRequest
     */
    'from'?: string;
    /**
     *
     * @type {string}
     * @memberof ProductRequest
     */
    'to'?: string;
}
/**
 *
 * @export
 * @interface ProtocolDescription
 */
export interface ProtocolDescription {
    /**
     *
     * @type {string}
     * @memberof ProtocolDescription
     */
    'implementationKey'?: string;
    /**
     *
     * @type {ProtocolType}
     * @memberof ProtocolDescription
     */
    'protocolType'?: ProtocolType;
    /**
     *
     * @type {string}
     * @memberof ProtocolDescription
     */
    'name'?: string;
    /**
     *
     * @type {boolean}
     * @memberof ProtocolDescription
     */
    'deprecated'?: boolean;
}


/**
 *
 * @export
 * @enum {string}
 */

export const ProtocolType = {
    Measurements: 'MEASUREMENTS',
    ProductItems: 'PRODUCT_ITEMS'
} as const;

export type ProtocolType = typeof ProtocolType[keyof typeof ProtocolType];


/**
 *
 * @export
 * @interface RemoteDatasetDTO
 */
export interface RemoteDatasetDTO {
    /**
     *
     * @type {number}
     * @memberof RemoteDatasetDTO
     */
    'categoryId'?: number;
    /**
     *
     * @type {string}
     * @memberof RemoteDatasetDTO
     */
    'clientId'?: string;
    /**
     *
     * @type {number}
     * @memberof RemoteDatasetDTO
     */
    'companyId'?: number;
    /**
     *
     * @type {number}
     * @memberof RemoteDatasetDTO
     */
    'createTime'?: number;
    /**
     *
     * @type {DatasetAccessType}
     * @memberof RemoteDatasetDTO
     */
    'datasetAccessType': DatasetAccessType;
    /**
     *
     * @type {DatasetDataType}
     * @memberof RemoteDatasetDTO
     */
    'datasetDataType': DatasetDataType;
    /**
     *
     * @type {DatasetMeta}
     * @memberof RemoteDatasetDTO
     */
    'datasetMeta': DatasetMeta;
    /**
     *
     * @type {DatasetType}
     * @memberof RemoteDatasetDTO
     */
    'datasetType': DatasetType;
    /**
     *
     * @type {boolean}
     * @memberof RemoteDatasetDTO
     */
    'deleted'?: boolean;
    /**
     *
     * @type {string}
     * @memberof RemoteDatasetDTO
     */
    'description'?: string;
    /**
     *
     * @type {number}
     * @memberof RemoteDatasetDTO
     */
    'editTime'?: number;
    /**
     *
     * @type {number}
     * @memberof RemoteDatasetDTO
     */
    'groupId'?: number;
    /**
     *
     * @type {boolean}
     * @memberof RemoteDatasetDTO
     */
    'hidden'?: boolean;
    /**
     *
     * @type {number}
     * @memberof RemoteDatasetDTO
     */
    'id'?: number;
    /**
     *
     * @type {number}
     * @memberof RemoteDatasetDTO
     */
    'measurementDeviceId'?: number;
    /**
     *
     * @type {number}
     * @memberof RemoteDatasetDTO
     */
    'retentionPolicyId'?: number;
    /**
     *
     * @type {Set<string>}
     * @memberof RemoteDatasetDTO
     */
    'tags': Set<string>;
    /**
     *
     * @type {string}
     * @memberof RemoteDatasetDTO
     */
    'title'?: string;
    /**
     *
     * @type {string}
     * @memberof RemoteDatasetDTO
     */
    'unit'?: string;
    /**
     *
     * @type {number}
     * @memberof RemoteDatasetDTO
     */
    'valueMapperId'?: number;
}


/**
 *
 * @export
 * @interface TagMapperTestRequest
 */
export interface TagMapperTestRequest {
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof TagMapperTestRequest
     */
    'mapperConfig': { [key: string]: string; };
    /**
     *
     * @type {Array<string>}
     * @memberof TagMapperTestRequest
     */
    'tags': Array<string>;
}
/**
 *
 * @export
 * @interface TracerouteParameters
 */
export interface TracerouteParameters {
    /**
     *
     * @type {InetAddress}
     * @memberof TracerouteParameters
     */
    'address'?: InetAddress;
    /**
     *
     * @type {number}
     * @memberof TracerouteParameters
     */
    'maxHops'?: number;
    /**
     *
     * @type {number}
     * @memberof TracerouteParameters
     */
    'timeout'?: number;
    /**
     *
     * @type {boolean}
     * @memberof TracerouteParameters
     */
    'forceIPV4'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof TracerouteParameters
     */
    'forceIPV6'?: boolean;
}

/**
 * ActionsApi - axios parameter creator
 * @export
 */
export const ActionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} aReaderId
         * @param {string} bActionId
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restActionsInvokeAReaderIdBActionIdPost: async (aReaderId: string, bActionId: string, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aReaderId' is not null or undefined
            assertParamExists('restActionsInvokeAReaderIdBActionIdPost', 'aReaderId', aReaderId)
            // verify required parameter 'bActionId' is not null or undefined
            assertParamExists('restActionsInvokeAReaderIdBActionIdPost', 'bActionId', bActionId)
            const localVarPath = `/rest/actions/invoke/{a_readerId}/{b_actionId}`
                .replace(`{${"a_readerId"}}`, encodeURIComponent(String(aReaderId)))
                .replace(`{${"b_actionId"}}`, encodeURIComponent(String(bActionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restActionsSchemaGet: async (schema: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('restActionsSchemaGet', 'schema', schema)
            const localVarPath = `/rest/actions/{schema}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionsApi - functional programming interface
 * @export
 */
export const ActionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} aReaderId
         * @param {string} bActionId
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restActionsInvokeAReaderIdBActionIdPost(aReaderId: string, bActionId: string, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restActionsInvokeAReaderIdBActionIdPost(aReaderId, bActionId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restActionsSchemaGet(schema: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restActionsSchemaGet(schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionsApi - factory interface
 * @export
 */
export const ActionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionsApiFp(configuration)
    return {
        /**
         *
         * @param {string} aReaderId
         * @param {string} bActionId
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restActionsInvokeAReaderIdBActionIdPost(aReaderId: string, bActionId: string, body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.restActionsInvokeAReaderIdBActionIdPost(aReaderId, bActionId, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restActionsSchemaGet(schema: string, options?: any): AxiosPromise<Array<ActionDTO>> {
            return localVarFp.restActionsSchemaGet(schema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionsApi - object-oriented interface
 * @export
 * @class ActionsApi
 * @extends {BaseAPI}
 */
export class ActionsApi extends BaseAPI {
    /**
     *
     * @param {string} aReaderId
     * @param {string} bActionId
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public restActionsInvokeAReaderIdBActionIdPost(aReaderId: string, bActionId: string, body?: any, options?: AxiosRequestConfig) {
        return ActionsApiFp(this.configuration).restActionsInvokeAReaderIdBActionIdPost(aReaderId, bActionId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} schema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public restActionsSchemaGet(schema: string, options?: AxiosRequestConfig) {
        return ActionsApiFp(this.configuration).restActionsSchemaGet(schema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BackupApi - axios parameter creator
 * @export
 */
export const BackupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBackup: async (readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('createBackup', 'readerId', readerId)
            const localVarPath = `/rest/backup/backup/{readerId}`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<number>} ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackup: async (ids: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteBackup', 'ids', ids)
            const localVarPath = `/rest/backup/backup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<string>} [ids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackups: async (ids?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/backup/entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationBackup: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConfigurationBackup', 'id', id)
            const localVarPath = `/rest/backup/backup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restBackupCreateRemoteBackupGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/backup/createRemoteBackup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} readerId
         * @param {number} backupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreBackup: async (readerId: string, backupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('restoreBackup', 'readerId', readerId)
            // verify required parameter 'backupId' is not null or undefined
            assertParamExists('restoreBackup', 'backupId', backupId)
            const localVarPath = `/rest/backup/restore/{readerId}`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (backupId !== undefined) {
                localVarQueryParameter['backupId'] = backupId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackupApi - functional programming interface
 * @export
 */
export const BackupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackupApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBackup(readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBackup(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<number>} ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBackup(ids: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBackup(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<string>} [ids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBackups(ids?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackupEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBackups(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigurationBackup(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigurationBackup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restBackupCreateRemoteBackupGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restBackupCreateRemoteBackupGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} readerId
         * @param {number} backupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreBackup(readerId: string, backupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreBackup(readerId, backupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BackupApi - factory interface
 * @export
 */
export const BackupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackupApiFp(configuration)
    return {
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBackup(readerId: string, options?: any): AxiosPromise<number> {
            return localVarFp.createBackup(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<number>} ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackup(ids: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBackup(ids, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} [ids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackups(ids?: Array<string>, options?: any): AxiosPromise<Array<BackupEntry>> {
            return localVarFp.getBackups(ids, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationBackup(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.getConfigurationBackup(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restBackupCreateRemoteBackupGet(options?: any): AxiosPromise<void> {
            return localVarFp.restBackupCreateRemoteBackupGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} readerId
         * @param {number} backupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreBackup(readerId: string, backupId: number, options?: any): AxiosPromise<void> {
            return localVarFp.restoreBackup(readerId, backupId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackupApi - object-oriented interface
 * @export
 * @class BackupApi
 * @extends {BaseAPI}
 */
export class BackupApi extends BaseAPI {
    /**
     *
     * @param {string} readerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public createBackup(readerId: string, options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).createBackup(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public deleteBackup(ids: Array<number>, options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).deleteBackup(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Array<string>} [ids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public getBackups(ids?: Array<string>, options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).getBackups(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public getConfigurationBackup(id: number, options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).getConfigurationBackup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public restBackupCreateRemoteBackupGet(options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).restBackupCreateRemoteBackupGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} readerId
     * @param {number} backupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public restoreBackup(readerId: string, backupId: number, options?: AxiosRequestConfig) {
        return BackupApiFp(this.configuration).restoreBackup(readerId, backupId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DataResourceApi - axios parameter creator
 * @export
 */
export const DataResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {boolean} [fromInclusive]
         * @param {string} [to]
         * @param {boolean} [toInclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetCountGet: async (dataset: number, from?: string, fromInclusive?: boolean, to?: string, toInclusive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetCountGet', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}/count`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (fromInclusive !== undefined) {
                localVarQueryParameter['fromInclusive'] = fromInclusive;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (toInclusive !== undefined) {
                localVarQueryParameter['toInclusive'] = toInclusive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetDelete: async (dataset: number, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetDelete', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {boolean} [fromInclusive]
         * @param {string} [to]
         * @param {boolean} [toInclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetHasValueGet: async (dataset: number, from?: string, fromInclusive?: boolean, to?: string, toInclusive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetHasValueGet', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}/hasValue`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (fromInclusive !== undefined) {
                localVarQueryParameter['fromInclusive'] = fromInclusive;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (toInclusive !== undefined) {
                localVarQueryParameter['toInclusive'] = toInclusive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [relativeTime]
         * @param {number} [specificHour]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetMaxTimeGet: async (dataset: number, relativeTime?: string, specificHour?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetMaxTimeGet', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}/maxTime`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (relativeTime !== undefined) {
                localVarQueryParameter['relativeTime'] = (relativeTime as any instanceof Date) ?
                    (relativeTime as any).toISOString() :
                    relativeTime;
            }

            if (specificHour !== undefined) {
                localVarQueryParameter['specificHour'] = specificHour;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetMaxValueGet: async (dataset: number, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetMaxValueGet', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}/maxValue`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [relativeTime]
         * @param {number} [specificHour]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetMinTimeGet: async (dataset: number, relativeTime?: string, specificHour?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetMinTimeGet', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}/minTime`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (relativeTime !== undefined) {
                localVarQueryParameter['relativeTime'] = (relativeTime as any instanceof Date) ?
                    (relativeTime as any).toISOString() :
                    relativeTime;
            }

            if (specificHour !== undefined) {
                localVarQueryParameter['specificHour'] = specificHour;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetMinValueGet: async (dataset: number, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetMinValueGet', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}/minValue`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetReadGet: async (dataset: number, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetReadGet', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}/read`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [moment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetReadOneGet: async (dataset: number, moment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetReadOneGet', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}/readOne`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (moment !== undefined) {
                localVarQueryParameter['moment'] = (moment as any instanceof Date) ?
                    (moment as any).toISOString() :
                    moment;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} dataset
         * @param {number} [count]
         * @param {string} [referenceTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetReadRecentGet: async (dataset: number, count?: number, referenceTime?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetReadRecentGet', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}/readRecent`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (referenceTime !== undefined) {
                localVarQueryParameter['referenceTime'] = (referenceTime as any instanceof Date) ?
                    (referenceTime as any).toISOString() :
                    referenceTime;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} dataset
         * @param {boolean} [overwrite]
         * @param {Array<DatasetValue>} [datasetValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetUpdateDatasetPost: async (dataset: number, overwrite?: boolean, datasetValue?: Array<DatasetValue>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetUpdateDatasetPost', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}/updateDataset`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (overwrite !== undefined) {
                localVarQueryParameter['overwrite'] = overwrite;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetValue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} dataset
         * @param {boolean} [overwrite]
         * @param {DatasetValue} [datasetValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetUpdateValuePost: async (dataset: number, overwrite?: boolean, datasetValue?: DatasetValue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('restDataDatasetUpdateValuePost', 'dataset', dataset)
            const localVarPath = `/rest/data/{dataset}/updateValue`
                .replace(`{${"dataset"}}`, encodeURIComponent(String(dataset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (overwrite !== undefined) {
                localVarQueryParameter['overwrite'] = overwrite;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetValue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [count]
         * @param {Array<number>} [id]
         * @param {string} [referenceTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataReadRecentBatchGet: async (count?: number, id?: Array<number>, referenceTime?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/data/readRecentBatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (referenceTime !== undefined) {
                localVarQueryParameter['referenceTime'] = (referenceTime as any instanceof Date) ?
                    (referenceTime as any).toISOString() :
                    referenceTime;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [overwrite]
         * @param {Array<DatasetDataUpdateDTO>} [datasetDataUpdateDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataUpdateDatasetsPost: async (overwrite?: boolean, datasetDataUpdateDTO?: Array<DatasetDataUpdateDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/data/updateDatasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (overwrite !== undefined) {
                localVarQueryParameter['overwrite'] = overwrite;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetDataUpdateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataResourceApi - functional programming interface
 * @export
 */
export const DataResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataResourceApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {boolean} [fromInclusive]
         * @param {string} [to]
         * @param {boolean} [toInclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetCountGet(dataset: number, from?: string, fromInclusive?: boolean, to?: string, toInclusive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetCountGet(dataset, from, fromInclusive, to, toInclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetDelete(dataset: number, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetDelete(dataset, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {boolean} [fromInclusive]
         * @param {string} [to]
         * @param {boolean} [toInclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetHasValueGet(dataset: number, from?: string, fromInclusive?: boolean, to?: string, toInclusive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetHasValueGet(dataset, from, fromInclusive, to, toInclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [relativeTime]
         * @param {number} [specificHour]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetMaxTimeGet(dataset: number, relativeTime?: string, specificHour?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetMaxTimeGet(dataset, relativeTime, specificHour, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetMaxValueGet(dataset: number, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetMaxValueGet(dataset, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [relativeTime]
         * @param {number} [specificHour]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetMinTimeGet(dataset: number, relativeTime?: string, specificHour?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetMinTimeGet(dataset, relativeTime, specificHour, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetMinValueGet(dataset: number, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetMinValueGet(dataset, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetReadGet(dataset: number, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatasetValue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetReadGet(dataset, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [moment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetReadOneGet(dataset: number, moment?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetReadOneGet(dataset, moment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} dataset
         * @param {number} [count]
         * @param {string} [referenceTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetReadRecentGet(dataset: number, count?: number, referenceTime?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatasetValue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetReadRecentGet(dataset, count, referenceTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} dataset
         * @param {boolean} [overwrite]
         * @param {Array<DatasetValue>} [datasetValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetUpdateDatasetPost(dataset: number, overwrite?: boolean, datasetValue?: Array<DatasetValue>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetUpdateDatasetPost(dataset, overwrite, datasetValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} dataset
         * @param {boolean} [overwrite]
         * @param {DatasetValue} [datasetValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataDatasetUpdateValuePost(dataset: number, overwrite?: boolean, datasetValue?: DatasetValue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataDatasetUpdateValuePost(dataset, overwrite, datasetValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [count]
         * @param {Array<number>} [id]
         * @param {string} [referenceTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataReadRecentBatchGet(count?: number, id?: Array<number>, referenceTime?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatasetValuesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataReadRecentBatchGet(count, id, referenceTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {boolean} [overwrite]
         * @param {Array<DatasetDataUpdateDTO>} [datasetDataUpdateDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDataUpdateDatasetsPost(overwrite?: boolean, datasetDataUpdateDTO?: Array<DatasetDataUpdateDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDataUpdateDatasetsPost(overwrite, datasetDataUpdateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataResourceApi - factory interface
 * @export
 */
export const DataResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataResourceApiFp(configuration)
    return {
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {boolean} [fromInclusive]
         * @param {string} [to]
         * @param {boolean} [toInclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetCountGet(dataset: number, from?: string, fromInclusive?: boolean, to?: string, toInclusive?: boolean, options?: any): AxiosPromise<number> {
            return localVarFp.restDataDatasetCountGet(dataset, from, fromInclusive, to, toInclusive, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetDelete(dataset: number, from?: string, to?: string, options?: any): AxiosPromise<void> {
            return localVarFp.restDataDatasetDelete(dataset, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {boolean} [fromInclusive]
         * @param {string} [to]
         * @param {boolean} [toInclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetHasValueGet(dataset: number, from?: string, fromInclusive?: boolean, to?: string, toInclusive?: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.restDataDatasetHasValueGet(dataset, from, fromInclusive, to, toInclusive, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [relativeTime]
         * @param {number} [specificHour]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetMaxTimeGet(dataset: number, relativeTime?: string, specificHour?: number, options?: any): AxiosPromise<string> {
            return localVarFp.restDataDatasetMaxTimeGet(dataset, relativeTime, specificHour, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetMaxValueGet(dataset: number, from?: string, to?: string, options?: any): AxiosPromise<DatasetValue> {
            return localVarFp.restDataDatasetMaxValueGet(dataset, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [relativeTime]
         * @param {number} [specificHour]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetMinTimeGet(dataset: number, relativeTime?: string, specificHour?: number, options?: any): AxiosPromise<string> {
            return localVarFp.restDataDatasetMinTimeGet(dataset, relativeTime, specificHour, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetMinValueGet(dataset: number, from?: string, to?: string, options?: any): AxiosPromise<DatasetValue> {
            return localVarFp.restDataDatasetMinValueGet(dataset, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetReadGet(dataset: number, from?: string, to?: string, options?: any): AxiosPromise<Array<DatasetValue>> {
            return localVarFp.restDataDatasetReadGet(dataset, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} dataset
         * @param {string} [moment]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetReadOneGet(dataset: number, moment?: string, options?: any): AxiosPromise<DatasetValue> {
            return localVarFp.restDataDatasetReadOneGet(dataset, moment, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} dataset
         * @param {number} [count]
         * @param {string} [referenceTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetReadRecentGet(dataset: number, count?: number, referenceTime?: string, options?: any): AxiosPromise<Array<DatasetValue>> {
            return localVarFp.restDataDatasetReadRecentGet(dataset, count, referenceTime, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} dataset
         * @param {boolean} [overwrite]
         * @param {Array<DatasetValue>} [datasetValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetUpdateDatasetPost(dataset: number, overwrite?: boolean, datasetValue?: Array<DatasetValue>, options?: any): AxiosPromise<void> {
            return localVarFp.restDataDatasetUpdateDatasetPost(dataset, overwrite, datasetValue, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} dataset
         * @param {boolean} [overwrite]
         * @param {DatasetValue} [datasetValue]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataDatasetUpdateValuePost(dataset: number, overwrite?: boolean, datasetValue?: DatasetValue, options?: any): AxiosPromise<void> {
            return localVarFp.restDataDatasetUpdateValuePost(dataset, overwrite, datasetValue, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [count]
         * @param {Array<number>} [id]
         * @param {string} [referenceTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataReadRecentBatchGet(count?: number, id?: Array<number>, referenceTime?: string, options?: any): AxiosPromise<Array<DatasetValuesDTO>> {
            return localVarFp.restDataReadRecentBatchGet(count, id, referenceTime, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {boolean} [overwrite]
         * @param {Array<DatasetDataUpdateDTO>} [datasetDataUpdateDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDataUpdateDatasetsPost(overwrite?: boolean, datasetDataUpdateDTO?: Array<DatasetDataUpdateDTO>, options?: any): AxiosPromise<void> {
            return localVarFp.restDataUpdateDatasetsPost(overwrite, datasetDataUpdateDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataResourceApi - object-oriented interface
 * @export
 * @class DataResourceApi
 * @extends {BaseAPI}
 */
export class DataResourceApi extends BaseAPI {
    /**
     *
     * @param {number} dataset
     * @param {string} [from]
     * @param {boolean} [fromInclusive]
     * @param {string} [to]
     * @param {boolean} [toInclusive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetCountGet(dataset: number, from?: string, fromInclusive?: boolean, to?: string, toInclusive?: boolean, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetCountGet(dataset, from, fromInclusive, to, toInclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} dataset
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetDelete(dataset: number, from?: string, to?: string, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetDelete(dataset, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} dataset
     * @param {string} [from]
     * @param {boolean} [fromInclusive]
     * @param {string} [to]
     * @param {boolean} [toInclusive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetHasValueGet(dataset: number, from?: string, fromInclusive?: boolean, to?: string, toInclusive?: boolean, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetHasValueGet(dataset, from, fromInclusive, to, toInclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} dataset
     * @param {string} [relativeTime]
     * @param {number} [specificHour]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetMaxTimeGet(dataset: number, relativeTime?: string, specificHour?: number, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetMaxTimeGet(dataset, relativeTime, specificHour, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} dataset
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetMaxValueGet(dataset: number, from?: string, to?: string, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetMaxValueGet(dataset, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} dataset
     * @param {string} [relativeTime]
     * @param {number} [specificHour]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetMinTimeGet(dataset: number, relativeTime?: string, specificHour?: number, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetMinTimeGet(dataset, relativeTime, specificHour, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} dataset
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetMinValueGet(dataset: number, from?: string, to?: string, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetMinValueGet(dataset, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} dataset
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetReadGet(dataset: number, from?: string, to?: string, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetReadGet(dataset, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} dataset
     * @param {string} [moment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetReadOneGet(dataset: number, moment?: string, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetReadOneGet(dataset, moment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} dataset
     * @param {number} [count]
     * @param {string} [referenceTime]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetReadRecentGet(dataset: number, count?: number, referenceTime?: string, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetReadRecentGet(dataset, count, referenceTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} dataset
     * @param {boolean} [overwrite]
     * @param {Array<DatasetValue>} [datasetValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetUpdateDatasetPost(dataset: number, overwrite?: boolean, datasetValue?: Array<DatasetValue>, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetUpdateDatasetPost(dataset, overwrite, datasetValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} dataset
     * @param {boolean} [overwrite]
     * @param {DatasetValue} [datasetValue]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataDatasetUpdateValuePost(dataset: number, overwrite?: boolean, datasetValue?: DatasetValue, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataDatasetUpdateValuePost(dataset, overwrite, datasetValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} [count]
     * @param {Array<number>} [id]
     * @param {string} [referenceTime]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataReadRecentBatchGet(count?: number, id?: Array<number>, referenceTime?: string, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataReadRecentBatchGet(count, id, referenceTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {boolean} [overwrite]
     * @param {Array<DatasetDataUpdateDTO>} [datasetDataUpdateDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataResourceApi
     */
    public restDataUpdateDatasetsPost(overwrite?: boolean, datasetDataUpdateDTO?: Array<DatasetDataUpdateDTO>, options?: AxiosRequestConfig) {
        return DataResourceApiFp(this.configuration).restDataUpdateDatasetsPost(overwrite, datasetDataUpdateDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DatasetApi - axios parameter creator
 * @export
 */
export const DatasetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {Array<number>} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsBatchGet: async (id?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/datasets/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id'] = id;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [categoryId]
         * @param {string} [clientId]
         * @param {number} [companyId]
         * @param {number} [deviceId]
         * @param {number} [fetch]
         * @param {number} [groupId]
         * @param {Set<string>} [tags]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsGet: async (categoryId?: number, clientId?: string, companyId?: number, deviceId?: number, fetch?: number, groupId?: number, tags?: Set<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (fetch !== undefined) {
                localVarQueryParameter['fetch'] = fetch;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (tags) {
                localVarQueryParameter['tags'] = Array.from(tags);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restDatasetsIdDelete', 'id', id)
            const localVarPath = `/rest/datasets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restDatasetsIdGet', 'id', id)
            const localVarPath = `/rest/datasets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {RemoteDatasetDTO} [remoteDatasetDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsIdPut: async (id: number, remoteDatasetDTO?: RemoteDatasetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restDatasetsIdPut', 'id', id)
            const localVarPath = `/rest/datasets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(remoteDatasetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RemoteDatasetDTO} [remoteDatasetDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsPost: async (remoteDatasetDTO?: RemoteDatasetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/datasets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(remoteDatasetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetApi - functional programming interface
 * @export
 */
export const DatasetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {Array<number>} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDatasetsBatchGet(id?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteDatasetDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDatasetsBatchGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [categoryId]
         * @param {string} [clientId]
         * @param {number} [companyId]
         * @param {number} [deviceId]
         * @param {number} [fetch]
         * @param {number} [groupId]
         * @param {Set<string>} [tags]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDatasetsGet(categoryId?: number, clientId?: string, companyId?: number, deviceId?: number, fetch?: number, groupId?: number, tags?: Set<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteDatasetDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDatasetsGet(categoryId, clientId, companyId, deviceId, fetch, groupId, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDatasetsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDatasetsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDatasetsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDatasetsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {RemoteDatasetDTO} [remoteDatasetDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDatasetsIdPut(id: number, remoteDatasetDTO?: RemoteDatasetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDatasetsIdPut(id, remoteDatasetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {RemoteDatasetDTO} [remoteDatasetDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restDatasetsPost(remoteDatasetDTO?: RemoteDatasetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoteDatasetDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restDatasetsPost(remoteDatasetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatasetApi - factory interface
 * @export
 */
export const DatasetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetApiFp(configuration)
    return {
        /**
         *
         * @param {Array<number>} [id]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsBatchGet(id?: Array<number>, options?: any): AxiosPromise<Array<RemoteDatasetDTO>> {
            return localVarFp.restDatasetsBatchGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [categoryId]
         * @param {string} [clientId]
         * @param {number} [companyId]
         * @param {number} [deviceId]
         * @param {number} [fetch]
         * @param {number} [groupId]
         * @param {Set<string>} [tags]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsGet(categoryId?: number, clientId?: string, companyId?: number, deviceId?: number, fetch?: number, groupId?: number, tags?: Set<string>, options?: any): AxiosPromise<Array<RemoteDatasetDTO>> {
            return localVarFp.restDatasetsGet(categoryId, clientId, companyId, deviceId, fetch, groupId, tags, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsIdDelete(id: number, options?: any): AxiosPromise<RemoteDatasetDTO> {
            return localVarFp.restDatasetsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsIdGet(id: number, options?: any): AxiosPromise<RemoteDatasetDTO> {
            return localVarFp.restDatasetsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {RemoteDatasetDTO} [remoteDatasetDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsIdPut(id: number, remoteDatasetDTO?: RemoteDatasetDTO, options?: any): AxiosPromise<RemoteDatasetDTO> {
            return localVarFp.restDatasetsIdPut(id, remoteDatasetDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {RemoteDatasetDTO} [remoteDatasetDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restDatasetsPost(remoteDatasetDTO?: RemoteDatasetDTO, options?: any): AxiosPromise<RemoteDatasetDTO> {
            return localVarFp.restDatasetsPost(remoteDatasetDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetApi - object-oriented interface
 * @export
 * @class DatasetApi
 * @extends {BaseAPI}
 */
export class DatasetApi extends BaseAPI {
    /**
     *
     * @param {Array<number>} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public restDatasetsBatchGet(id?: Array<number>, options?: AxiosRequestConfig) {
        return DatasetApiFp(this.configuration).restDatasetsBatchGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} [categoryId]
     * @param {string} [clientId]
     * @param {number} [companyId]
     * @param {number} [deviceId]
     * @param {number} [fetch]
     * @param {number} [groupId]
     * @param {Set<string>} [tags]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public restDatasetsGet(categoryId?: number, clientId?: string, companyId?: number, deviceId?: number, fetch?: number, groupId?: number, tags?: Set<string>, options?: AxiosRequestConfig) {
        return DatasetApiFp(this.configuration).restDatasetsGet(categoryId, clientId, companyId, deviceId, fetch, groupId, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public restDatasetsIdDelete(id: number, options?: AxiosRequestConfig) {
        return DatasetApiFp(this.configuration).restDatasetsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public restDatasetsIdGet(id: number, options?: AxiosRequestConfig) {
        return DatasetApiFp(this.configuration).restDatasetsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {RemoteDatasetDTO} [remoteDatasetDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public restDatasetsIdPut(id: number, remoteDatasetDTO?: RemoteDatasetDTO, options?: AxiosRequestConfig) {
        return DatasetApiFp(this.configuration).restDatasetsIdPut(id, remoteDatasetDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {RemoteDatasetDTO} [remoteDatasetDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public restDatasetsPost(remoteDatasetDTO?: RemoteDatasetDTO, options?: AxiosRequestConfig) {
        return DatasetApiFp(this.configuration).restDatasetsPost(remoteDatasetDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/health/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async version(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.version(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<string> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options?: any): AxiosPromise<string> {
            return localVarFp.version(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public version(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).version(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get log of data collector instance
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralLog: async (logRequest?: LogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/logs/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get log of reader. Any provided \"loggerNameRegex\" is ignored
         * @param {string} readerId
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderLog: async (readerId: string, logRequest?: LogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('getReaderLog', 'readerId', readerId)
            const localVarPath = `/rest/logs/reader/{readerId}`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get log of data collector instance
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralLog(logRequest?: LogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralLog(logRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get log of reader. Any provided \"loggerNameRegex\" is ignored
         * @param {string} readerId
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaderLog(readerId: string, logRequest?: LogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaderLog(readerId, logRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogsApiFp(configuration)
    return {
        /**
         * Get log of data collector instance
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralLog(logRequest?: LogRequest, options?: any): AxiosPromise<Array<LogMessage>> {
            return localVarFp.getGeneralLog(logRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get log of reader. Any provided \"loggerNameRegex\" is ignored
         * @param {string} readerId
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderLog(readerId: string, logRequest?: LogRequest, options?: any): AxiosPromise<Array<LogMessage>> {
            return localVarFp.getReaderLog(readerId, logRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
    /**
     * Get log of data collector instance
     * @param {LogRequest} [logRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public getGeneralLog(logRequest?: LogRequest, options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).getGeneralLog(logRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get log of reader. Any provided \"loggerNameRegex\" is ignored
     * @param {string} readerId
     * @param {LogRequest} [logRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public getReaderLog(readerId: string, logRequest?: LogRequest, options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).getReaderLog(readerId, logRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ManagementApi - axios parameter creator
 * @export
 */
export const ManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmptyConfig: async (schema: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('createEmptyConfig', 'schema', schema)
            const localVarPath = `/rest/management/emptyConfig/{schema}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReader: async (readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('deleteReader', 'readerId', readerId)
            const localVarPath = `/rest/management/{readerId}`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doesReaderExist: async (readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('doesReaderExist', 'readerId', readerId)
            const localVarPath = `/rest/management/exists/{readerId}`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchemas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/management/allSchemas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralConfig: async (readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('getGeneralConfig', 'readerId', readerId)
            const localVarPath = `/rest/management/{readerId}/general`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} meterId
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeterConfig: async (meterId: string, readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meterId' is not null or undefined
            assertParamExists('getMeterConfig', 'meterId', meterId)
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('getMeterConfig', 'readerId', readerId)
            const localVarPath = `/rest/management/{readerId}/meter/{meterId}`
                .replace(`{${"meterId"}}`, encodeURIComponent(String(meterId)))
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderConfig: async (readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('getReaderConfig', 'readerId', readerId)
            const localVarPath = `/rest/management/config/{readerId}`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema: async (schema: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('getSchema', 'schema', schema)
            const localVarPath = `/rest/management/schema/{schema}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedMeterTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/management/supportedMeterTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseConfig: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/management/parseConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/management/reloadAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadReader: async (readerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('reloadReader', 'readerId', readerId)
            const localVarPath = `/rest/management/reload/{readerId}`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} readerId
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGeneralConfig: async (readerId: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('setGeneralConfig', 'readerId', readerId)
            const localVarPath = `/rest/management/{readerId}/general`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} meterId
         * @param {string} readerId
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMeterConfig: async (meterId: string, readerId: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meterId' is not null or undefined
            assertParamExists('setMeterConfig', 'meterId', meterId)
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('setMeterConfig', 'readerId', readerId)
            const localVarPath = `/rest/management/{readerId}/meter/{meterId}`
                .replace(`{${"meterId"}}`, encodeURIComponent(String(meterId)))
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} readerId
         * @param {boolean} [requireExist]
         * @param {boolean} [requireNotExist]
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReaderConfig: async (readerId: string, requireExist?: boolean, requireNotExist?: boolean, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('setReaderConfig', 'readerId', readerId)
            const localVarPath = `/rest/management/{readerId}`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requireExist !== undefined) {
                localVarQueryParameter['requireExist'] = requireExist;
            }

            if (requireNotExist !== undefined) {
                localVarQueryParameter['requireNotExist'] = requireNotExist;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shutdownDatacollector: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/management/shutdownDatacollector`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagementApi - functional programming interface
 * @export
 */
export const ManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagementApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmptyConfig(schema: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmptyConfig(schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReader(readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReader(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doesReaderExist(readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doesReaderExist(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSchemas(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSchemas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralConfig(readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralConfig(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} meterId
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeterConfig(meterId: string, readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeterConfig(meterId, readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaderConfig(readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaderConfig(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchema(schema: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchema(schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportedMeterTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProtocolDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportedMeterTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parseConfig(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicReaderInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parseConfig(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reloadAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reloadAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reloadReader(readerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reloadReader(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} readerId
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGeneralConfig(readerId: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGeneralConfig(readerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} meterId
         * @param {string} readerId
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMeterConfig(meterId: string, readerId: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMeterConfig(meterId, readerId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} readerId
         * @param {boolean} [requireExist]
         * @param {boolean} [requireNotExist]
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setReaderConfig(readerId: string, requireExist?: boolean, requireNotExist?: boolean, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setReaderConfig(readerId, requireExist, requireNotExist, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shutdownDatacollector(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shutdownDatacollector(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManagementApi - factory interface
 * @export
 */
export const ManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagementApiFp(configuration)
    return {
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmptyConfig(schema: string, options?: any): AxiosPromise<any> {
            return localVarFp.createEmptyConfig(schema, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReader(readerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReader(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doesReaderExist(readerId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.doesReaderExist(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchemas(options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.getAllSchemas(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralConfig(readerId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getGeneralConfig(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} meterId
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeterConfig(meterId: string, readerId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getMeterConfig(meterId, readerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderConfig(readerId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getReaderConfig(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema(schema: string, options?: any): AxiosPromise<any> {
            return localVarFp.getSchema(schema, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedMeterTypes(options?: any): AxiosPromise<Array<ProtocolDescription>> {
            return localVarFp.getSupportedMeterTypes(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseConfig(body?: string, options?: any): AxiosPromise<BasicReaderInfo> {
            return localVarFp.parseConfig(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadAll(options?: any): AxiosPromise<void> {
            return localVarFp.reloadAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadReader(readerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.reloadReader(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} readerId
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGeneralConfig(readerId: string, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.setGeneralConfig(readerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} meterId
         * @param {string} readerId
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMeterConfig(meterId: string, readerId: string, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.setMeterConfig(meterId, readerId, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} readerId
         * @param {boolean} [requireExist]
         * @param {boolean} [requireNotExist]
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setReaderConfig(readerId: string, requireExist?: boolean, requireNotExist?: boolean, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.setReaderConfig(readerId, requireExist, requireNotExist, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shutdownDatacollector(options?: any): AxiosPromise<void> {
            return localVarFp.shutdownDatacollector(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagementApi - object-oriented interface
 * @export
 * @class ManagementApi
 * @extends {BaseAPI}
 */
export class ManagementApi extends BaseAPI {
    /**
     *
     * @param {string} schema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public createEmptyConfig(schema: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).createEmptyConfig(schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} readerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public deleteReader(readerId: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).deleteReader(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} readerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public doesReaderExist(readerId: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).doesReaderExist(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getAllSchemas(options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getAllSchemas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} readerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getGeneralConfig(readerId: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getGeneralConfig(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} meterId
     * @param {string} readerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getMeterConfig(meterId: string, readerId: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getMeterConfig(meterId, readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} readerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getReaderConfig(readerId: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getReaderConfig(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} schema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getSchema(schema: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getSchema(schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getSupportedMeterTypes(options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getSupportedMeterTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public parseConfig(body?: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).parseConfig(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public reloadAll(options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).reloadAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} readerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public reloadReader(readerId: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).reloadReader(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} readerId
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public setGeneralConfig(readerId: string, body?: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).setGeneralConfig(readerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} meterId
     * @param {string} readerId
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public setMeterConfig(meterId: string, readerId: string, body?: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).setMeterConfig(meterId, readerId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} readerId
     * @param {boolean} [requireExist]
     * @param {boolean} [requireNotExist]
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public setReaderConfig(readerId: string, requireExist?: boolean, requireNotExist?: boolean, body?: string, options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).setReaderConfig(readerId, requireExist, requireNotExist, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public shutdownDatacollector(options?: AxiosRequestConfig) {
        return ManagementApiFp(this.configuration).shutdownDatacollector(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MeasurementApi - axios parameter creator
 * @export
 */
export const MeasurementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get latest measurements
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMeasurements: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/measurements/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get measurements of a many meters
         * @param {Array<string>} [aMeters] Meters
         * @param {number} [cFrom] Unix instant in milliseconds from which to get measurements
         * @param {number} [dTo] Unix instant in milliseconds to which to get measurements
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMetersMeasurements: async (aMeters?: Array<string>, cFrom?: number, dTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/measurements/meters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (aMeters) {
                localVarQueryParameter['a_meters'] = aMeters;
            }

            if (cFrom !== undefined) {
                localVarQueryParameter['c_from'] = cFrom;
            }

            if (dTo !== undefined) {
                localVarQueryParameter['d_to'] = dTo;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get measurements of a many meters
         * @param {MeasurementRequest} [measurementRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetersMeasurements_1: async (measurementRequest?: MeasurementRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/measurements/meters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(measurementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get product items
         * @param {ProductRequest} [productRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductItems: async (productRequest?: ProductRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/measurements/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasurementApi - functional programming interface
 * @export
 */
export const MeasurementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasurementApiAxiosParamCreator(configuration)
    return {
        /**
         * Get latest measurements
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestMeasurements(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Measurement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestMeasurements(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get measurements of a many meters
         * @param {Array<string>} [aMeters] Meters
         * @param {number} [cFrom] Unix instant in milliseconds from which to get measurements
         * @param {number} [dTo] Unix instant in milliseconds to which to get measurements
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getMetersMeasurements(aMeters?: Array<string>, cFrom?: number, dTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Measurement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetersMeasurements(aMeters, cFrom, dTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get measurements of a many meters
         * @param {MeasurementRequest} [measurementRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetersMeasurements_1(measurementRequest?: MeasurementRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Measurement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetersMeasurements_1(measurementRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get product items
         * @param {ProductRequest} [productRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductItems(productRequest?: ProductRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductItems(productRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeasurementApi - factory interface
 * @export
 */
export const MeasurementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasurementApiFp(configuration)
    return {
        /**
         * Get latest measurements
         * @param {Array<string>} [requestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMeasurements(requestBody?: Array<string>, options?: any): AxiosPromise<Array<Measurement>> {
            return localVarFp.getLatestMeasurements(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Get measurements of a many meters
         * @param {Array<string>} [aMeters] Meters
         * @param {number} [cFrom] Unix instant in milliseconds from which to get measurements
         * @param {number} [dTo] Unix instant in milliseconds to which to get measurements
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMetersMeasurements(aMeters?: Array<string>, cFrom?: number, dTo?: number, options?: any): AxiosPromise<Array<Measurement>> {
            return localVarFp.getMetersMeasurements(aMeters, cFrom, dTo, options).then((request) => request(axios, basePath));
        },
        /**
         * Get measurements of a many meters
         * @param {MeasurementRequest} [measurementRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetersMeasurements_1(measurementRequest?: MeasurementRequest, options?: any): AxiosPromise<Array<Measurement>> {
            return localVarFp.getMetersMeasurements_1(measurementRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get product items
         * @param {ProductRequest} [productRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductItems(productRequest?: ProductRequest, options?: any): AxiosPromise<Array<ProductItem>> {
            return localVarFp.getProductItems(productRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasurementApi - object-oriented interface
 * @export
 * @class MeasurementApi
 * @extends {BaseAPI}
 */
export class MeasurementApi extends BaseAPI {
    /**
     * Get latest measurements
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApi
     */
    public getLatestMeasurements(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return MeasurementApiFp(this.configuration).getLatestMeasurements(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get measurements of a many meters
     * @param {Array<string>} [aMeters] Meters
     * @param {number} [cFrom] Unix instant in milliseconds from which to get measurements
     * @param {number} [dTo] Unix instant in milliseconds to which to get measurements
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof MeasurementApi
     */
    public getMetersMeasurements(aMeters?: Array<string>, cFrom?: number, dTo?: number, options?: AxiosRequestConfig) {
        return MeasurementApiFp(this.configuration).getMetersMeasurements(aMeters, cFrom, dTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get measurements of a many meters
     * @param {MeasurementRequest} [measurementRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApi
     */
    public getMetersMeasurements_1(measurementRequest?: MeasurementRequest, options?: AxiosRequestConfig) {
        return MeasurementApiFp(this.configuration).getMetersMeasurements_1(measurementRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get product items
     * @param {ProductRequest} [productRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementApi
     */
    public getProductItems(productRequest?: ProductRequest, options?: AxiosRequestConfig) {
        return MeasurementApiFp(this.configuration).getProductItems(productRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ObjectApi - axios parameter creator
 * @export
 */
export const ObjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/objects/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/objects/readers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} aReader
         * @param {string} bMeter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags: async (aReader: string, bMeter: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aReader' is not null or undefined
            assertParamExists('getTags', 'aReader', aReader)
            // verify required parameter 'bMeter' is not null or undefined
            assertParamExists('getTags', 'bMeter', bMeter)
            const localVarPath = `/rest/objects/tags/{a_reader}/{b_meter}`
                .replace(`{${"a_reader"}}`, encodeURIComponent(String(aReader)))
                .replace(`{${"b_meter"}}`, encodeURIComponent(String(bMeter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} reader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags_1: async (reader: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reader' is not null or undefined
            assertParamExists('getTags_1', 'reader', reader)
            const localVarPath = `/rest/objects/tags/{reader}`
                .replace(`{${"reader"}}`, encodeURIComponent(String(reader)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObjectApi - functional programming interface
 * @export
 */
export const ObjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObjectApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BasicReaderInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} aReader
         * @param {string} bMeter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTags(aReader: string, bMeter: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTags(aReader, bMeter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} reader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTags_1(reader: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTags_1(reader, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObjectApi - factory interface
 * @export
 */
export const ObjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObjectApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllTags(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaders(options?: any): AxiosPromise<Array<BasicReaderInfo>> {
            return localVarFp.getReaders(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} aReader
         * @param {string} bMeter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags(aReader: string, bMeter: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getTags(aReader, bMeter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} reader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags_1(reader: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getTags_1(reader, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ObjectApi - object-oriented interface
 * @export
 * @class ObjectApi
 * @extends {BaseAPI}
 */
export class ObjectApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public getAllTags(options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).getAllTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public getReaders(options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).getReaders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} aReader
     * @param {string} bMeter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public getTags(aReader: string, bMeter: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).getTags(aReader, bMeter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} reader
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public getTags_1(reader: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).getTags_1(reader, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PostprocessorApi - axios parameter creator
 * @export
 */
export const PostprocessorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Delete postprocessor config by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfig: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConfig', 'id', id)
            const localVarPath = `/rest/postprocessors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get all postprocessor configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConfigs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/postprocessors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchemas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/postprocessors/allSchemas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get postprocessor config by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConfig', 'id', id)
            const localVarPath = `/rest/postprocessors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema: async (schema: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('getSchema', 'schema', schema)
            const localVarPath = `/rest/postprocessors/schema/{schema}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedPostProcessors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/postprocessors/supportedPostprocessors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Set postprocessor config by id
         * @param {boolean} [restartReaders]
         * @param {PostProcessorConfigDTO} [postProcessorConfigDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setConfig: async (restartReaders?: boolean, postProcessorConfigDTO?: PostProcessorConfigDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/postprocessors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (restartReaders !== undefined) {
                localVarQueryParameter['restartReaders'] = restartReaders;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProcessorConfigDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostprocessorApi - functional programming interface
 * @export
 */
export const PostprocessorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostprocessorApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Delete postprocessor config by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConfig(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConfig(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get all postprocessor configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllConfigs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostProcessorConfigDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConfigs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSchemas(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSchemas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get postprocessor config by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfig(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostProcessorConfigDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfig(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchema(schema: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchema(schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportedPostProcessors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostProcessorDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportedPostProcessors(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Set postprocessor config by id
         * @param {boolean} [restartReaders]
         * @param {PostProcessorConfigDTO} [postProcessorConfigDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setConfig(restartReaders?: boolean, postProcessorConfigDTO?: PostProcessorConfigDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setConfig(restartReaders, postProcessorConfigDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostprocessorApi - factory interface
 * @export
 */
export const PostprocessorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostprocessorApiFp(configuration)
    return {
        /**
         *
         * @summary Delete postprocessor config by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfig(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteConfig(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all postprocessor configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConfigs(options?: any): AxiosPromise<Array<PostProcessorConfigDTO>> {
            return localVarFp.getAllConfigs(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchemas(options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.getAllSchemas(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get postprocessor config by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig(id: string, options?: any): AxiosPromise<PostProcessorConfigDTO> {
            return localVarFp.getConfig(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema(schema: string, options?: any): AxiosPromise<any> {
            return localVarFp.getSchema(schema, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedPostProcessors(options?: any): AxiosPromise<Array<PostProcessorDescription>> {
            return localVarFp.getSupportedPostProcessors(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set postprocessor config by id
         * @param {boolean} [restartReaders]
         * @param {PostProcessorConfigDTO} [postProcessorConfigDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setConfig(restartReaders?: boolean, postProcessorConfigDTO?: PostProcessorConfigDTO, options?: any): AxiosPromise<void> {
            return localVarFp.setConfig(restartReaders, postProcessorConfigDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostprocessorApi - object-oriented interface
 * @export
 * @class PostprocessorApi
 * @extends {BaseAPI}
 */
export class PostprocessorApi extends BaseAPI {
    /**
     *
     * @summary Delete postprocessor config by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostprocessorApi
     */
    public deleteConfig(id: string, options?: AxiosRequestConfig) {
        return PostprocessorApiFp(this.configuration).deleteConfig(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get all postprocessor configs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostprocessorApi
     */
    public getAllConfigs(options?: AxiosRequestConfig) {
        return PostprocessorApiFp(this.configuration).getAllConfigs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostprocessorApi
     */
    public getAllSchemas(options?: AxiosRequestConfig) {
        return PostprocessorApiFp(this.configuration).getAllSchemas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get postprocessor config by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostprocessorApi
     */
    public getConfig(id: string, options?: AxiosRequestConfig) {
        return PostprocessorApiFp(this.configuration).getConfig(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} schema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostprocessorApi
     */
    public getSchema(schema: string, options?: AxiosRequestConfig) {
        return PostprocessorApiFp(this.configuration).getSchema(schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostprocessorApi
     */
    public getSupportedPostProcessors(options?: AxiosRequestConfig) {
        return PostprocessorApiFp(this.configuration).getSupportedPostProcessors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Set postprocessor config by id
     * @param {boolean} [restartReaders]
     * @param {PostProcessorConfigDTO} [postProcessorConfigDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostprocessorApi
     */
    public setConfig(restartReaders?: boolean, postProcessorConfigDTO?: PostProcessorConfigDTO, options?: AxiosRequestConfig) {
        return PostprocessorApiFp(this.configuration).setConfig(restartReaders, postProcessorConfigDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestingApi - axios parameter creator
 * @export
 */
export const TestingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRunData: async (runId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('getRunData', 'runId', runId)
            const localVarPath = `/rest/testing/getRunData/{runId}`
                .replace(`{${"runId"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runExists: async (runId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('runExists', 'runId', runId)
            const localVarPath = `/rest/testing/runExists/{runId}`
                .replace(`{${"runId"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DataHoldMode} [dataHoldMode]
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRun: async (dataHoldMode?: DataHoldMode, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/testing/startRun`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dataHoldMode !== undefined) {
                localVarQueryParameter['dataHoldMode'] = dataHoldMode;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopRun: async (runId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('stopRun', 'runId', runId)
            const localVarPath = `/rest/testing/stopRun/{runId}`
                .replace(`{${"runId"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {TagMapperTestRequest} [tagMapperTestRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTagMapper: async (tagMapperTestRequest?: TagMapperTestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/testing/testTagMapper`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagMapperTestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestingApi - functional programming interface
 * @export
 */
export const TestingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestingApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRunData(runId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Measurement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRunData(runId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runExists(runId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runExists(runId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DataHoldMode} [dataHoldMode]
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startRun(dataHoldMode?: DataHoldMode, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startRun(dataHoldMode, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopRun(runId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopRun(runId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {TagMapperTestRequest} [tagMapperTestRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTagMapper(tagMapperTestRequest?: TagMapperTestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTagMapper(tagMapperTestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestingApi - factory interface
 * @export
 */
export const TestingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestingApiFp(configuration)
    return {
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRunData(runId: string, options?: any): AxiosPromise<Array<Measurement>> {
            return localVarFp.getRunData(runId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runExists(runId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.runExists(runId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DataHoldMode} [dataHoldMode]
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startRun(dataHoldMode?: DataHoldMode, body?: string, options?: any): AxiosPromise<string> {
            return localVarFp.startRun(dataHoldMode, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopRun(runId: string, options?: any): AxiosPromise<void> {
            return localVarFp.stopRun(runId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {TagMapperTestRequest} [tagMapperTestRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTagMapper(tagMapperTestRequest?: TagMapperTestRequest, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.testTagMapper(tagMapperTestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestingApi - object-oriented interface
 * @export
 * @class TestingApi
 * @extends {BaseAPI}
 */
export class TestingApi extends BaseAPI {
    /**
     *
     * @param {string} runId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingApi
     */
    public getRunData(runId: string, options?: AxiosRequestConfig) {
        return TestingApiFp(this.configuration).getRunData(runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} runId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingApi
     */
    public runExists(runId: string, options?: AxiosRequestConfig) {
        return TestingApiFp(this.configuration).runExists(runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DataHoldMode} [dataHoldMode]
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingApi
     */
    public startRun(dataHoldMode?: DataHoldMode, body?: string, options?: AxiosRequestConfig) {
        return TestingApiFp(this.configuration).startRun(dataHoldMode, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} runId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingApi
     */
    public stopRun(runId: string, options?: AxiosRequestConfig) {
        return TestingApiFp(this.configuration).stopRun(runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {TagMapperTestRequest} [tagMapperTestRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingApi
     */
    public testTagMapper(tagMapperTestRequest?: TagMapperTestRequest, options?: AxiosRequestConfig) {
        return TestingApiFp(this.configuration).testTagMapper(tagMapperTestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ToolsApi - axios parameter creator
 * @export
 */
export const ToolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} runId
         * @param {number} [since]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutput: async (runId: string, since?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('getOutput', 'runId', runId)
            const localVarPath = `/rest/tools/{runId}`
                .replace(`{${"runId"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killRun: async (runId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('killRun', 'runId', runId)
            const localVarPath = `/rest/tools/{runId}`
                .replace(`{${"runId"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PingParameters} [pingParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPing: async (pingParameters?: PingParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/tools/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pingParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {TracerouteParameters} [tracerouteParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTraceroute: async (tracerouteParameters?: TracerouteParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/tools/traceroute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tracerouteParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ToolsApi - functional programming interface
 * @export
 */
export const ToolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ToolsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} runId
         * @param {number} [since]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutput(runId: string, since?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOutput(runId, since, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async killRun(runId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.killRun(runId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PingParameters} [pingParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startPing(pingParameters?: PingParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startPing(pingParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {TracerouteParameters} [tracerouteParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startTraceroute(tracerouteParameters?: TracerouteParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startTraceroute(tracerouteParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ToolsApi - factory interface
 * @export
 */
export const ToolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ToolsApiFp(configuration)
    return {
        /**
         *
         * @param {string} runId
         * @param {number} [since]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutput(runId: string, since?: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getOutput(runId, since, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} runId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        killRun(runId: string, options?: any): AxiosPromise<void> {
            return localVarFp.killRun(runId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PingParameters} [pingParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPing(pingParameters?: PingParameters, options?: any): AxiosPromise<string> {
            return localVarFp.startPing(pingParameters, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {TracerouteParameters} [tracerouteParameters]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startTraceroute(tracerouteParameters?: TracerouteParameters, options?: any): AxiosPromise<string> {
            return localVarFp.startTraceroute(tracerouteParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ToolsApi - object-oriented interface
 * @export
 * @class ToolsApi
 * @extends {BaseAPI}
 */
export class ToolsApi extends BaseAPI {
    /**
     *
     * @param {string} runId
     * @param {number} [since]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    public getOutput(runId: string, since?: number, options?: AxiosRequestConfig) {
        return ToolsApiFp(this.configuration).getOutput(runId, since, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} runId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    public killRun(runId: string, options?: AxiosRequestConfig) {
        return ToolsApiFp(this.configuration).killRun(runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PingParameters} [pingParameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    public startPing(pingParameters?: PingParameters, options?: AxiosRequestConfig) {
        return ToolsApiFp(this.configuration).startPing(pingParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {TracerouteParameters} [tracerouteParameters]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    public startTraceroute(tracerouteParameters?: TracerouteParameters, options?: AxiosRequestConfig) {
        return ToolsApiFp(this.configuration).startTraceroute(tracerouteParameters, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WriteApi - axios parameter creator
 * @export
 */
export const WriteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Writer given measurements to database
         * @param {File} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeMeasurements: async (body?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/write/measurements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Writer given product items to database
         * @param {ProductEntries} [productEntries]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeProductItems: async (productEntries?: ProductEntries, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rest/write/productItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productEntries, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WriteApi - functional programming interface
 * @export
 */
export const WriteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WriteApiAxiosParamCreator(configuration)
    return {
        /**
         * Writer given measurements to database
         * @param {File} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writeMeasurements(body?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writeMeasurements(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Writer given product items to database
         * @param {ProductEntries} [productEntries]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writeProductItems(productEntries?: ProductEntries, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writeProductItems(productEntries, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WriteApi - factory interface
 * @export
 */
export const WriteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WriteApiFp(configuration)
    return {
        /**
         * Writer given measurements to database
         * @param {File} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeMeasurements(body?: File, options?: any): AxiosPromise<void> {
            return localVarFp.writeMeasurements(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Writer given product items to database
         * @param {ProductEntries} [productEntries]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeProductItems(productEntries?: ProductEntries, options?: any): AxiosPromise<void> {
            return localVarFp.writeProductItems(productEntries, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WriteApi - object-oriented interface
 * @export
 * @class WriteApi
 * @extends {BaseAPI}
 */
export class WriteApi extends BaseAPI {
    /**
     * Writer given measurements to database
     * @param {File} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WriteApi
     */
    public writeMeasurements(body?: File, options?: AxiosRequestConfig) {
        return WriteApiFp(this.configuration).writeMeasurements(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Writer given product items to database
     * @param {ProductEntries} [productEntries]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WriteApi
     */
    public writeProductItems(productEntries?: ProductEntries, options?: AxiosRequestConfig) {
        return WriteApiFp(this.configuration).writeProductItems(productEntries, options).then((request) => request(this.axios, this.basePath));
    }
}



