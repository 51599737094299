import React from 'react';
import {Dropdown} from "primereact/dropdown";

import './OutputsDropdown.scss'

type OutputWindowSelectedProps = {
    selectOutput: (confId: string) => void,
    selected: string,
    setSelected: (confId: string) => void,
    options: Array<{ label: string, value: string }>
}

const OutputsDropdown = ({options, selectOutput,setSelected,selected}: OutputWindowSelectedProps) => {
    return <Dropdown value={selected}
                     options={options}
                     onChange={(e) => {
                         selectOutput(e.value)
                         setSelected(e.value)
                     }}
                     className={"p-dropdown-sm"}
                     disabled={options?.length === 1}
    />
};

export default OutputsDropdown;