import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css"; //core css
import "primereact/resources/themes/bootstrap4-dark-blue/theme.css"; //theme
import { HashRouter } from "react-router-dom";
import App from "./App";
import { apisStore } from "./context/GlobalStates";
import UserService from "./service/UserService";
import Utils from "./util/Utils";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient();

const serviceId = new URLSearchParams(window.location.search).get("serviceId");

const renderRoot = () =>
	root.render(
		<HashRouter>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</HashRouter>,
	);

if (serviceId) {
    console.log("ServiceId - ", serviceId)
    apisStore.setState(({serviceId: serviceId}))
    UserService.initKeycloak(renderRoot)
} else {
    renderRoot()
}


console.log(Utils.getContextPath())

reportWebVitals();
