import React from 'react';
import NotificationPopUp from "util/NotificationPopUp";
import {ConfirmPopup} from "primereact/confirmpopup";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ManagementApi} from "serviceapi/dataPusherApi";

import './PipelineRemove.scss';
import {apisStore} from "context/GlobalStates";

type WriterRemoveProps = {
    writerId: string,
    unsetSelectedConfig(): void,
    closeDialog(): void
}

class WriterRemoveState {
    value: string = "";
    visible: boolean = false;
}

class PipelineRemove extends React.Component<WriterRemoveProps, WriterRemoveState> {
    private managementApi: ManagementApi = (apisStore.getState().dataPusher as any).managementApiConst;

    constructor(props: Readonly<WriterRemoveProps>) {
        super(props);

        this.state = new WriterRemoveState();
    }

    private confirm = () => {
        const er = this.props.writerId;
        if (!er) {
            return;
        }

        this.managementApi.deletePipeline(this.props.writerId)
            .then((e: any) => {
                if (!e) {
                    NotificationPopUp.show("Network Error", 'error')
                    return
                }

                NotificationPopUp.show(`Pipeline has been deleted`, 'success')
            })
            .then(this.props.unsetSelectedConfig)
            .then(() => {
                this.props.closeDialog()
            })
            .catch(er => {
                NotificationPopUp.show(er.message, 'error')
            })
    }

    private buttonDisable = () => {
        return this.props.writerId !== this.state.value;
    }

    render() {
        return (
            <div>
                <ConfirmPopup/>

                <div className={"text-alignment"}>
                    To remove this reader write reader id: [<span
                    className={"writer-name"}><b>{this.props.writerId}</b></span>]
                </div>

                <InputText style={{alignSelf: "center"}}
                           value={this.state.value}
                           onChange={(e) => this.setState({value: e.target.value})}/>

                <Button id="confirm-button"
                        style={{transform: "scale(90%)"}}
                        label="Remove"
                        icon="pi pi-times"
                        onClick={this.confirm}
                        disabled={this.buttonDisable()}/>
            </div>
        )
    }
}

export default PipelineRemove;