import React, {useState} from 'react';
import {Tooltip} from "react-tooltip";

import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import RemoveConfirmation from "./RemoveConfirmation";
import NotificationPopUp from "util/NotificationPopUp";


type DeleteOutputProps = {
    outputId: string,
    confirm(): void,
    disabled?: boolean
}

const DeleteOutput = ({outputId, confirm,disabled}: DeleteOutputProps) => {

    const [dialog, setDialog] = useState<boolean>(false)

    return <>
        <Tooltip id={"remove-old-output"}/>
        <Button className="add-pipeline-button p-button-danger"
                icon="pi pi-trash"
                onClick={() => setDialog(true)}
                data-tooltip-content={"Remove output"}
                data-tooltip-id={"remove-old-output"}
                disabled={disabled}
        />
        {dialog &&
            <Dialog onHide={() => setDialog(false)}
                    visible={dialog}
                    header="Remove output"
            >
                <RemoveConfirmation outputId={outputId} confirm={() => {
                    confirm();
                    setDialog(false);
                    NotificationPopUp.show("Output removed successfully", "success")
                }}/>
            </Dialog>
        }

    </>
};

export default DeleteOutput