import React, {useId} from 'react';
import {Button} from "primereact/button";
import {ReaderCreator} from "reader/ReaderCreator";
import {Tooltip} from "react-tooltip";

type CreateReaderButtonProps = {
    onUnselectTree: () => void;
    updateReaders: (readerId: string) => void;
}

const CreateReaderButton = ({onUnselectTree, updateReaders}: CreateReaderButtonProps) => {
    const id = useId()
    const readerCreatorRef = React.useRef<ReaderCreator>(null);

    const newReader = () => {
        readerCreatorRef.current?.start();
        onUnselectTree();

    }

    return <>
        <ReaderCreator ref={readerCreatorRef}
                       onComplete={(r) => r.readerInfo.id ? updateReaders(r.readerInfo.id) : null}
        />
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <Button icon={"pi pi-plus"}
                data-tooltip-id={id}
                data-tooltip-content={"Add"}
                className="p-button-success p-button-sm"
                onClick={() => newReader()}
        />
    </>
};

export default CreateReaderButton;