import React, {useId, useState} from 'react';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {HideLoadingIndicator, LogHeaderHandler, ShowLoadingIndicator} from "components/dialogs/dialogHeaders";
import ReaderLogViewer from "components/data-collector/ReaderLogs/ReaderLogViewer";
import {Reader} from "reader/Reader";
import {apisStore} from "context/GlobalStates";
import {Tooltip} from "react-tooltip";

type CollectorLogsButtonProps = {
    editingReader?:Reader
}

const CollectorLogsButton = ({editingReader}:CollectorLogsButtonProps) => {
    const id = useId()
    const [visible, setVisible] = useState(false);

    const apis = apisStore().dataCollector as any;

    return <>
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <Button
            className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
            icon={"pi pi-book"}
            data-tooltip-id={id}
            data-tooltip-content={"Logs"}
            onClick={() => setVisible(true)}
            disabled={editingReader == null}
        />

        {visible &&
            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                style={{width: "70vW", height: "70vh"}}
                header={LogHeaderHandler(editingReader?.readerInfo.id)}
                breakpoints={{'960px': '85vw', '640px': '100vw'}}
            >
                <div style={{height: "100%", overflowY: "hidden"}}>
                    <ReaderLogViewer api={apis.logsApi}
                                     readerId={editingReader?.readerInfo.id}
                                     showProgressIndicator={ShowLoadingIndicator}
                                     hideProgressIndicator={HideLoadingIndicator}/>
                </div>

            </Dialog>
        }
    </>
};

export default CollectorLogsButton;