import React, {useId} from 'react';
import {Button} from "primereact/button";
import {Tooltip} from "react-tooltip";

type SaveReaderProps = {
    saveReader: () => void;
    editingReader: any;
}

const SaveReaderButton = ({editingReader, saveReader}: SaveReaderProps) => {
    const id = useId()
    return <>
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <Button
            className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
            icon={"pi pi-save"}
            data-tooltip-id={id}
            data-tooltip-content={"Save"}
            onClick={saveReader}
            disabled={editingReader == null}
        />
    </>
};

export default SaveReaderButton;