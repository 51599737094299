import React, {useId, useState} from 'react';
import NotificationPopUp from "util/NotificationPopUp";
import {ConfirmDialog} from "primereact/confirmdialog";
import {InputSwitch} from "primereact/inputswitch";
import {BasicPipelineInfo, ManagementApi} from "serviceapi/dataPusherApi";
import {Tooltip} from "react-tooltip";
import {CLIENT_TIMEOUT, DEFAULT_OPTIONS} from "../../../../service/HttpService";

const WriterToggleButton = ({
                                writer,
                                onWriterToggled,
                                managementApi
                            }: {
    writer: BasicPipelineInfo,
    onWriterToggled: () => void,
    managementApi: ManagementApi
}) => {
    const id = useId()
    const [visible, setVisible] = useState(false);

    const confirmationHandler = () => {
        setVisible(true);
    };

    const handleChange = async () => {
        if (!writer || !writer.id) return;

        try {
            const obj = await managementApi.getPipelineConfig(writer.id, DEFAULT_OPTIONS);
            let tempObj: any = obj.data;
            tempObj.enabled = !tempObj.enabled;

            await managementApi.setPipelineConfig(tempObj.id, false, false, tempObj, DEFAULT_OPTIONS);

            if (!writer.enabled) {
                NotificationPopUp.show("Starting data writer", 'success');
            } else {
                NotificationPopUp.show("Stopping data writer", 'success');
            }
        } catch (err: any) {
            console.error(err);
            NotificationPopUp.show("Pipeline may not have been saved or reloaded.", 'error');
        }

        onWriterToggled();
    };

    const getTooltipMessage = writer.enabled ? "Turn writer off" : "Turn writer on";
    const getConfirmationMessage = writer.enabled ? "Do you want to turn off the writer?" : "Do you want to turn on the writer?";

    return (
        <div className="toggle-button-custom">
            <Tooltip id={id} place={"left"} style={{fontSize: 24}}/>
            <ConfirmDialog
                visible={visible}
                header="Confirmation"
                onHide={() => setVisible(false)}
                message={getConfirmationMessage}
                icon="pi pi-exclamation-triangle"
                accept={handleChange}
            />
            {writer &&
                <InputSwitch
                    checked={writer.enabled}
                    onChange={confirmationHandler}
                    data-tooltip-id={id}
                    data-tooltip-content={getTooltipMessage}
                    className="reader-switch"
                />
            }
        </div>
    );
};

export default WriterToggleButton;
