import React, {useId} from 'react';
import {Dialog} from "primereact/dialog";
import PipelineHistory from "components/data-writer/PipelineHistory/PipelineHistory";
import {Button} from "primereact/button";
import {Tooltip} from "react-tooltip";

type WriterHistoryButtonProps = {
    pipelineId: string | undefined;
}

const WriterHistoryButton = ({pipelineId}: WriterHistoryButtonProps) => {
    const id = useId()
    const [writerHistoryDialog, setWriterHistoryDialog] = React.useState(false);

    return <>
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <Button
            className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
            icon={"pi pi-history"}
            data-tooltip-id={id}
            data-tooltip-content="History"
            onClick={() => setWriterHistoryDialog(true)}
            disabled={!pipelineId}
        />
        {writerHistoryDialog &&
            <Dialog
                visible={writerHistoryDialog}
                onHide={() => setWriterHistoryDialog(false)}
                style={{width: "80vW", height: "70vh"}}
                breakpoints={{'960px': '85vw', '640px': '100vw'}}
                header={"Writer pipeline history"}
            >
                <PipelineHistory pipelineId={pipelineId}/>
            </Dialog>
        }
    </>
};

export default WriterHistoryButton;