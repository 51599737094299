import React from 'react';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/prism";
import { dracula as style } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './CheatSheet.scss';


type CheatSheetProps = {
    implementationKey:string;
}

class CheatSheetState {
    text: string = "";
}

class CheatSheet extends React.Component<CheatSheetProps, CheatSheetState> {
    constructor(props:Readonly<CheatSheetProps>) {
        super(props);
        this.state = new CheatSheetState();
    }

    componentDidMount() {
        this.cheatSheetExists(this.props.implementationKey)
            .then(res => {
                if(res.slice(0,15)!=="<!DOCTYPE html>" && res.slice(0,15)!=="<!doctype html>") {
                    this.setState({text: res})
                } else {
                    this.setState({text: "No manual"})
                }
            })
            .catch(er => console.log(er))
    }

    private async cheatSheetExists(name: string) {
        return (await fetch(process.env.PUBLIC_URL +'/resources/'+ name +'.md')).text()
    }

    render() {
        const {text} = this.state;
        return <ReactMarkdown children={text}
                              remarkPlugins={[remarkGfm]}
                              components={{
                                  code({ node, inline, className, children, ...props }) {
                                      const match = /language-(\w+)/.exec(className || "");
                                      return !inline && match ? (
                                          <SyntaxHighlighter
                                              children={String(children).replace(/\n$/, "")}
                                              language={match[1]}
                                              style={style}
                                          />
                                      ) : (
                                          <code className={className} {...props}>
                                              {children}
                                          </code>
                                      );
                                  },
                              }}
        />
    }
}

export default CheatSheet;