/* tslint:disable */
/* eslint-disable */
/**
 * data-pusher-app API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 4.11.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 *
 * @export
 * @interface ActionDTO
 */
export interface ActionDTO {
    /**
     *
     * @type {string}
     * @memberof ActionDTO
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ActionDTO
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof ActionDTO
     */
    'description'?: string;
    /**
     *
     * @type {any}
     * @memberof ActionDTO
     */
    'returnSchema'?: any;
    /**
     *
     * @type {Array<ArgumentInfoDTO>}
     * @memberof ActionDTO
     */
    'arguments'?: Array<ArgumentInfoDTO>;
}
/**
 *
 * @export
 * @interface ArgumentInfoDTO
 */
export interface ArgumentInfoDTO {
    /**
     *
     * @type {string}
     * @memberof ArgumentInfoDTO
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof ArgumentInfoDTO
     */
    'description'?: string;
    /**
     *
     * @type {any}
     * @memberof ArgumentInfoDTO
     */
    'argumentSchema'?: any;
}
/**
 *
 * @export
 * @interface BackupEntry
 */
export interface BackupEntry {
    /**
     *
     * @type {number}
     * @memberof BackupEntry
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof BackupEntry
     */
    'readerId'?: string;
    /**
     *
     * @type {string}
     * @memberof BackupEntry
     */
    'timestamp'?: string;
}
/**
 *
 * @export
 * @interface BasicPipelineInfo
 */
export interface BasicPipelineInfo {
    /**
     *
     * @type {boolean}
     * @memberof BasicPipelineInfo
     */
    'enabled'?: boolean;
    /**
     *
     * @type {string}
     * @memberof BasicPipelineInfo
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof BasicPipelineInfo
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof BasicPipelineInfo
     */
    'inputSchemaType'?: string;
    /**
     *
     * @type {string}
     * @memberof BasicPipelineInfo
     */
    'processingSchemaType'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof BasicPipelineInfo
     */
    'outputSchemaTypes'?: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const LogLevel = {
    Off: 'OFF',
    Severe: 'SEVERE',
    Warning: 'WARNING',
    Info: 'INFO',
    Config: 'CONFIG',
    Fine: 'FINE',
    Finer: 'FINER',
    Finest: 'FINEST',
    All: 'ALL'
} as const;

export type LogLevel = typeof LogLevel[keyof typeof LogLevel];


/**
 *
 * @export
 * @interface LogMessage
 */
export interface LogMessage {
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'timestamp'?: string;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'loggerName'?: string;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'className'?: string;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'methodName'?: string;
    /**
     *
     * @type {number}
     * @memberof LogMessage
     */
    'levelInt'?: number;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'level'?: string;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'message'?: string;
    /**
     *
     * @type {boolean}
     * @memberof LogMessage
     */
    'hasException'?: boolean;
    /**
     *
     * @type {string}
     * @memberof LogMessage
     */
    'exceptionType'?: string;
}
/**
 *
 * @export
 * @interface LogRequest
 */
export interface LogRequest {
    /**
     *
     * @type {string}
     * @memberof LogRequest
     */
    'from'?: string;
    /**
     *
     * @type {string}
     * @memberof LogRequest
     */
    'to'?: string;
    /**
     *
     * @type {string}
     * @memberof LogRequest
     */
    'duration'?: string;
    /**
     *
     * @type {number}
     * @memberof LogRequest
     */
    'limit'?: number;
    /**
     *
     * @type {LogLevel}
     * @memberof LogRequest
     */
    'minLevel'?: LogLevel;
    /**
     *
     * @type {boolean}
     * @memberof LogRequest
     */
    'requireException'?: boolean;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof LogRequest
     */
    'likeRequests'?: { [key: string]: string; };
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof LogRequest
     */
    'equalRequests'?: { [key: string]: string; };
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof LogRequest
     */
    'regexRequests'?: { [key: string]: string; };
}


/**
 *
 * @export
 * @interface ProtocolDescription
 */
export interface ProtocolDescription {
    /**
     *
     * @type {string}
     * @memberof ProtocolDescription
     */
    'implementationKey': string;
    /**
     *
     * @type {SchemaType}
     * @memberof ProtocolDescription
     */
    'schemaType': SchemaType;
    /**
     *
     * @type {string}
     * @memberof ProtocolDescription
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof ProtocolDescription
     */
    'description': string;
}


/**
 *
 * @export
 * @enum {string}
 */

export const SchemaType = {
    Input: 'INPUT',
    Processing: 'PROCESSING',
    Output: 'OUTPUT'
} as const;

export type SchemaType = typeof SchemaType[keyof typeof SchemaType];



/**
 * ActionsApi - axios parameter creator
 * @export
 */
export const ActionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get actions for schema
         * @param {string} schema
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActions: async (schema: string, schemaType: SchemaType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('getActions', 'schema', schema)
            // verify required parameter 'schemaType' is not null or undefined
            assertParamExists('getActions', 'schemaType', schemaType)
            const localVarPath = `/actions/{schemaType}/{schema}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"schemaType"}}`, encodeURIComponent(String(schemaType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Invoke action
         * @param {string} aPipelineId
         * @param {SchemaType} bSchemaType
         * @param {string} cPartId
         * @param {string} dActionId
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invokeAction: async (aPipelineId: string, bSchemaType: SchemaType, cPartId: string, dActionId: string, body?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aPipelineId' is not null or undefined
            assertParamExists('invokeAction', 'aPipelineId', aPipelineId)
            // verify required parameter 'bSchemaType' is not null or undefined
            assertParamExists('invokeAction', 'bSchemaType', bSchemaType)
            // verify required parameter 'cPartId' is not null or undefined
            assertParamExists('invokeAction', 'cPartId', cPartId)
            // verify required parameter 'dActionId' is not null or undefined
            assertParamExists('invokeAction', 'dActionId', dActionId)
            const localVarPath = `/actions/invoke/{a_pipelineId}/{b_schemaType}/{c_partId}/{d_actionId}`
                .replace(`{${"a_pipelineId"}}`, encodeURIComponent(String(aPipelineId)))
                .replace(`{${"b_schemaType"}}`, encodeURIComponent(String(bSchemaType)))
                .replace(`{${"c_partId"}}`, encodeURIComponent(String(cPartId)))
                .replace(`{${"d_actionId"}}`, encodeURIComponent(String(dActionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionsApi - functional programming interface
 * @export
 */
export const ActionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get actions for schema
         * @param {string} schema
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActions(schema: string, schemaType: SchemaType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActions(schema, schemaType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionsApi.getActions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @summary Invoke action
         * @param {string} aPipelineId
         * @param {SchemaType} bSchemaType
         * @param {string} cPartId
         * @param {string} dActionId
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invokeAction(aPipelineId: string, bSchemaType: SchemaType, cPartId: string, dActionId: string, body?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invokeAction(aPipelineId, bSchemaType, cPartId, dActionId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionsApi.invokeAction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActionsApi - factory interface
 * @export
 */
export const ActionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionsApiFp(configuration)
    return {
        /**
         *
         * @summary Get actions for schema
         * @param {string} schema
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActions(schema: string, schemaType: SchemaType, options?: any): AxiosPromise<Array<ActionDTO>> {
            return localVarFp.getActions(schema, schemaType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Invoke action
         * @param {string} aPipelineId
         * @param {SchemaType} bSchemaType
         * @param {string} cPartId
         * @param {string} dActionId
         * @param {any} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invokeAction(aPipelineId: string, bSchemaType: SchemaType, cPartId: string, dActionId: string, body?: any, options?: any): AxiosPromise<any> {
            return localVarFp.invokeAction(aPipelineId, bSchemaType, cPartId, dActionId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionsApi - object-oriented interface
 * @export
 * @class ActionsApi
 * @extends {BaseAPI}
 */
export class ActionsApi extends BaseAPI {
    /**
     *
     * @summary Get actions for schema
     * @param {string} schema
     * @param {SchemaType} schemaType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public getActions(schema: string, schemaType: SchemaType, options?: RawAxiosRequestConfig) {
        return ActionsApiFp(this.configuration).getActions(schema, schemaType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Invoke action
     * @param {string} aPipelineId
     * @param {SchemaType} bSchemaType
     * @param {string} cPartId
     * @param {string} dActionId
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public invokeAction(aPipelineId: string, bSchemaType: SchemaType, cPartId: string, dActionId: string, body?: any, options?: RawAxiosRequestConfig) {
        return ActionsApiFp(this.configuration).invokeAction(aPipelineId, bSchemaType, cPartId, dActionId, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BackupApi - axios parameter creator
 * @export
 */
export const BackupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBackup: async (readerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('createBackup', 'readerId', readerId)
            const localVarPath = `/backup/backup/{readerId}`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<number>} ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackup: async (ids: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteBackup', 'ids', ids)
            const localVarPath = `/backup/backup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<string>} [ids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackups: async (ids?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/backup/entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationBackup: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConfigurationBackup', 'id', id)
            const localVarPath = `/backup/backup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} readerId
         * @param {number} backupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreBackup: async (readerId: string, backupId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('restoreBackup', 'readerId', readerId)
            // verify required parameter 'backupId' is not null or undefined
            assertParamExists('restoreBackup', 'backupId', backupId)
            const localVarPath = `/backup/restore/{readerId}`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (backupId !== undefined) {
                localVarQueryParameter['backupId'] = backupId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackupApi - functional programming interface
 * @export
 */
export const BackupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackupApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBackup(readerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBackup(readerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackupApi.createBackup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {Array<number>} ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBackup(ids: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBackup(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackupApi.deleteBackup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {Array<string>} [ids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBackups(ids?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackupEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBackups(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackupApi.getBackups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigurationBackup(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigurationBackup(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackupApi.getConfigurationBackup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} readerId
         * @param {number} backupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreBackup(readerId: string, backupId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreBackup(readerId, backupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BackupApi.restoreBackup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BackupApi - factory interface
 * @export
 */
export const BackupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackupApiFp(configuration)
    return {
        /**
         *
         * @param {string} readerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBackup(readerId: string, options?: any): AxiosPromise<number> {
            return localVarFp.createBackup(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<number>} ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackup(ids: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBackup(ids, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} [ids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackups(ids?: Array<string>, options?: any): AxiosPromise<Array<BackupEntry>> {
            return localVarFp.getBackups(ids, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationBackup(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.getConfigurationBackup(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} readerId
         * @param {number} backupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreBackup(readerId: string, backupId: number, options?: any): AxiosPromise<void> {
            return localVarFp.restoreBackup(readerId, backupId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackupApi - object-oriented interface
 * @export
 * @class BackupApi
 * @extends {BaseAPI}
 */
export class BackupApi extends BaseAPI {
    /**
     *
     * @param {string} readerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public createBackup(readerId: string, options?: RawAxiosRequestConfig) {
        return BackupApiFp(this.configuration).createBackup(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Array<number>} ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public deleteBackup(ids: Array<number>, options?: RawAxiosRequestConfig) {
        return BackupApiFp(this.configuration).deleteBackup(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {Array<string>} [ids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public getBackups(ids?: Array<string>, options?: RawAxiosRequestConfig) {
        return BackupApiFp(this.configuration).getBackups(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public getConfigurationBackup(id: number, options?: RawAxiosRequestConfig) {
        return BackupApiFp(this.configuration).getConfigurationBackup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} readerId
     * @param {number} backupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackupApi
     */
    public restoreBackup(readerId: string, backupId: number, options?: RawAxiosRequestConfig) {
        return BackupApiFp(this.configuration).restoreBackup(readerId, backupId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get log of data collector instance
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralLog: async (logRequest?: LogRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logs/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get log of pipeline. Any provided \"loggerNameRegex\" is ignored
         * @param {string} pipelineId
         * @param {boolean} [input]
         * @param {boolean} [output]
         * @param {boolean} [pipeline]
         * @param {boolean} [processor]
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipelineLog: async (pipelineId: string, input?: boolean, output?: boolean, pipeline?: boolean, processor?: boolean, logRequest?: LogRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pipelineId' is not null or undefined
            assertParamExists('getPipelineLog', 'pipelineId', pipelineId)
            const localVarPath = `/logs/pipeline/{pipelineId}`
                .replace(`{${"pipelineId"}}`, encodeURIComponent(String(pipelineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (input !== undefined) {
                localVarQueryParameter['input'] = input;
            }

            if (output !== undefined) {
                localVarQueryParameter['output'] = output;
            }

            if (pipeline !== undefined) {
                localVarQueryParameter['pipeline'] = pipeline;
            }

            if (processor !== undefined) {
                localVarQueryParameter['processor'] = processor;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get log of data collector instance
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralLog(logRequest?: LogRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralLog(logRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogsApi.getGeneralLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get log of pipeline. Any provided \"loggerNameRegex\" is ignored
         * @param {string} pipelineId
         * @param {boolean} [input]
         * @param {boolean} [output]
         * @param {boolean} [pipeline]
         * @param {boolean} [processor]
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPipelineLog(pipelineId: string, input?: boolean, output?: boolean, pipeline?: boolean, processor?: boolean, logRequest?: LogRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineLog(pipelineId, input, output, pipeline, processor, logRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogsApi.getPipelineLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogsApiFp(configuration)
    return {
        /**
         * Get log of data collector instance
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralLog(logRequest?: LogRequest, options?: any): AxiosPromise<Array<LogMessage>> {
            return localVarFp.getGeneralLog(logRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get log of pipeline. Any provided \"loggerNameRegex\" is ignored
         * @param {string} pipelineId
         * @param {boolean} [input]
         * @param {boolean} [output]
         * @param {boolean} [pipeline]
         * @param {boolean} [processor]
         * @param {LogRequest} [logRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipelineLog(pipelineId: string, input?: boolean, output?: boolean, pipeline?: boolean, processor?: boolean, logRequest?: LogRequest, options?: any): AxiosPromise<Array<LogMessage>> {
            return localVarFp.getPipelineLog(pipelineId, input, output, pipeline, processor, logRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
    /**
     * Get log of data collector instance
     * @param {LogRequest} [logRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public getGeneralLog(logRequest?: LogRequest, options?: RawAxiosRequestConfig) {
        return LogsApiFp(this.configuration).getGeneralLog(logRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get log of pipeline. Any provided \"loggerNameRegex\" is ignored
     * @param {string} pipelineId
     * @param {boolean} [input]
     * @param {boolean} [output]
     * @param {boolean} [pipeline]
     * @param {boolean} [processor]
     * @param {LogRequest} [logRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public getPipelineLog(pipelineId: string, input?: boolean, output?: boolean, pipeline?: boolean, processor?: boolean, logRequest?: LogRequest, options?: RawAxiosRequestConfig) {
        return LogsApiFp(this.configuration).getPipelineLog(pipelineId, input, output, pipeline, processor, logRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ManagementApi - axios parameter creator
 * @export
 */
export const ManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} schema
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmptyConfig: async (schema: string, schemaType: SchemaType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('createEmptyConfig', 'schema', schema)
            // verify required parameter 'schemaType' is not null or undefined
            assertParamExists('createEmptyConfig', 'schemaType', schemaType)
            const localVarPath = `/management/emptyConfig/{schemaType}/{schema}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"schemaType"}}`, encodeURIComponent(String(schemaType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePipeline: async (pipelineId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pipelineId' is not null or undefined
            assertParamExists('deletePipeline', 'pipelineId', pipelineId)
            const localVarPath = `/management/{pipelineId}`
                .replace(`{${"pipelineId"}}`, encodeURIComponent(String(pipelineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doesPipelineExist: async (pipelineId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pipelineId' is not null or undefined
            assertParamExists('doesPipelineExist', 'pipelineId', pipelineId)
            const localVarPath = `/management/exists/{pipelineId}`
                .replace(`{${"pipelineId"}}`, encodeURIComponent(String(pipelineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchemas: async (schemaType: SchemaType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schemaType' is not null or undefined
            assertParamExists('getAllSchemas', 'schemaType', schemaType)
            const localVarPath = `/management/allSchemas/{schemaType}`
                .replace(`{${"schemaType"}}`, encodeURIComponent(String(schemaType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipelineConfig: async (pipelineId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pipelineId' is not null or undefined
            assertParamExists('getPipelineConfig', 'pipelineId', pipelineId)
            const localVarPath = `/management/config/{pipelineId}`
                .replace(`{${"pipelineId"}}`, encodeURIComponent(String(pipelineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipelines: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/pipelines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} schema
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema: async (schema: string, schemaType: SchemaType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('getSchema', 'schema', schema)
            // verify required parameter 'schemaType' is not null or undefined
            assertParamExists('getSchema', 'schemaType', schemaType)
            const localVarPath = `/management/schema/{schemaType}/{schema}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"schemaType"}}`, encodeURIComponent(String(schemaType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedMeterTypes: async (schemaType: SchemaType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schemaType' is not null or undefined
            assertParamExists('getSupportedMeterTypes', 'schemaType', schemaType)
            const localVarPath = `/management/supportedMeterTypes/{schemaType}`
                .replace(`{${"schemaType"}}`, encodeURIComponent(String(schemaType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseConfig: async (body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/parseConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/reloadAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadPipeline: async (pipelineId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pipelineId' is not null or undefined
            assertParamExists('reloadPipeline', 'pipelineId', pipelineId)
            const localVarPath = `/management/reload/{pipelineId}`
                .replace(`{${"pipelineId"}}`, encodeURIComponent(String(pipelineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} pipelineId
         * @param {boolean} [requireExist]
         * @param {boolean} [requireNotExist]
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPipelineConfig: async (pipelineId: string, requireExist?: boolean, requireNotExist?: boolean, body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pipelineId' is not null or undefined
            assertParamExists('setPipelineConfig', 'pipelineId', pipelineId)
            const localVarPath = `/management/{pipelineId}`
                .replace(`{${"pipelineId"}}`, encodeURIComponent(String(pipelineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (requireExist !== undefined) {
                localVarQueryParameter['requireExist'] = requireExist;
            }

            if (requireNotExist !== undefined) {
                localVarQueryParameter['requireNotExist'] = requireNotExist;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shutdownDatapusher: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/management/shutdownDatapusher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagementApi - functional programming interface
 * @export
 */
export const ManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagementApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} schema
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEmptyConfig(schema: string, schemaType: SchemaType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEmptyConfig(schema, schemaType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.createEmptyConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePipeline(pipelineId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePipeline(pipelineId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.deletePipeline']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doesPipelineExist(pipelineId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doesPipelineExist(pipelineId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.doesPipelineExist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSchemas(schemaType: SchemaType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSchemas(schemaType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.getAllSchemas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPipelineConfig(pipelineId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineConfig(pipelineId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.getPipelineConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPipelines(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BasicPipelineInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelines(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.getPipelines']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} schema
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchema(schema: string, schemaType: SchemaType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchema(schema, schemaType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.getSchema']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportedMeterTypes(schemaType: SchemaType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProtocolDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportedMeterTypes(schemaType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.getSupportedMeterTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parseConfig(body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicPipelineInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parseConfig(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.parseConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reloadAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reloadAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.reloadAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reloadPipeline(pipelineId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reloadPipeline(pipelineId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.reloadPipeline']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {string} pipelineId
         * @param {boolean} [requireExist]
         * @param {boolean} [requireNotExist]
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPipelineConfig(pipelineId: string, requireExist?: boolean, requireNotExist?: boolean, body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPipelineConfig(pipelineId, requireExist, requireNotExist, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.setPipelineConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shutdownDatapusher(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shutdownDatapusher(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagementApi.shutdownDatapusher']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ManagementApi - factory interface
 * @export
 */
export const ManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagementApiFp(configuration)
    return {
        /**
         *
         * @param {string} schema
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmptyConfig(schema: string, schemaType: SchemaType, options?: any): AxiosPromise<any> {
            return localVarFp.createEmptyConfig(schema, schemaType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePipeline(pipelineId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePipeline(pipelineId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doesPipelineExist(pipelineId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.doesPipelineExist(pipelineId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchemas(schemaType: SchemaType, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.getAllSchemas(schemaType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipelineConfig(pipelineId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getPipelineConfig(pipelineId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipelines(options?: any): AxiosPromise<Array<BasicPipelineInfo>> {
            return localVarFp.getPipelines(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} schema
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema(schema: string, schemaType: SchemaType, options?: any): AxiosPromise<any> {
            return localVarFp.getSchema(schema, schemaType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SchemaType} schemaType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedMeterTypes(schemaType: SchemaType, options?: any): AxiosPromise<Array<ProtocolDescription>> {
            return localVarFp.getSupportedMeterTypes(schemaType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parseConfig(body?: string, options?: any): AxiosPromise<BasicPipelineInfo> {
            return localVarFp.parseConfig(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadAll(options?: any): AxiosPromise<void> {
            return localVarFp.reloadAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} pipelineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reloadPipeline(pipelineId: string, options?: any): AxiosPromise<void> {
            return localVarFp.reloadPipeline(pipelineId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} pipelineId
         * @param {boolean} [requireExist]
         * @param {boolean} [requireNotExist]
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPipelineConfig(pipelineId: string, requireExist?: boolean, requireNotExist?: boolean, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.setPipelineConfig(pipelineId, requireExist, requireNotExist, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shutdownDatapusher(options?: any): AxiosPromise<void> {
            return localVarFp.shutdownDatapusher(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagementApi - object-oriented interface
 * @export
 * @class ManagementApi
 * @extends {BaseAPI}
 */
export class ManagementApi extends BaseAPI {
    /**
     *
     * @param {string} schema
     * @param {SchemaType} schemaType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public createEmptyConfig(schema: string, schemaType: SchemaType, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).createEmptyConfig(schema, schemaType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} pipelineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public deletePipeline(pipelineId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).deletePipeline(pipelineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} pipelineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public doesPipelineExist(pipelineId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).doesPipelineExist(pipelineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SchemaType} schemaType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getAllSchemas(schemaType: SchemaType, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getAllSchemas(schemaType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} pipelineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getPipelineConfig(pipelineId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getPipelineConfig(pipelineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getPipelines(options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getPipelines(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} schema
     * @param {SchemaType} schemaType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getSchema(schema: string, schemaType: SchemaType, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getSchema(schema, schemaType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SchemaType} schemaType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public getSupportedMeterTypes(schemaType: SchemaType, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).getSupportedMeterTypes(schemaType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public parseConfig(body?: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).parseConfig(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public reloadAll(options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).reloadAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} pipelineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public reloadPipeline(pipelineId: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).reloadPipeline(pipelineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} pipelineId
     * @param {boolean} [requireExist]
     * @param {boolean} [requireNotExist]
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public setPipelineConfig(pipelineId: string, requireExist?: boolean, requireNotExist?: boolean, body?: string, options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).setPipelineConfig(pipelineId, requireExist, requireNotExist, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementApi
     */
    public shutdownDatapusher(options?: RawAxiosRequestConfig) {
        return ManagementApiFp(this.configuration).shutdownDatapusher(options).then((request) => request(this.axios, this.basePath));
    }
}



