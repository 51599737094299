import React from 'react';
import {ConfirmPopup} from "primereact/confirmpopup";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

type RemoveConfirmationProps = {
    outputId: string,
    confirm(): void
}

const RemoveConfirmation = ({outputId, confirm}: RemoveConfirmationProps) => {
    const [value, setValue] = React.useState<string>("")

    return <div>
        <ConfirmPopup/>

        <div className={"text-alignment"}>
            To remove this reader write pipeline output id: [<span
            className={"writer-name"}><b>{outputId}</b></span>]
        </div>

        <InputText style={{alignSelf: "center"}}
                   value={value}
                   onChange={(e) => setValue(e.target.value)}/>

        <Button id="confirm-button"
                style={{transform: "scale(90%)"}}
                label="Remove"
                icon="pi pi-times"
                onClick={confirm}
                disabled={value !== outputId}/>
    </div>
};

export default RemoveConfirmation;