import React from 'react';
import OutputsDropdown from "./OutputsDropdown/OutputsDropdown";
import AddPipeOutput from "./AddOutput/AddPipeOutput";
import DeleteOutput from "./DeleteOutput/DeleteOutput";

type MainComponentsWrapperProps = {
    editorConfig: any,
    editorFullConfig: any,
    setEditorFullConfig: (conf: any) => void,
    selectOutput: (confId?: string) => void,
    configChanged: () => void
}

const MainComponentsWrapper = ({
                                   editorConfig,
                                   editorFullConfig,
                                   setEditorFullConfig,
                                   selectOutput,
                                   configChanged
                               }: MainComponentsWrapperProps) => {
    const options = editorFullConfig.outputConfig?.map((ob: any) => {
        return {label: ob.id, value: ob.id}
    })

    return <div className={"flex gap-1 justify-content-center align-items-center pr-1"}>

        <AddPipeOutput allOutputs={editorFullConfig?.outputConfig}
                       addNewOutput={(conf) => {
                           setEditorFullConfig({
                               ...editorFullConfig,
                               outputConfig: [...editorFullConfig.outputConfig, conf]
                           });
                           configChanged();
                       }}
        />

        <OutputsDropdown options={options}
                         selectOutput={selectOutput}
                         selected={editorConfig.id}
                         setSelected={selectOutput}
        />

        <DeleteOutput outputId={editorConfig.id}
                      confirm={() => {
                          const newOutputConfig = editorFullConfig.outputConfig.filter((ob: any) => ob.id !== editorConfig.id)
                          setEditorFullConfig({...editorFullConfig, outputConfig: newOutputConfig})
                          configChanged();
                      }}
                      disabled={options?.length === 1}
        />
    </div>
};

export default MainComponentsWrapper;