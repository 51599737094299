import React, {useState, useCallback, useId} from 'react';
import './ReaderToggleButton.scss';
import { ConfirmDialog } from 'primereact/confirmdialog';
import NotificationPopUp from 'util/NotificationPopUp';
import { Reader } from 'reader/Reader';
import { InputSwitch } from 'primereact/inputswitch';
import {Tooltip} from "react-tooltip";

type ReaderProp = {
    reader: Reader;
    onReaderToggled(readerId?: string): void;
};

const ReaderToggleButton: React.FC<ReaderProp> = ({ reader, onReaderToggled }) => {
    const id = useId()
    const [visible, setVisible] = useState(false);

    const confirmationHandler = () => {
        setVisible(true);
    };

    const handleChange = useCallback(() => {
        reader
            .reloadConfig()
            .then(() => {
                const conf = JSON.parse(reader.config);
                conf.enabled = !conf.enabled;
                reader.config = JSON.stringify(conf, null, 4);

                const notificationMessage = !reader.readerInfo.enabled
                    ? 'Starting data reader'
                    : 'Stopping data reader';
                NotificationPopUp.show(notificationMessage, 'success');
            })
            .then(() => reader.saveConfig())
            .then(() => reader.restart())
            .then(() => {
                const conf = JSON.parse(reader.config);
                reader.readerInfo.enabled = conf.enabled;
            })
            .catch((er) => {
                console.log(er);
            })
            .finally(() => {
                onReaderToggled(reader.readerInfo.id);
            });
    }, [reader, onReaderToggled]);

    const getTooltipMessage = reader.readerInfo.enabled ? 'Turn reader off' : 'Turn reader on';

    const getConfirmationMessage = useCallback(() => {
        return reader.readerInfo.enabled ? 'Do you want to turn off the reader?' : 'Do you want to turn on the reader?';
    }, [reader.readerInfo.enabled]);

    return (
        <div className="toggle-button-custom">
            <Tooltip id={id} place={"left"} style={{fontSize:24}}/>
            <ConfirmDialog
                visible={visible}
                header="Confirmation"
                onHide={() => setVisible(false)}
                message={getConfirmationMessage()}
                icon="pi pi-exclamation-triangle"
                accept={() => {
                    handleChange();
                }}
            />

            <InputSwitch
                checked={reader.readerInfo.enabled}
                onChange={confirmationHandler}
                data-tooltip-id={id}
                data-tooltip-content={getTooltipMessage}
                className="reader-switch"
            />
        </div>
    );
};

export default React.memo(ReaderToggleButton);
