import React, {useEffect} from 'react'
import {useVirtualizer} from '@tanstack/react-virtual'

type VirtualScrollerProps = {
    lines: JSX.Element[],
    count: number,
    linesSize: number[],
    autoScroll: boolean
}

const VirtualScroller = ({lines, count, linesSize, autoScroll}: VirtualScrollerProps) => {
    const parentRef = React.useRef<HTMLDivElement>(null)

    const virtualizer = useVirtualizer({
        count,
        getScrollElement: () => parentRef.current,
        estimateSize: (index) => linesSize[index],
        overscan: 5
    })

    useEffect(() => {
        if (autoScroll) {
                virtualizer.scrollToIndex(count - 1)
        }
    }, [lines, autoScroll]);

    const items = virtualizer.getVirtualItems()

    let [paddingTop, paddingBottom] = items.length > 0
        ? [
            Math.max(0, items[0].start - virtualizer.options.scrollMargin),
            Math.max(0, virtualizer.getTotalSize() - items[items.length - 1].end),
        ]
        : [0, 0]


    return <div ref={parentRef} style={{height: "100%", width: "100%", overflowY: 'auto', contain: 'strict'}}>
        <div style={{height: virtualizer.getTotalSize()}}>
            <div style={{paddingTop, paddingBottom}}>
                {items.map((item) => (
                    <div key={item.key} data-index={item.index} ref={virtualizer.measureElement}>
                        <div>{lines[item.index]}</div>
                    </div>
                ))}
            </div>
        </div>
    </div>
};

export default VirtualScroller;