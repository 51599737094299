import React, {Component} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import './ImportFileReaderStatusWindow.scss';
import NotificationPopUp from "../../../util/NotificationPopUp";
import {Tooltip} from "primereact/tooltip";
import {apisType} from "../../../serviceapi/ApiImports";
import {apisStore} from "../../../context/GlobalStates";

type Props = {
    collectorExists?: Array<boolean>,
    collectorArray?: Array<any>,
    writerExists?: Array<boolean>,
    writerArray?: Array<any>
}

class State {
    fullCollectorArray: Array<any> = [];
    loadingCollector: boolean = true;
    fullWriterArray: Array<any> = [];
    loadingWriter: boolean = true;
    refreshNeeded: boolean = false;
}

class ImportFileReaderStatusWindow extends Component<Props, State> {
    private uploaded: boolean = false;
    private overrided: boolean = false;
    private dataCollectorApi:apisType;
    private dataWriterApi:apisType;
    private fontSize = 10;
    private textLength = 0;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = new State();
        this.dataCollectorApi = apisStore.getState().dataCollector;
        this.dataWriterApi = apisStore.getState().dataPusher
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (document.getElementsByClassName("dataCollectorName")[0]) {
            this.textLength = document.getElementsByClassName("dataCollectorName")[0].clientWidth;
        }
        if (prevProps !== this.props) {
            this.combineArrays("dataCollector", this.props.collectorArray, this.props.collectorExists);
            this.combineArrays("dataWriter", this.props.writerArray, this.props.writerExists);
            this.uploaded = false;
        }
    }

    componentWillUnmount() {
        if(this.state.refreshNeeded) {
            window.location.reload();
        }
    }

    private combineArrays = (picked: string, arrArr?: Array<any>, existArr?: Array<any>) => {
        let nr = arrArr ? arrArr.length : 0;
        let arr: Array<any> = arrArr ? arrArr : [];
        let exists: Array<any> = existArr ? existArr : [];
        let newArr: Array<any> = [];


        for (let i = 0; i < nr; i++) {
            let newObj = {
                data: arr[i],
                exists: exists[i],
                status: ""
            }
            newArr.push(newObj);
        }
        if (picked === "dataCollector") {
            this.setState({fullCollectorArray: newArr, loadingCollector: false})
        } else if (picked === "dataWriter") {
            this.setState({fullWriterArray: newArr, loadingWriter: false})
        }

    }

    private doesIdAlreadyExists = (rowData: any) => {
        if (rowData.exists) {
            return <i className={"pi pi-check green-round-ball"}/>
        } else {
            return <i className={"pi pi-times green-round-ball"}/>
        }

    }

    private tableIdBody = (rowData: any) => {
        let tooltip = "";
        if (rowData.data.id.length > this.textLength / this.fontSize) {
            tooltip = rowData.data.id;
        }

        return <>
            <Tooltip target=".tooltip-id" position={"left"}/>
            <div id={"tableIdId"} className="tooltip-id" data-pr-tooltip={tooltip}>
                {rowData.data.id}
            </div>
        </>
    }

    private readerName = (rowData: any) => {
        if (rowData.data.readerName) {
            let tooltip = "";
            if (rowData.data.readerName.length > (this.textLength / this.fontSize)) {
                tooltip = rowData.data.readerName;
            }

            return <>
                <Tooltip target=".tooltip-readerName" position={"left"}/>
                <div id={"readerNameId"} className="tooltip-readerName" data-pr-tooltip={tooltip}>
                    {rowData.data.readerName}
                </div>
            </>
        } else {
            return "-";
        }
    }

    private writerName = (rowData: any) => {
        if (rowData.data.name) {
            let tooltip = "";
            if (rowData.data.name.length > this.textLength / this.fontSize + 10) {
                tooltip = rowData.data.name;
            }
            return <>
                <Tooltip target=".tooltip-name"/>
                <span id={"writerNameId"} className="tooltip-name" data-pr-tooltip={tooltip}>
                   {rowData.data.name}
                </span>
            </>
        } else {
            return "-";
        }
    }

    private dataTableArrayUploadStatus = (rowData: any) => {
        if (rowData.status === "failed") {
            return <i className={"pi pi-times-circle"}/>
        } else if (rowData.status === "success") {
            return <i className={"pi pi-check-circle"}/>
        } else if (rowData.status === "skipped") {
            return "skipped"
        } else {
            return ""
        }
    }

    private finishReader = (goodArr: Array<any>) => {
        this.setState({fullCollectorArray: goodArr})
    }

    private finishWriter = (goodArr: Array<any>) => {
        this.setState({fullCollectorArray: goodArr})
    }

    private safeImport = () => {
        this.setState({refreshNeeded: true})
        if (this.uploaded) {
            return
        }
        this.setState({loadingCollector: true})

        let importR = this.importReaders();
        let importW = this.importWriters();

        if (!importR && !importW) {
            NotificationPopUp.show("List is empty")
        } else {
            NotificationPopUp.show("File imported successfully", "success")
        }
        this.setState({loadingWriter: false,loadingCollector:false})
        this.uploaded = true;
    }

    private importReaders = () => {
        let goodArr: Array<any> = [];

        let importArray = this.state.fullCollectorArray.filter(obj => {
            if (!obj.exists) {
                return obj.data
            }
            return undefined
        })

        if (importArray.length === 0) {
            this.setState({loadingCollector: false})
            return false;
        }

        for (let i = 0; i < this.state.fullCollectorArray.length; i++) {
            const ob = this.state.fullCollectorArray[i];
            if (!ob.exists) {
                this.safelyUploadReader(ob).then(res => {
                    goodArr.push(res);
                    if (goodArr.length === this.state.fullCollectorArray.length) {
                        this.finishReader(goodArr);
                    }
                })
            } else {
                ob.status = "skipped"
                goodArr.push(ob)
                if (goodArr.length === this.state.fullCollectorArray.length) {
                    this.finishReader(goodArr);
                }
            }
        }
        return true;
    }

    private importWriters = () => {
        let goodArr: Array<any> = [];

        let importArray = this.state.fullWriterArray.filter(obj => {
            if (!obj.exists) {
                return obj.data
            }
            return undefined
        })

        if (importArray.length === 0) {
            this.setState({loadingWriter: false})
            return false;
        }

        for (let i = 0; i < this.state.fullWriterArray.length; i++) {
            const ob = this.state.fullWriterArray[i];
            if (!ob.exists) {
                this.safelyUploadWriter(ob).then(res => {
                    goodArr.push(res);
                    if (goodArr.length === this.state.fullWriterArray.length) {
                        this.finishWriter(goodArr);
                    }
                })
            } else {
                ob.status = "skipped"
                goodArr.push(ob)
                if (goodArr.length === this.state.fullWriterArray.length) {
                    this.finishWriter(goodArr);
                }
            }
        }
        return true;
    }

    private overrideImport = () => {
        this.setState({refreshNeeded: true})
        if (this.overrided) {
            return
        }

        let overrideR = this.overrideReaders();
        let overrideW = this.overrideWriters();

        if(!overrideR && !overrideW) {
            NotificationPopUp.show("List is empty")
            return
        }

        this.uploaded = true;
        this.overrided = true;
        this.setState({loadingCollector: false,loadingWriter:false})
        NotificationPopUp.show("File imported successfully", "success")
    }

    private overrideReaders = () => {
        let goodArr: Array<any> = [];

        if (this.state.fullCollectorArray.length === 0) {
            this.setState({loadingCollector: false});
            return false;
        }

        for (let i = 0; i < this.state.fullCollectorArray.length; i++) {
            const ob = this.state.fullCollectorArray[i];
            this.overrideUploadReader(ob).then(res => {
                goodArr.push(res);
                if (goodArr.length === this.state.fullCollectorArray.length) {
                    this.finishReader(goodArr);
                }
            })
        }
        return true;
    }

    private overrideWriters = () => {
        let goodArr: Array<any> = [];

        if (this.state.fullWriterArray.length === 0) {
            this.setState({loadingWriter: false});
            return false;
        }

        for (let i = 0; i < this.state.fullWriterArray.length; i++) {
            const ob = this.state.fullWriterArray[i];
            this.overrideUploadWriter(ob).then(res => {
                goodArr.push(res);
                if (goodArr.length === this.state.fullWriterArray.length) {
                    this.finishReader(goodArr);
                }
            })
        }
        return true;
    }

    private safelyUploadReader = async (ob: any): Promise<any> => {
        await this.dataCollectorApi.managementApi?.setReaderConfig(ob.data.id, false, true, JSON.stringify(ob.data))
            .then(() => ob.status = "success")
            .catch(() => ob.status = "failed")
        return ob;
    }

    private safelyUploadWriter = async (ob: any): Promise<any> => {
        await this.dataWriterApi.managementApiConst?.setPipelineConfig(ob.data.id, false, true, JSON.stringify(ob.data))
            .then(() => ob.status = "success")
            .catch(() => ob.status = "failed")
        return ob;
    }

    private overrideUploadReader = async (ob: any): Promise<any> => {
        await this.dataCollectorApi.managementApi?.setReaderConfig(ob.data.id, false, false, JSON.stringify(ob.data))
            .then(() => ob.status = "success")
            .catch(() => ob.status = "failed")
        return ob;
    }

    private overrideUploadWriter = async (ob: any): Promise<any> => {
        await this.dataWriterApi.managementApiConst?.setPipelineConfig(ob.data.id, false, false, JSON.stringify(ob.data))
            .then(() => ob.status = "success")
            .catch(() => ob.status = "failed")
        return ob;
    }

    render() {
        if (this.state.fullCollectorArray.length > 0 || this.state.fullWriterArray.length > 0) {
            return (
                <div className={"full-component"} id={"component-full-window"}>
                    <div className={"status-data-table"}>
                        {this.state.fullCollectorArray.length > 0 &&
                            <div className={"table-class"}>
                                <div className={"text-place"}><b>Data Collector</b></div>
                                <DataTable value={this.state.fullCollectorArray}
                                           loading={this.state.loadingCollector}
                                           showGridlines
                                >
                                    <Column field={"id"} header={"Id"} body={this.tableIdBody}
                                            style={{
                                                maxWidth: "0",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "clip"
                                            }}
                                    />
                                    <Column className={"dataCollectorName"} field={"name"} header={"Name"}
                                            body={this.readerName}
                                            style={{
                                                maxWidth: "0",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "clip"
                                            }}
                                    />
                                    <Column field={"exists"} header={"Already exists"}
                                            style={{width: 0, textAlign: "center"}}
                                            body={this.doesIdAlreadyExists}
                                    />
                                    <Column field={"status"} header={"Upload status"}
                                            style={{width: 0, textAlign: "center"}}
                                            body={this.dataTableArrayUploadStatus}
                                    />
                                </DataTable>
                            </div>
                        }
                        {this.state.fullWriterArray.length > 0 &&
                            <div className={"table-class"}>
                                <div className={"text-place"}><b>Data Writer</b></div>
                                <DataTable value={this.state.fullWriterArray}
                                           loading={this.state.loadingWriter} showGridlines>
                                    <Column field={"id"} header={"Id"} body={this.tableIdBody}
                                            style={{
                                                maxWidth: "0",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "clip"
                                            }}
                                    />
                                    <Column field={"name"} header={"Name"} body={this.writerName}
                                            style={{
                                                maxWidth: "0",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "clip"
                                            }}
                                    />
                                    <Column field={"exists"} header={"Already exists"}
                                            style={{width: 0, textAlign: "center"}}
                                            body={this.doesIdAlreadyExists}/>
                                    <Column field={"status"} header={"Upload status"}
                                            style={{width: 0, textAlign: "center"}}
                                            body={this.dataTableArrayUploadStatus}/>
                                </DataTable>
                            </div>
                        }
                    </div>
                    <div className={"button-container"}>
                        <Button label={"Safe Import"}
                                onClick={this.safeImport}
                                disabled={this.uploaded}/>
                        <Button label={"Override Import"}
                                onClick={this.overrideImport}
                                disabled={this.overrided}/>
                    </div>
                </div>
            );
        } else {
            return
        }

    }
}

export default ImportFileReaderStatusWindow;