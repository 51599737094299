import React, {Component} from "react";
import {Reader} from "./Reader";
import {Dialog} from "primereact/dialog";
import {ProtocolDescription} from "serviceapi";
import {Button} from "primereact/button";
import {Dropdown, DropdownChangeParams} from "primereact/dropdown";
import {SelectItem} from "primereact/selectitem";
import {ConfirmPopup, confirmPopup, ConfirmPopupProps} from 'primereact/confirmpopup';
import NotificationPopUp from "util/NotificationPopUp";
import {InputText} from "primereact/inputtext";
import Utils from "util/Utils";
import {apisType} from "serviceapi/ApiImports";
import {ReaderViewer} from "./ReaderViewer";
import {apisStore} from "context/GlobalStates";
import {CLIENT_TIMEOUT, DEFAULT_OPTIONS} from "../service/HttpService";

type ReaderCreatorProps = {
    onComplete(reader: Reader): void
}

class ReaderCreatorState {
    open: boolean = false;
    showSelectTypeDialog: boolean = false;
    showSetupReaderDialog: boolean = false;

    availableReaderTypes: SelectItem[] = []
    selectedReaderType?: ProtocolDescription = undefined;

    reader?: Reader = undefined;
    readerId: string = "";
    readerName: string = "";
    readerProtocol: string = "";
    schema?: any = undefined;
}

class ReaderCreator extends React.Component<ReaderCreatorProps, ReaderCreatorState> {

    private runId?: string = undefined;
    private editorError: boolean = false;
    private oldConfig: any = undefined;
    private apis: apisType;

    constructor(props: Readonly<ReaderCreatorProps> | ReaderCreatorProps) {
        super(props);
        this.state = new ReaderCreatorState();
        this.apis = apisStore.getState().dataCollector
    }

    start = () => {
        this.apis.managementApi?.getSupportedMeterTypes()
            .then(r => {
                let readerTypes = r.data.filter(r => (r.deprecated === false) || !r.deprecated).map(v => {
                    return {
                        label: v.name,
                        value: v,
                        title: v.name,
                    };

                });

                readerTypes = readerTypes.sort((a: any, b: any) => {
                    if (a.title.toLowerCase() > b.title.toLowerCase()) {
                        return 1
                    } else if (a.title.toLowerCase() < b.title.toLowerCase()) {
                        return -1
                    }
                    return 0;
                })

                this.setState({
                    open: true,
                    availableReaderTypes: readerTypes,
                    selectedReaderType: undefined,
                    showSelectTypeDialog: true,
                    readerId: "",
                    readerName: "",
                    readerProtocol: ""
                })
            });
    }

    private cancel = () => {
        this.setState({
            open: false,
            showSelectTypeDialog: false,
            showSetupReaderDialog: false
        })
    }

    private onTypeChange = (e: DropdownChangeParams) => {
        this.setState({selectedReaderType: e.target.value});
    }

    private enterSelectTypeStage = () => {
        this.setState({
            showSelectTypeDialog: true,
            showSetupReaderDialog: false
        });
    }

    private getSchema = async () => {
        await this.apis.managementApi?.getSchema(this.state.selectedReaderType?.implementationKey as string)
            .then(res => {
                this.setState({schema: res.data})
            })
            .catch(er => console.log(er))
    }

    private checkInputs = () => {
        if (!this.state.selectedReaderType) {
            throw Error("Protocol is not selected")
        } else if (!this.state.readerId) {
            throw Error("Reader Id must be provided")
        } else if (!this.state.readerName) {
            throw Error("Reader name must be provided")
        }
    }

    private enterEditReaderState = () => {
        try {
            this.checkInputs()
        } catch (er: any) {
            NotificationPopUp.show(er.message, 'error')
            return
        }
        if (!this.state.selectedReaderType) {
            return
        }

        this.apis.managementApi?.createEmptyConfig(this.state.selectedReaderType.implementationKey as string, DEFAULT_OPTIONS)
            .then(v => {
                return this.apis.managementApi?.doesReaderExist(this.state.readerId, DEFAULT_OPTIONS)
                    .then(response => {
                        if (response.data) {
                            throw new Error("Reader id [" + this.state.readerId + "] already exists");
                        }
                    })
                    .then(() => v.data)
            })
            .then((v: any) => {
                v.id = this.state.readerId;
                v.readerName = this.state.readerName;
                this.oldConfig = v;
                return this.apis.managementApi?.parseConfig(JSON.stringify(v), DEFAULT_OPTIONS)
                    .then(parseResponse => {
                        return new Reader(parseResponse.data, this.apis.managementApi!!, JSON.stringify(v, null, 4));
                    });

            })
            .then(reader => {
                this.getSchema()
                    .then(() => {
                        this.setState({reader})
                    })
                    .finally(() => {
                        this.setState({
                            showSelectTypeDialog: false,
                            showSetupReaderDialog: true
                        });
                    })
            })
            .catch(er => {
                NotificationPopUp.show(er.message, 'error')
            })


    }

    private checkEditorError = (errorBool: boolean) => {
        this.editorError = errorBool;
    }

    render() {
        const stopReaderIfExist = () => {
            if (this.runId) { // noinspection JSIgnoredPromiseFromCall
                this.apis.testingApi?.stopRun(this.runId)
            }
        }

        return (
            <>
                <Dialog visible={this.state.showSelectTypeDialog}
                        header={"Create new reader"}
                        onHide={() => this.setState({showSelectTypeDialog: false})}
                        footer={<WizardPanelButtons onNext={this.enterEditReaderState} onCancel={this.cancel}/>}
                >
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "3%", columnGap: "15px"}}>
                        <span className="p-float-label">
                            <Dropdown id="protocolName"
                                      value={this.state.selectedReaderType}
                                      options={this.state.availableReaderTypes}
                                      onChange={this.onTypeChange}
                                      filter
                                      filterPlaceholder={"Search"}
                            />
                            <label htmlFor="protocolName">Protocol</label>
                        </span>

                        <span className="p-float-label">
                            <InputText id="readerId" value={this.state.readerId}
                                       onChange={e => this.setState({readerId: e.target.value})}/>
                            <label htmlFor="readerId">Reader ID</label>
                        </span>

                        <span className="p-float-label">
                            <InputText id="readerName" value={this.state.readerName}
                                       onChange={e => this.setState({readerName: e.target.value})}/>
                            <label htmlFor="readerName">Reader name</label>
                        </span>
                    </div>


                </Dialog>
                <Dialog visible={this.state.showSetupReaderDialog}
                        onHide={() => this.setState({showSetupReaderDialog: false})}
                        header={"Create new reader"}
                        style={{minWidth: "600px", width: '50%', height: '80%'}}

                        footer={
                            <WizardPanelButtons
                                onBack={() => {
                                    this.enterSelectTypeStage();
                                    stopReaderIfExist();
                                }}
                                onCancel={this.cancel}
                                onFinish={() => {
                                    let reader = this.state.reader;
                                    let config: string = reader!.config

                                    try {
                                        Utils.editorHaveErrors(this.editorError)
                                    } catch (err: any) {
                                        NotificationPopUp.show(err.message, 'error')
                                        return
                                    }

                                    try {
                                        Utils.checkConfigValidation(JSON.parse(config), this.oldConfig);
                                    } catch (err: any) {
                                        NotificationPopUp.show(err.message, 'error')
                                        return
                                    }

                                    let readerId: string = JSON.parse(config)["id"];

                                    this.apis.managementApi?.setReaderConfig(readerId, undefined, true, config, DEFAULT_OPTIONS)
                                        .then(() => {
                                            this.cancel()
                                            this.props.onComplete(reader!)
                                            stopReaderIfExist();
                                        })
                                        .catch((err) => {
                                            NotificationPopUp.show(err.response.data.message, 'error', "Error")
                                        })
                                }}
                                confirmFinishProps={{
                                    message: "Create new reader?"
                                }}
                            />
                        }
                >
                    <ReaderViewer reader={this.state.reader}
                                  schema={this.state.schema}
                                  errorIsInEditor={this.checkEditorError}
                    />
                </Dialog>
            </>
        );
    }
}

type WizardPanelButtonsProps = {
    onBack?(): void
    onNext?(): void
    onCancel?(): void
    onFinish?(): void

    validateNext?(): Promise<boolean>
    validateFinish?(): Promise<boolean>

    confirmFinish?: boolean
    confirmFinishProps?: ConfirmPopupProps
}

class WizardPanelButtons extends Component<WizardPanelButtonsProps> {

    static defaultProps = {
        confirmFinish: false
    }

    private next = () => {
        if (this.props.validateNext) {
            this.props.validateNext()
                .then(this.props.onNext)
        } else if (this.props.onNext) {
            this.props.onNext()
        }
    }

    private finish = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.props.confirmFinish || this.props.confirmFinishProps) {
            let props = {
                ...this.props.confirmFinishProps
            }
            props.accept = this.doFinish
            props.reject = () => {
            }
            props.target = e.currentTarget
            if (!props.message) {
                props.message = "Confirm?"
            }

            confirmPopup(props);
        } else {
            this.doFinish();
        }

    }

    private doFinish = () => {
        if (this.props.validateFinish) {
            this.props.validateFinish()
                .then(this.props.onFinish)
        } else if (this.props.onFinish) {
            this.props.onFinish()
        }
    }

    render() {
        return (
            <React.Fragment>
                <Button label={"Back"} onClick={this.props.onBack} disabled={this.props.onBack === undefined}/>
                <Button label={"Next"} onClick={this.next} disabled={this.props.onNext === undefined}/>
                <Button label={"Cancel"} onClick={this.props.onCancel} disabled={this.props.onCancel === undefined}/>
                <Button label={"Finish"} onClick={e => this.finish(e)} disabled={this.props.onFinish === undefined}/>
                <ConfirmPopup/>
            </React.Fragment>
        )
    }
}

export {ReaderCreator}