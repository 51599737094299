import React, {useId} from 'react';
import PipelineRemove from "components/data-writer/PipelineRemove/PipelineRemove";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Tooltip} from 'react-tooltip';

type WriterRemoveButtonProps = {
    selectedPipelineId: string | undefined,
    selectedPipeline: any,

    unsetSelectedConfig: () => void
}

const WriterRemoveButton = ({selectedPipelineId, selectedPipeline, unsetSelectedConfig}: WriterRemoveButtonProps) => {
    const id = useId()
    const [visible, setVisible] = React.useState(false);

    return <>
        <Tooltip id={id} style={{zIndex:9999}} place={"right"}/>
        <Button
            className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
            icon={"pi pi-trash"}
            data-tooltip-id={id}
            data-tooltip-content="Remove"
            onClick={() => {
                selectedPipelineId = undefined;
                setVisible(true)
            }}
            disabled={!selectedPipeline || !selectedPipelineId}
        />
        {visible &&
            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                style={{minWidth: "16vw"}}
                header={"Writer Pipeline Remove"}
            >
                <PipelineRemove writerId={selectedPipelineId as string}
                                unsetSelectedConfig={unsetSelectedConfig}
                                closeDialog={() => setVisible(false)}/>

            </Dialog>
        }
    </>
};

export default WriterRemoveButton;