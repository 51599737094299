import React, { RefObject } from "react";
import { ListBox } from "primereact/listbox";
import { Tooltip } from "primereact/tooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import { BasicPipelineInfo } from "serviceapi/dataPusherApi";
import { WriterView } from "../../../writer/WriterView";
import NotificationPopUp from "util/NotificationPopUp";
import WriterToggleButton from "./WriterToggleButton/WriterToggleButton";
import PageDisablerOnLoading from "../../general/PageDisablerOnLoading/PageDisablerOnLoading";
import "./DataWriter.scss";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Apis, { apisType } from "serviceapi/ApiImports";
import { apisStore } from "context/GlobalStates";
import Encoder from "../Encoder/Encoder";
import Utils from "util/Utils";
import WriterActionsButtonWithList from "./Buttons/WriterActionsButtonWithList";
import { AxiosRequestConfig } from "axios";
import CreateNewPipelineButton from "./Buttons/CreateNewPipelineButton";
import WriterRemoveButton from "./Buttons/WriterRemoveButton";
import WriterHistoryButton from "./Buttons/WriterHistoryButton";
import WriterLogViewerButton from "./Buttons/WriterLogViewerButton";
import WriterTesterButton from "./Buttons/WriterTesterButton";
import WriterPipelineSaveButton from "./Buttons/WriterPipelineSaveButton";
import WriterPipelineRefreshButton from "./Buttons/WriterPipelineRefreshButton";
import MainComponentsWrapper from "components/data-writer/OutputCRUD/Components/MainComponentsWrapper";
import { CLIENT_TIMEOUT } from "../../../service/HttpService";
import { Button } from "primereact/button";

type DataWriterProps = {};

class DataWriterState {
  //ALL PIPES
  pipelines: Array<BasicPipelineInfo> = [];

  //SELECTED PIPE
  selectedPipeline: BasicPipelineInfo | undefined | null = undefined;

  //PIPE CONFIG
  editorFullConfig: any = undefined;
  editorConfig: any = undefined;
  editorSchema: any = undefined;
  readScript: boolean = false;

  //LOADER STATE
  pageLoadingText: string = "";
  pageLoading: boolean = false;
  writerLoading: boolean = true;
  listLoad: boolean = true;

  //TAB
  viewTabIndex: number = 3;
  prepareChangeIndex: number = 3;

  //DIALOGS

  writerAddDialog: boolean = false;
  writerTesterDialog: boolean = false;
  writerHistoryDialog: boolean = false;
  writerLogsDialog: boolean = false;
  writerRemoveDialog: boolean = false;
  writerAllLogsDialog: boolean = false;
  writerEncodeDialog: boolean = false;
  base64: boolean = false;

  //ERRORS
  errorMessage: string = "";
}

class DataWriter extends React.Component<DataWriterProps, DataWriterState> {
  private mounted = false;
  private apis: apisType = Apis("datapusher");
  private selectedPipelineId: any = undefined;

  private errorInEditor = false;
  private editorChanged = false;
  private viewTabChangeable = true;

  private readonly writerViewRef: RefObject<WriterView> =
    React.createRef<WriterView>();
  private readonly encoderRef: RefObject<Encoder> = React.createRef<Encoder>();

  constructor(props: Readonly<DataWriterProps> | DataWriterProps) {
    super(props);
    this.state = new DataWriterState();
  }

  componentDidMount() {
    if (!this.mounted) {
      if (
        Object.keys(apisStore.getState().dataCollector).length === 0 ||
        Object.keys(apisStore.getState().dataPusher).length === 0
      ) {
        apisStore.setState({ dataCollector: Apis("datacollector") });
        apisStore.setState({ dataPusher: this.apis });
      }
      this.mounted = true;
      this.refreshPipelines();
    }
  }

  private sortAlphabetically = (data: Array<BasicPipelineInfo>) => {
    return data.sort((a: BasicPipelineInfo, b: BasicPipelineInfo) => {
      if (a.name && b.name) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else {
          return 1;
        }
      } else if (a.name) {
        return 1;
      } else if (b.name) {
        return -1;
      } else if (a.id && b.id) {
        if (a.id.toLowerCase() < b.id.toLowerCase()) {
          return -1;
        } else {
          return 1;
        }
      }
      return 0;
    });
  };

  private refreshPipelines = () => {
    this.apis.managementApiConst
      .getPipelines({ timeout: CLIENT_TIMEOUT } as AxiosRequestConfig)
      .then((res: any) => {
        this.setState({ writerLoading: false });
        if (!res) {
          this.setState({ errorMessage: "Invalid URL" });
          return;
        } else if (res.status !== 200) {
          this.setState({ errorMessage: res.status });
          return;
        } else if (!res.data) {
          this.setState({ errorMessage: res.status });
          return;
        }

        if (res) {
          let sortedData = this.sortAlphabetically(res.data);
          this.setState({ pipelines: sortedData });

          if (sortedData.length === 1) {
            this.setState({ selectedPipeline: sortedData[0] });
            this.selectedPipelineId = sortedData[0].id;
          } else {
            if (this.selectedPipelineId) {
              let tempPipe = res.data.filter(
                (obj: any) => obj.id === this.selectedPipelineId
              )[0];
              this.setState({ selectedPipeline: tempPipe });
            } else {
              this.setState({
                editorSchema: undefined,
                editorConfig: undefined,
                editorFullConfig: undefined,
                selectedPipeline: null,
              });
            }
          }
        }
        if (this.state.listLoad) {
          this.setState({ listLoad: false });
        }
      })
      .catch((er: any) => {
        this.setState({ errorMessage: er.message });
      });
  };

  private restartPipelines = () => {
    this.setState({
      pageLoadingText: "Pipeline Restarting",
      pageLoading: true,
    });
    if (this.state.selectedPipeline) {
      this.apis.managementApiConst
        .reloadPipeline(this.state.selectedPipeline.id!!, {
          timeout: CLIENT_TIMEOUT,
        })
        .then(() => {
          NotificationPopUp.show("Pipeline restarted successfully", "success");
          this.setState({ pageLoading: false });
        })
        .catch((er: any) => console.error(er));
    }
  };

  componentDidUpdate(
    _: Readonly<DataWriterProps>,
    prevState: Readonly<DataWriterState>
  ) {
    if (prevState.selectedPipeline !== this.state.selectedPipeline) {
      if (this.state.viewTabIndex !== 3) {
        this.viewTabChangeable = true;
        this.setState({ viewTabIndex: 3, prepareChangeIndex: 3 });
      }

      if (this.state.selectedPipeline != null) {
        this.apis.managementApiConst
          .getPipelineConfig(this.state.selectedPipeline?.id!!, {
            timeout: CLIENT_TIMEOUT,
          })
          .then((res: any) => {
            let tempObj = JSON.parse(JSON.stringify(res.data));
            delete tempObj.inputConfig;
            delete tempObj.processorConfig;
            delete tempObj.outputConfig;

            this.setState({
              editorFullConfig: res.data,
              editorConfig: tempObj,
              editorSchema: undefined,
            });
          })
          .catch((res: any) => {
            console.error(res);
            NotificationPopUp.show("Error", "error");
          });
      } else {
        this.setState({
          editorFullConfig: undefined,
          editorConfig: undefined,
          editorSchema: undefined,
        });
      }

      if (this.errorInEditor || this.editorChanged) {
        this.editorChanged = false;
        this.errorInEditor = false;
      }
    } else if (
      prevState.selectedPipeline === this.state.selectedPipeline &&
      this.state.prepareChangeIndex !== prevState.prepareChangeIndex &&
      this.viewTabChangeable
    ) {
      if (this.editorChanged) {
        this.confirmTabChange(this.state.prepareChangeIndex);
      } else {
        this.tabChanger(this.state.prepareChangeIndex);
      }
      this.viewTabChangeable = false;
    }

    if (
      this.state.viewTabIndex === 2 &&
      prevState.selectedPipeline === this.state.selectedPipeline &&
      this.state.selectedPipeline &&
      prevState.editorFullConfig?.outputConfig?.length !==
        this.state.editorFullConfig?.outputConfig?.length &&
      prevState.editorFullConfig?.outputConfig?.length > 0 &&
      this.state.editorFullConfig?.outputConfig?.length > 0
    ) {
      const oldOutputs: Array<any> = prevState.editorFullConfig.outputConfig;
      const outputs: Array<any> = this.state.editorFullConfig.outputConfig;

      if (outputs.length > oldOutputs.length) {
        this.selectOutput(
          outputs.filter((obj: any) => !oldOutputs.includes(obj))[0].id
        );
      } else {
        this.selectOutput(outputs[0].id);
      }
    }
  }

  private tabChanger = (tabNumber: number) => {
    if (this.state.selectedPipeline != null) {
      if (tabNumber === 0) {
        this.apis.managementApiConst
          .getSchema(this.state.selectedPipeline.inputSchemaType!!, "INPUT", {
            timeout: CLIENT_TIMEOUT,
          })
          .then((res: any) => {
            if (this.state.editorFullConfig) {
              this.setState({
                editorSchema: res.data,
                editorConfig: this.state.editorFullConfig.inputConfig,
              });
            } else {
              this.setState({
                editorSchema: res.data,
              });
            }
          })
          .catch((res: any) => {
            console.error(res);
            NotificationPopUp.show("Error", "error");
          });
      } else if (tabNumber === 1) {
        this.setState({
          editorSchema: null,
          editorConfig: this.state.editorFullConfig.processorConfig,
        });
      } else if (tabNumber === 2) {
        this.selectOutput();
      } else if (tabNumber === 3) {
        let tempObj = JSON.parse(JSON.stringify(this.state.editorFullConfig));
        delete tempObj.inputConfig;
        delete tempObj.processorConfig;
        delete tempObj.outputConfig;

        // setTimeout added to fix state race bug.
        setTimeout(() => {
          this.setState({
            editorSchema: null,
            editorConfig: tempObj,
          });
        }, 0);
      }

      this.setState({ viewTabIndex: tabNumber, prepareChangeIndex: tabNumber });
    }

    this.refreshTabViewStyle(tabNumber);

    if (this.errorInEditor || this.editorChanged) {
      this.editorChanged = false;
      this.errorInEditor = false;
    }
  };

  private selectOutput = (confId?: string) => {
    const outputConfig = confId
      ? this.state.editorFullConfig?.outputConfig?.find(
          (conf: any) => conf.id === confId
        )
      : this.state.editorFullConfig?.outputConfig?.[0];
    const schemaType = outputConfig?.implementationKey;

    if (!schemaType || !outputConfig) {
      NotificationPopUp.show("Something went wrong...", "error");
      return;
    }

    this.apis.managementApiConst
      .getSchema(schemaType, "OUTPUT", { timeout: CLIENT_TIMEOUT })
      .then((res: any) => {
        this.setState({
          editorSchema: res.data,
          editorConfig: outputConfig,
        });
      })
      .catch((res: any) => {
        console.error(res);
        NotificationPopUp.show("Error", "error");
      });
  };

  private refreshTabViewStyle = (tabNumber: number) => {
    document.getElementById("f-button")!.className = document
      .getElementById("f-button")!
      .className.replace(" active", "");
    document.getElementById("s-button")!.className = document
      .getElementById("s-button")!
      .className.replace(" active", "");
    document.getElementById("t-button")!.className = document
      .getElementById("t-button")!
      .className.replace(" active", "");
    document.getElementById("g-button")!.className = document
      .getElementById("g-button")!
      .className.replace(" active", "");

    if (tabNumber === 0) {
      document.getElementById("f-button")!.className += " active";
    } else if (tabNumber === 1) {
      document.getElementById("s-button")!.className += " active";
    } else if (tabNumber === 2) {
      document.getElementById("t-button")!.className += " active";
    } else {
      document.getElementById("g-button")!.className += " active";
    }
  };

  private listBoxItemTemplate = (rowData: any) => {
    let printName: string;
    if (rowData.name) {
      if (rowData.name !== "") {
        printName = rowData.name;
      } else {
        printName = "[" + rowData.id + "]";
      }
    } else {
      printName = "[" + rowData.id + "]";
    }
    return (
      <span className={"flex justify-content-between align-items-center"}>
        <span className="long-name-tooltip" style={{ wordBreak: "break-word" }}>
          {printName}
        </span>

        <div>
          {
            <WriterToggleButton
              writer={rowData}
              managementApi={this.apis.managementApiConst}
              onWriterToggled={this.refreshPipelines}
            />
          }
        </div>
      </span>
    );
  };

  private editorErrorStateChanger = (state: any) => {
    if (this.errorInEditor !== state) {
      this.errorInEditor = state;
    }
  };

  private editorStateChanger = (state: boolean) => {
    if (state !== this.editorChanged) {
      this.editorChanged = state;
    }
  };

  private saveButtonHandler = () => {
    if (this.state.viewTabIndex === 1) {
      const code = this.encoderRef.current;
      if (code == null) {
        console.error("ERROR: Encoder ref is null");
        return;
      }
      this.setState(
        (prevState) => {
          console.log(prevState.editorFullConfig);
          const tempObj = { ...prevState.editorFullConfig };
          console.log(tempObj);
          tempObj.processorConfig.script = code.state.scriptCode
            ? Utils.base64Encode(code.state.scriptCode)
            : "";

          return { editorFullConfig: tempObj };
        },
        () => {
          this.apis.managementApiConst
            .setPipelineConfig(
              this.state.selectedPipeline?.id!,
              true,
              false,
              this.state.editorFullConfig,
              { timeout: CLIENT_TIMEOUT }
            )
            .then(() => {
              NotificationPopUp.show(
                "Configuration saved successfully",
                "success"
              );
              this.editorChanged = false;
            })
            .catch((result: any) => {
              console.error(result);
              NotificationPopUp.show("Error", "error");
              this.editorChanged = true;
            });
        }
      );

      return;
    }

    try {
      const config = JSON.parse(
        this.writerViewRef.current?.editor?.getValue() || "{}"
      );

      this.setState(
        (prevState) => {
          const tempObj = { ...prevState.editorFullConfig };

          if (this.state.viewTabIndex === 0) {
            tempObj.inputConfig = config;
          } else if (this.state.viewTabIndex === 2) {
            const objInd = tempObj.outputConfig.findIndex(
              (conf: any) => conf.id === this.state.editorConfig?.id
            );
            tempObj.outputConfig[objInd] = config;
          } else if (this.state.viewTabIndex === 3) {
            Object.assign(tempObj, config);
          }

          return { editorFullConfig: tempObj };
        },
        () => {
          this.apis.managementApiConst
            .setPipelineConfig(
              this.state.selectedPipeline?.id!,
              true,
              false,
              this.state.editorFullConfig,
              { timeout: CLIENT_TIMEOUT }
            )
            .then(() => {
              NotificationPopUp.show(
                "Configuration saved successfully",
                "success"
              );
              this.editorChanged = false;
            })
            .catch((result: any) => {
              console.error(result);
              NotificationPopUp.show("Error", "error");
              this.editorChanged = true;
            });
        }
      );
    } catch (error) {
      NotificationPopUp.show("JSON syntax error", "error");
      console.error("JSON parse error:", error);
    }
  };

  private spinnerHeaderHandler = (dialogName: string) => {
    const obj = this.state.selectedPipeline
      ? this.readerNameChecker()
      : { name: "", string: "" };
    obj.name = this.state.selectedPipeline
      ? `Writer ${obj.string} ${dialogName}`
      : dialogName;
    return this.spinnerHeader(obj);
  };

  private showLoadingIndicator = () => {
    if (document.getElementById("progress-spinner-indicator-id")) {
      document.getElementById("progress-spinner-indicator-id")!.style.display =
        "block";
    }
  };

  private hideLoadingIndicator = () => {
    if (document.getElementById("progress-spinner-indicator-id")) {
      document.getElementById("progress-spinner-indicator-id")!.style.display =
        "none";
    }
  };

  private spinnerHeader = (obj: any) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "30px",
          justifyContent: "space-between",
          height: "30px",
          marginRight: "10px",
        }}
      >
        {obj.tooltip && <Tooltip target=".long-name-tooltip" />}
        <div
          className={"long-name-tooltip"}
          data-pr-tooltip={obj.tooltipMessage}
        >
          {obj.name}
        </div>
        <div>
          <ProgressSpinner
            id={"progress-spinner-indicator-id"}
            style={{ width: "30px", height: "30px" }}
            strokeWidth="7"
            animationDuration=".8s"
          />
        </div>
      </div>
    );
  };

  private readerNameChecker = () => {
    let returnObject = Object({ tooltip: false });

    if (this.state.editorFullConfig?.name) {
      if (this.state.editorFullConfig.name.length > 30) {
        returnObject.string =
          this.state.editorFullConfig.name.slice(0, 30) + "...";
        returnObject.tooltipMessage = this.state.editorFullConfig.name;
        returnObject.tooltip = true;
      } else {
        returnObject.string = this.state.editorFullConfig?.name;
      }
    } else {
      if (this.state.editorFullConfig?.id) {
        if (this.state.editorFullConfig?.id.length > 30) {
          returnObject.string =
            this.state.editorFullConfig?.id.slice(0, 30) + "...";
          returnObject.tooltipMessage = this.state.editorFullConfig?.id;
          returnObject.tooltip = true;
        } else {
          returnObject.string = "[" + this.state.editorFullConfig?.id + "]";
        }
      }
    }

    return returnObject;
  };

  showAllLogs = () => {
    this.setState({ writerAllLogsDialog: true });
  };

  restartWriter = () => {
    this.setState({ pageLoading: true, pageLoadingText: "Writer restarting" });
    this.apis.managementApiConst
      .reloadAll({ timeout: CLIENT_TIMEOUT })
      .then(() => {
        NotificationPopUp.show("Writer restarted successfully", "success");
        this.setState({ pageLoading: false });
      })
      .catch((er: any) => {
        NotificationPopUp.show("Writer failed to restart", "error");
        console.error(er.message);
        this.setState({ pageLoading: false });
      });
  };

  shutdownWriter = () => {
    this.setState({
      pageLoading: true,
      pageLoadingText: "Writer shutting down",
    });
    this.apis.managementApiConst
      .shutdownDatapusher({ timeout: CLIENT_TIMEOUT * 5 })
      .then(() => {
        NotificationPopUp.show("Writer shutdown successfully", "success");
        this.setState({ pageLoading: false });
      })
      .catch((er: any) => {
        NotificationPopUp.show("Writer failed to shutdown", "error");
        console.error(er.message);
        this.setState({ pageLoading: false });
      });
  };

  confirmTabChange(tab: number) {
    confirmDialog({
      message:
        "This tab contains unsaved changes. Do you still wish to change the tab?",
      header: "Unsaved changes",
      acceptClassName: "p-button-danger",
      icon: "pi pi-exclamation-triangle",
      accept: () => this.accept(tab),
      reject: () => this.reject(),
      acceptLabel: "Change",
      rejectLabel: "Stay",
    });
  }

  accept(tab: number) {
    this.tabChanger(tab);
  }

  reject() {
    this.setState({ prepareChangeIndex: this.state.viewTabIndex });
  }

  render() {
    return (
      <>
        {this.state.errorMessage && (
          <div style={{ textAlign: "center" }}>
            <h3>Error</h3>
            {this.state.errorMessage}
          </div>
        )}
        {this.state.writerLoading && !this.state.errorMessage && (
          <div style={{ height: "100%", width: "100%", position: "relative" }}>
            <ProgressSpinner
              style={{
                width: "50px",
                height: "50px",
                position: "absolute",
                left: "47%",
                top: "45%",
              }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </div>
        )}

        {this.state.pageLoading && !this.state.errorMessage && (
          <PageDisablerOnLoading text={this.state.pageLoadingText} />
        )}

        {!this.state.errorMessage &&
          !this.state.pageLoading &&
          !this.state.writerLoading && (
            <>
              <ConfirmDialog />
              <div className={"data-writer"}>
                <div className={"left-side"}>
                  <div className={"list-box"}>
                    {this.state.listLoad ? (
                      <div className={"list-box-loading"}>
                        <ProgressSpinner />
                      </div>
                    ) : (
                      <ListBox
                        value={this.state.selectedPipeline}
                        options={this.state.pipelines}
                        onChange={(e) => {
                          if (e.value) {
                            this.setState({ selectedPipeline: e.value });
                            this.selectedPipelineId = e.value.id;
                          }
                        }}
                        filter
                        filterPlaceholder={"Search"}
                        filterBy={"id,name"}
                        optionLabel="id"
                        itemTemplate={this.listBoxItemTemplate}
                        style={{
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                        }}
                        listStyle={{ height: "calc(100% - 36px)" }}
                      />
                    )}
                  </div>
                </div>
                <div className={"tool-box-container"}>
                  <div className={"tool-box-top-container"}>
                    <CreateNewPipelineButton
                      refreshPipelines={this.refreshPipelines}
                    />
                    <WriterPipelineSaveButton
                      selectedPipeline={this.state.selectedPipeline}
                      saveCallback={this.saveButtonHandler}
                    />
                    <WriterPipelineRefreshButton
                      selectedPipeline={this.state.selectedPipeline}
                      restartCallback={this.restartPipelines}
                    />
                    <WriterTesterButton
                      editorFullConfig={this.state.editorFullConfig}
                    />
                    <WriterHistoryButton
                      pipelineId={this.state.selectedPipeline?.id}
                    />
                    <WriterLogViewerButton
                      pipelineId={this.state.selectedPipeline?.id}
                      showProgressIndicator={this.showLoadingIndicator}
                      hideProgressIndicator={this.hideLoadingIndicator}
                      header={this.spinnerHeaderHandler}
                    />
                    <WriterActionsButtonWithList
                      pipelineId={this.state.selectedPipeline?.id}
                      enabled={this.state.selectedPipeline?.enabled}
                      editorConfig={this.state.editorConfig}
                      schemaType={
                        this.state.viewTabIndex === 0
                          ? "INPUT"
                          : this.state.viewTabIndex === 1
                          ? "PROCESSING"
                          : this.state.viewTabIndex === 2
                          ? "OUTPUT"
                          : undefined
                      }
                    />
                    <WriterRemoveButton
                      unsetSelectedConfig={() => {
                        this.selectedPipelineId = undefined;
                        this.setState({
                          selectedPipeline: undefined,
                          editorFullConfig: undefined,
                        });
                        this.refreshPipelines();
                      }}
                      selectedPipelineId={this.selectedPipelineId}
                      selectedPipeline={this.state.selectedPipeline}
                    />
                    <Button
                      className={
                        "function-buttons-container-container-button p-button-secondary p-button-sm"
                      }
                      icon="pi pi-pencil"
                      tooltip="Open processor config editor"
                      onClick={() => {
                        this.setState({ readScript: !this.state.readScript });
                      }}
                      disabled={this.state.viewTabIndex !== 1}
                    />
                  </div>
                </div>
                <div className={"right-side"}>
                  {/*<div>CUSTOM TOOLS</div>*/}
                  <div className={"tab-view-window"}>
                    <div className={"function-buttons-container"}>
                      <div className={"button-container-scheme-picker"}>
                        <div
                          className={"tab-view-button active first-button"}
                          id={"g-button"}
                          onClick={() => {
                            if (this.state.editorFullConfig) {
                              this.setState({ prepareChangeIndex: 3 });
                              this.viewTabChangeable = true;
                            }
                          }}
                        >
                          General
                        </div>
                        <div
                          className={"tab-view-button middle-button"}
                          id={"f-button"}
                          onClick={() => {
                            if (this.state.editorFullConfig) {
                              this.setState({ prepareChangeIndex: 0 });
                              this.viewTabChangeable = true;
                            }
                          }}
                        >
                          Input
                        </div>
                        <div
                          className={"tab-view-button middle-button"}
                          id={"s-button"}
                          onClick={() => {
                            if (this.state.editorFullConfig) {
                              this.setState({ prepareChangeIndex: 1 });

                              this.viewTabChangeable = true;
                            }
                          }}
                        >
                          Process
                        </div>
                        <div
                          className={"tab-view-button last-button"}
                          id={"t-button"}
                          onClick={() => {
                            if (this.state.editorFullConfig) {
                              this.setState({ prepareChangeIndex: 2 });
                              this.viewTabChangeable = true;
                            }
                          }}
                        >
                          Output
                        </div>
                      </div>

                      {this.state.viewTabIndex === 2 && (
                        <MainComponentsWrapper
                          editorFullConfig={this.state.editorFullConfig}
                          setEditorFullConfig={(e) =>
                            this.setState({ editorFullConfig: e })
                          }
                          selectOutput={this.selectOutput}
                          configChanged={() => (this.editorChanged = true)}
                          editorConfig={this.state.editorConfig}
                        />
                      )}
                    </div>
                    <div className={"json-window"}>
                      {this.state.viewTabIndex === 1 ? (
                        <Encoder
                          config={this.state.editorFullConfig}
                          updateConfig={(config) => {
                            this.setState({ editorFullConfig: config });
                          }}
                          id={this.selectedPipelineId}
                          ref={this.encoderRef}
                          managementApi={this.apis.managementApiConst}
                          readScript={this.state.readScript}
                          setReadScript={(value) =>
                            this.setState({ readScript: value })
                          }
                          closeWindow={() =>
                            this.setState({ writerEncodeDialog: false })
                          }
                          updateWriters={this.refreshPipelines}
                        />
                      ) : (
                        <WriterView
                          writer={this.state.editorConfig || ""}
                          schema={this.state.editorSchema}
                          ref={this.writerViewRef}
                          errorIsInEditor={(res: boolean) =>
                            this.editorErrorStateChanger(res)
                          }
                          changed={(res: boolean) =>
                            this.editorStateChanger(res)
                          }
                          saveCallback={() => this.saveButtonHandler()}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
      </>
    );
  }
}

export default DataWriter;
