import {AxiosInstance} from "axios";
import {APIResponse, requestPromiseTypeWrapper} from "../service/HttpService";
import {DatasetDataInfoResponse} from "./apiModels";

export async function dataInfoRecent(axios: AxiosInstance, array: Array<any>,params?:any): Promise<APIResponse<Array<DatasetDataInfoResponse>>> {
    return requestPromiseTypeWrapper(axios.post<Array<any>>("/datasetData/dataInfoRecent", array,{params:{...params}}));
}

export async function datasetExists(axios: AxiosInstance, identifier:string): Promise<boolean> {
    const response = await requestPromiseTypeWrapper(axios.get<any>("/datasets/search", {params: {identifiers: identifier}}));
    return response.data.length > 0;

}