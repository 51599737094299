import React, {useId, useState} from 'react';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import PipelineAdd from "components/data-writer/PipelineAdd/PipelineAdd";
import {Tooltip} from "react-tooltip";

type PipelineButtonProps = {
    refreshPipelines(): void
}

const CreateNewPipelineButton = ({refreshPipelines}:PipelineButtonProps) => {
    const id = useId()
    const [writerAddDialog, setWriterAddDialog] = useState(false);

    return <>
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <Button
        icon={"pi pi-plus"}
        data-tooltip-id={id}
        data-tooltip-content="Add"
        className="p-button-success p-button-sm"
        onClick={() => setWriterAddDialog(true)}/>

        {writerAddDialog &&
            <Dialog
                visible={writerAddDialog}
                onHide={() => setWriterAddDialog(false)}
                resizable={false}
                style={{width: 'min(100vw, 800px)'}}
                header={"Create new writer"}
            >
                <PipelineAdd updateWriters={refreshPipelines}
                             closeWindow={() => setWriterAddDialog(false)}
                />
            </Dialog>
        }
    </>
};

export default CreateNewPipelineButton;