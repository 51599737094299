import React, {Component} from 'react';
import {Reader} from "../../../reader/Reader";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import './ReaderRemove.scss';
import {ConfirmPopup} from 'primereact/confirmpopup';
import {ManagementApi} from "../../../serviceapi";
import NotificationPopUp from "../../../util/NotificationPopUp";
import {CLIENT_TIMEOUT, DEFAULT_OPTIONS} from "../../../service/HttpService";


type Props = {
    reader: Reader,
    managementApi: ManagementApi,
    updateReaders(): void,
    closeDialog(dialog: string): void
}

type States = {
    value: string,
    visible: boolean
}

class ReaderRemove extends Component<Props, States> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            value: "",
            visible: false
        }

    }

    private confirm = () => {

        const er = this.props.reader;

        if (!er) {
            return;
        }

        this.props.managementApi.deleteReader(er.readerInfo.id as string, DEFAULT_OPTIONS)
            .then((e: any) => {
                if (!e) {
                    NotificationPopUp.show("Network Error", 'error')
                    return
                }
                let name: string;
                if (er.readerInfo.readerName) {
                    name = er.readerInfo.readerName
                } else {
                    name = "[" + er.readerInfo.id + "]";
                }
                NotificationPopUp.show(`Reader ${name} has been deleted`, 'success')
            })
            .then(this.props.updateReaders)
            .then(() => {
                this.props.closeDialog("remove")
            })
            .catch(er => {
                NotificationPopUp.show(er.message, 'error')
            })

    }

    private buttonDisable = () => {
        return this.props.reader?.readerInfo.id !== this.state.value;
    }

    render() {
        return (
            <div>
                <ConfirmPopup/>

                <div className={"text-alignment"}>
                    To remove this reader write reader id:[<span
                    className={"reader-name"}><b>{this.props.reader?.readerInfo.id}</b></span>]
                </div>

                <InputText style={{alignSelf: "center"}}
                           value={this.state.value}
                           onChange={(e) => this.setState({value: e.target.value})}/>

                <Button id="confirm-button"
                        style={{transform: "scale(90%)"}}
                        label="Remove"
                        icon="pi pi-times"
                        onClick={this.confirm}
                        disabled={this.buttonDisable()}/>
            </div>
        )
    }
}

export default ReaderRemove;