import React, {useId, useState} from 'react';
import {Button} from "primereact/button";
import {Reader} from "reader/Reader";
import {Dialog} from "primereact/dialog";
import CheatSheet from "components/general/CheatSheet/CheatSheet";
import {Tooltip} from "react-tooltip";

type CollectorCheatSheetButton = {
    editingReader?:Reader
}

const CollectorCheatSheetButton = ({editingReader}:CollectorCheatSheetButton) => {
    const id = useId()
    const [visible, setVisible] = useState(false);
    return <>
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <Button className="p-button-secondary p-button-sm"
                icon={"pi pi-question"}
                tooltip={'Protocol manual'}
                data-tooltip-id={id}
                data-tooltip-content={"Protocol manual"}
                tooltipOptions={{position: "bottom"}}
                onClick={() => setVisible(true)}
                disabled={editingReader == null}

        />

        {visible &&
            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                style={{minWidth: "16vw"}}
                header={editingReader?.readerInfo.implementationKey ?
                    editingReader.readerInfo.implementationKey + " manual"
                    :
                    "No selected reader"}
            >
                <CheatSheet implementationKey={editingReader?.readerInfo.implementationKey || ""}/>
            </Dialog>

        }
    </>
};

export default CollectorCheatSheetButton;