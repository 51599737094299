import React from 'react';

import './PipelineHistory.scss';

import EditorReadonly from "../../general/EditorReadonly";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import Utils from "../../../util/Utils";
import {Button} from "primereact/button";
import {ConfirmPopup, confirmPopup} from "primereact/confirmpopup";
import NotificationPopUp from "../../../util/NotificationPopUp";
import {apisType} from "../../../serviceapi/ApiImports";
import {apisStore} from "../../../context/GlobalStates";

type WriterHistoryProps = {
    pipelineId:string|undefined;
}

class WriterHistoryState {
    pipelineHistories: Array<any> =[];
    selectedPipeline: any = undefined;
    pipelineText: string = "";
    loading:boolean = true;
}

class PipelineHistory extends React.Component<WriterHistoryProps, WriterHistoryState> {
    private apis:apisType;
    constructor(props:Readonly<WriterHistoryProps>) {
        super(props);
        this.state = new WriterHistoryState();
        this.apis = apisStore.getState().dataPusher
    }

    componentDidMount() {
        this.updateWriters();
    }

    private updateWriters = () => {
        if(this.props.pipelineId) {
            this.apis.writerBackupApi?.getBackups([this.props.pipelineId])
                .then((res:any) => {
                    this.setState({pipelineHistories:res.data,loading:false})
                })
                .catch(err => NotificationPopUp.show(err.message,'error'))
        }
    }

    componentDidUpdate(_: Readonly<WriterHistoryProps>, prevState: Readonly<WriterHistoryState>) {
        if(this.state.selectedPipeline !== prevState.selectedPipeline) {
            this.apis.writerBackupApi?.getConfigurationBackup(this.state.selectedPipeline.id)
                .then((res:any) => {
                    this.setState({pipelineText:JSON.stringify(res.data,null,4)})
                })
                .catch(err => NotificationPopUp.show(err.message,'error'))
        }
    }

    private timestampToDate = (rowData: any) => {
        return Utils.timestampToReadableDate(rowData.timestamp)
    }

    private functionality = (rowData: any) => {
        return <div className={"button-container"}>
            <Button icon={"pi pi-history"}
                    className={"button p-button-info"}
                    onClick={event => this.restore(event, rowData.id)}
                    tooltip={"Restore writer config"}
            />
            <Button icon={"pi pi-trash"}
                    className={"button p-button-danger"}
                    onClick={event => this.remove(event, rowData.id)}
                    tooltip={"Remove writer config backup"}
            />
        </div>

    }

    private restore(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to restore pipeline with this backup?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.apis.writerBackupApi?.restoreBackup(this.props.pipelineId!!,id)
                    .then(() => {
                        NotificationPopUp.show("Pipeline was restored successfully","success")
                    })
                    .catch(err => NotificationPopUp.show(err.message,'error'))
            }
        });
    }

    private remove(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to remove pipeline back up?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.apis.writerBackupApi?.deleteBackup([id])
                    .then(() => {
                        NotificationPopUp.show("Pipeline backup removed successfully",'success')
                        this.updateWriters();
                    })
                    .catch(err => console.log(err))
            }
        });
    }

    render() {
        return (
            <div className={"writer-history-full-window"}>
                <div className={"left-side"}>
                    <DataTable value={this.state.pipelineHistories}
                               style={{maxHeight:"50vw"}}
                               sortField={"timestamp"}
                               sortOrder={-1}
                               selectionMode={"single"}
                               selection={this.state.selectedPipeline}
                               onSelectionChange={e => {
                                   this.setState({selectedPipeline: e.value});
                               }}
                               loading={this.state.loading}
                    >
                        <Column field="timestamp" header={"Date"} body={this.timestampToDate}/>
                        <Column className={"column"} body={this.functionality}/>
                    </DataTable>
                </div>
                <div className={"right-side"}>
                    <EditorReadonly text={this.state.pipelineText}/>
                </div>
                <ConfirmPopup />
            </div>
        );
    }
}

export default PipelineHistory;