import React from 'react';
import LogViewer from "components/general/LogViewer/LogViewer";
import {LogLevel, LogsApi} from "serviceapi";
import {CLIENT_TIMEOUT, DEFAULT_OPTIONS} from "../../../service/HttpService";


type LogViewerProps = {
    api: LogsApi,
    from?: Date,
    to?: Date,
    autoUpdate?: boolean,
    readerId?: string,
    showLoggerName?: boolean,

    showProgressIndicator(): void,
    hideProgressIndicator(): void,
}

const ReaderLogViewer = ({api, showProgressIndicator, hideProgressIndicator, readerId, showLoggerName}: LogViewerProps) => {


    const fetchData = (from: number, to: number, level: LogLevel) => {
        let logQuery = {
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
            minLevel: level
        };

        if (readerId) {
            return api.getReaderLog(readerId, logQuery, DEFAULT_OPTIONS);
        } else {
            return api.getGeneralLog(logQuery, DEFAULT_OPTIONS);
        }
    }

    return <LogViewer fetchData={fetchData}
                      showLoggerName={showLoggerName}
                      showProgressIndicator={showProgressIndicator}
                      hideProgressIndicator={hideProgressIndicator}
    />
};

export default ReaderLogViewer;