import axios, {AxiosPromise} from "axios";
import {ApiConfig} from "../axios/Config";
import UserService from "./UserService";
import {AxiosRequestHeaders} from "axios";

const headers = {"Content-Type": "application/json"}

export const CLIENT_TIMEOUT = 30000;
export const DEFAULT_OPTIONS = {timeout: CLIENT_TIMEOUT};

const createEasasAxiosClient = ({baseURL, authToken}: { baseURL: string, authToken: string }) => {
    let usedHeaders: any = {...headers};

    usedHeaders["Authorization"] = `Bearer ${authToken}`;

    let instance = axios.create({
        baseURL: baseURL,
        headers: headers,
    });

    instance.defaults.timeout = CLIENT_TIMEOUT;

    return instance;
}

const createAxiosClient = ({baseURL, serviceId}: { baseURL: string, serviceId?: string }) => {
    let usedHeaders: any = {...headers}

    usedHeaders["Accept"] = "application/json"
    usedHeaders['Access-Control-Request-Private-Network'] = "true";
    if (serviceId) {
        usedHeaders["x-eaproxy-endpointid"] = serviceId;
    }

    let instance = axios.create({
        baseURL: baseURL,
    });

    instance.defaults.headers.common = {...instance.defaults.headers.common, ...usedHeaders}

    instance.interceptors.request.use(async function (config) {
        await refreshToken()
        const accessToken = UserService.getToken();
        if (accessToken) {
            config.headers = {...config.headers, "Authorization": `Bearer ${accessToken}`} as AxiosRequestHeaders
        }
        return config;
    });

    instance.defaults.timeout = CLIENT_TIMEOUT;

    return instance;
}

const refreshToken = async () => {
    const accessTokenTime = UserService.getTokenParsed()?.exp;
    if (accessTokenTime) {
        const accessTokenTimeInSeconds = accessTokenTime - new Date().getTime() / 1000;
        if (accessTokenTimeInSeconds < 0) {
            await UserService.updateToken()
        }
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////

const HttpService = {
    get_EASASAxios: (baseURL: string, authToken: string) => createEasasAxiosClient({
        baseURL: baseURL,
        authToken: authToken
    }),
    get_dataCollectorAxios: (serviceId?: string) => createAxiosClient({
        baseURL: ApiConfig.baseUrl,
        serviceId: serviceId
    }),
    get_dataPusherAxios: (serviceId?: string) => createAxiosClient({
        baseURL: ApiConfig.baseUrl,
        serviceId: serviceId
    })
};

export type Info = { headers?: Record<string, any>; code?: number };

export type APIResponse<T> = { data?: T, error?: Error, info?: Info };

export type APIPromise<T> = Promise<APIResponse<T>>;

export function requestPromiseTypeWrapper<T>(promise: AxiosPromise<T>, objMapperFn?: (data: Partial<T>) => T): APIPromise<T> {
    return promise.then((res) => {
        if (objMapperFn) {
            return ({data: objMapperFn(res.data)});
        } else {
            return {data: res.data};
        }
    }).catch((error: any) => {
        return {error: error.response?.data};
    })
}

export default HttpService;