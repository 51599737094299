import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useRef, useState } from "react";
import { useResizeDetector } from 'react-resize-detector';
import { Tooltip } from "react-tooltip";
import { LogLevel, LogMessage } from "serviceapi";
import Utils from "../../../util/Utils";
import VirtualScroller from "../VirtualScroller/VirtualScroller";

type LogViewerProps = {
    showLoggerName?: boolean,
    fetchData: (from: number, to: number, level: LogLevel) => Promise<AxiosResponse<LogMessage[]>>,

    showProgressIndicator(): void,
    hideProgressIndicator(): void,
};

class LogLine {
    line: number;
    message: LogMessage;

    constructor(line: number, fullMessage: LogMessage) {
        this.line = line;
        this.message = fullMessage;
    }
}

const levelOptions: Array<LogLevel> = Object.values(LogLevel);

const lineTemplate = (item: LogLine, showLoggerName?: boolean): JSX.Element => (
    <pre style={{fontSize: "10pt", margin: "0"}} data-line={item.line}>
     {item.line} {item.message.timestamp} {item.message.level} {showLoggerName ? `${item.message.loggerName} ` : ""}{item.message.message}
  </pre>
);

const LoadingIndicator = () => (
    <div className="flex justify-content-center align-items-center h-full">
        <div className="text-muted">
            <ProgressSpinner style={{overflow: 'hidden'}}/>
        </div>
    </div>
);

const NoLogsMessage = ({message}: { message: string }) => (
    <div className="flex justify-content-center align-items-center h-full">
        <div className="text-muted">{message}</div>
    </div>
);

const MiddleRender = ({firstLoad, enabled, lines, linesSize, autoScroll}: {
    firstLoad: boolean,
    enabled: boolean,
    lines: JSX.Element[],
    linesSize: number[],
    autoScroll: boolean
}) => {
    if (firstLoad) {
        return <LoadingIndicator/>;
    } else {
        if (lines.length > 0) {
            return <VirtualScroller lines={lines} count={lines.length} linesSize={linesSize} autoScroll={autoScroll}/>;
        } else {
            return <NoLogsMessage message="No logs"/>;
        }
    }
};

const REFETCH_TIME = 5000; // 5 seconds

type Log = {
    line: JSX.Element;
    size: number;
}

const getHourChunks = (startDate: Date, endDate: Date) => {
  const chunks: { start: Date; end: Date }[] = [];
  let current = new Date(startDate.getTime());
  while (current <= endDate) {
    const next = new Date(current.getTime() + 60 * 60 * 1000); // Add hour in milliseconds

    chunks.push({
      start: new Date(current),
      end: next <= endDate ? new Date(next) : endDate,
    });

    current = next;
  }

  return chunks;
}

const LogViewer: React.FC<LogViewerProps> = (props) => {
    const logHistoryTime = Date.now() - (1000 * 60 * 5); // 5min log history
    const {height, ref} = useResizeDetector();
    const [dateFrom, setDateFrom] = useState(new Date(logHistoryTime));
    const [dateTo, setDateTo] = useState(new Date());
    const [isLiveFetch, setIsLiveFetch] = useState(true);
    const [autoScroll, setAutoScroll] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [logLevel, setLogLevel] = useState<LogLevel>("ALL");
    const hasBeenRendered = useRef(false);

    const [logs, setLogs] = useState<Log[]>([]);
    const [filteredLogs, setFilteredLogs] = useState<Log[]>([]);
    const [dateRangeLogs, setDateRangeLogs] = useState<Log[]>([]);

    const {data, refetch, isFetching} = useQuery({
        queryKey: ['logs', isLiveFetch],
        queryFn: async () => {
            const timeFrom = isLiveFetch ? dateFrom.getTime() : dateFrom.setSeconds(0, 0);
            const timeTo = isLiveFetch ? Date.now() : dateTo.setSeconds(0, 0);
            const logType = isLiveFetch ? "ALL" : logLevel;
            setFirstLoad(false);

            if (!isLiveFetch) {
              const chunks = getHourChunks(dateFrom, dateTo);
              const promises = chunks.map(chunk =>
								props.fetchData(chunk.start.getTime(), chunk.end.getTime(), logType),
							);
              return await Promise.all(promises).then(data =>
								data.reduce(
									(acc, curr) => ({
										...acc,
										...curr,
										data: acc?.data ? [...acc.data, ...curr.data] : curr.data,
									}),
									{} as AxiosResponse<LogMessage[]>,
								),
							);
            }
            return await props.fetchData(timeFrom, timeTo, logType)},
        refetchInterval: isLiveFetch ? REFETCH_TIME : false,
        enabled: isLiveFetch,
        gcTime: 0
    });

    if (isFetching) {
        props.showProgressIndicator();
    } else {
        props.hideProgressIndicator();
    }

    useEffect(() => {
        if (data && data?.data?.length > 0 && isLiveFetch) {
            const lastData = [...data.data].pop();
            const lastLine = [...logs].pop();
            const lineNumber = lastLine?.line?.props?.["data-line"] ?? 0;

            setDateFrom(new Date(lastData?.timestamp ?? logHistoryTime));
            setLogs(prev => [
                ...prev,
                ...mapLogs(data.data, lineNumber),
            ])
        }

        if (data && data?.data?.length > 0 && !isLiveFetch) {
            setDateRangeLogs(mapLogs(data.data));
        }
    }, [data]);

    useEffect(() => {
        if (isLiveFetch && hasBeenRendered.current) {
            setDateRangeLogs([])
            refetch();
        } 

        if (!isLiveFetch && firstLoad) {
            setLogs([]);
            setFirstLoad(false);
        }

        hasBeenRendered.current = true;
    }, [isLiveFetch])

    useEffect(() => {
        setFilteredLogs(logs.filter(l => logLevel === "ALL" ? l : l.line?.props?.children?.[4] === logLevel));
    }, [logs, logLevel])

    const exportJSXArrayToString = (array: JSX.Element[]) => {
        const arr = array.map((item) => {
            return item.props.children.join("")
        }).join("\n")

        Utils.downloadStringToTxt(arr, "logs.txt");
    }

    const mapLogs = (data: LogMessage[], lineNumber: number = 0) => {
        return data?.map((m, i) => {
            const log = new LogLine(i + 1 + lineNumber, m);
            return {
                line: lineTemplate(log, props.showLoggerName),
                size: `${log.message.loggerName} ${log.message.message}`.split(/\r\n|\r|\n/).length * 15,
            };
        });
    };
    
    const getLogs = () =>{
        let logSizes = [20];
        let logLines = [<NoLogsMessage message="No logs" />];

        if (!isLiveFetch && dateRangeLogs.length > 0) {
            logSizes = dateRangeLogs.map(l => l.size);
            logLines = dateRangeLogs.map(l => l.line);
        }

        if (isLiveFetch && filteredLogs.length > 0) {
            logSizes = filteredLogs.map(l => l.size);
            logLines = filteredLogs.map(l => l.line);
        }

        return { lines : logLines, sizes: logSizes };
    }

    return (
			<div className="flex flex-column h-full gap-2" ref={ref}>
				<div
					className={`flex ${
						isLiveFetch ? "justify-content-end" : "justify-content-between"
					} pl-1 pr-1`}>
					{!isLiveFetch && (
						<div className="flex gap-2">
							<Calendar
								disabled={isLiveFetch}
								value={dateFrom}
								onChange={e => {
									if (e.target.value) {
										setDateFrom(e.target.value as Date);
									}
								}}
								showTime
								dateFormat={"yy-mm-dd"}
								showIcon
							/>
							<Calendar
								disabled={isLiveFetch}
								value={dateTo}
								onChange={e => {
									if (e.target.value) {
										setDateTo(e.target.value as Date);
									}
								}}
								showTime
								dateFormat={"yy-mm-dd"}
								showIcon
							/>
							<Button
								label="Fetch"
								className="p-button-sm"
								onClick={() => {
									setFirstLoad(true);
									refetch();
								}}
								disabled={isLiveFetch}
							/>
						</div>
					)}
					<div className="flex gap-2">
						<div className="flex justify-content-center align-items-center">
							<Tooltip id={"logs-enable-button"} />
							<InputSwitch
								checked={isLiveFetch}
								onChange={e => {
									setDateFrom(new Date(logHistoryTime));
									setDateTo(new Date());
									setIsLiveFetch(e.value);
									setFirstLoad(true);
								}}
								className="input-switch"
								data-tooltip-id={"logs-enable-button"}
								data-tooltip-content={isLiveFetch ? "Live fetching" : "Historical data"}
							/>
						</div>
						<div className="flex justify-content-center align-items-center">
							<Tooltip id={"logs-autoscroll-button"} />
							<InputSwitch
								checked={autoScroll}
								onChange={e => setAutoScroll(e.value)}
								className="input-switch"
								data-tooltip-id={"logs-autoscroll-button"}
								data-tooltip-content={autoScroll ? "Auto scrolling: ON" : "Auto scrolling: OFF"}
							/>
						</div>
						<div>
							<Dropdown
								placeholder="Min. Level"
								value={logLevel}
								options={levelOptions}
								onChange={e => setLogLevel(e.value)}
							/>
						</div>
						<Button
							label="Download"
							className="p-button-sm"
							onClick={() => exportJSXArrayToString(getLogs().lines.slice(0, -1))}
						/>
					</div>
				</div>
				<Divider />
				<div style={{ width: "100%", height: height ? height - 45 : "450px" }}>
					<MiddleRender
						firstLoad={firstLoad}
						linesSize={getLogs().sizes}
						lines={getLogs().lines}
						enabled={isLiveFetch}
						autoScroll={autoScroll}
					/>
				</div>
			</div>
		);
};

export default LogViewer;
