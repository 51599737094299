import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ToolsApi} from "../../../serviceapi";
import './Toolnvoker.scss';
import NotificationPopUp from "../../../util/NotificationPopUp";
import {AxiosResponse} from "axios";
import {ProgressSpinner} from "primereact/progressspinner";

type Props = {
    toolsApi: ToolsApi,
    startFunction(toolsApi: ToolsApi, props: any): Promise<AxiosResponse<string, any>>
}

class State {
    params: any = {address: ""};
    button: boolean = false;
    runId: string = "";
    logs: string = "";
    loading: boolean | undefined = undefined;
}

class ToolInvoker extends Component<Props, State> {
    private interval?: NodeJS.Timer;

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = new State();
    }

    private startButtonPressed = () => {
        if (this.state.params.address) {
            this.givenPropsFunction();
            this.setState({loading: true})
        } else {
            NotificationPopUp.show("Address is not specified")
        }
    }

    private stopButtonPressed = () => {
        this.setState({button: false})
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    private givenPropsFunction = () => {
        this.props.startFunction(this.props.toolsApi, this.state.params)
            .then(res => {
                this.setState({runId: res.data, button: true});
            })
            .catch(() => {
                this.setState({loading: false})
                NotificationPopUp.show("Bad address", 'error');
            })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevState.runId !== this.state.runId) {
            this.interval = setInterval(() => this.updateLog(), 1000)
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (this.state.runId) {
            this.props.toolsApi.killRun(this.state.runId)
                .catch(er => console.log(er.message, 'error'))
        }
    }

    private updateLog = () => {
        this.props.toolsApi.getOutput(this.state.runId)
            .then(res => {
                this.connectStringArray(res.data)
            })
            .catch(err => NotificationPopUp.show(err.message, 'error'))
    }

    private connectStringArray = (logs: string[]) => {
        let temp = logs.join("\n");


        this.setState({logs: temp})
        if (temp.length && this.state.loading) {
            this.setState({loading: false})
        }
    }

    render() {
        return (
            <div className={"full-window-tool-invoker"}>
                <div className={"address-bar"}>
                    <span className="p-float-label">
                        <InputText id="address"
                                   value={this.state.params.address}
                                   onChange={(e) => this.setState({params: {address: e.target.value}})}
                                   disabled={this.state.button}/>
                        <label htmlFor="address">Address</label>
                    </span>
                    <div className={"tool-invoker-button-container"}>
                        <Button label={"Start"}
                                onClick={this.startButtonPressed}
                                disabled={this.state.button}/>
                        <Button label={"Stop"}
                                onClick={this.stopButtonPressed}
                                disabled={!this.state.button}/>
                    </div>
                </div>
                <div className={"logs"}>
                    {
                        (this.state.loading !== undefined) ?
                            (this.state.loading) ?
                                <div style={{width: "60px", height: "60px"}}>
                                    <ProgressSpinner style={{width: "50px", height: "50px"}}/>
                                </div>
                                :
                                <pre>
                                    {this.state.logs}
                                </pre>
                            :
                            ""
                    }

                </div>
            </div>
        );
    }
}

export default ToolInvoker;