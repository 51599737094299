import React, {useId, useState} from 'react';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {HideLoadingIndicator, ShowLoadingIndicator, TestHeaderHandler} from "components/dialogs/dialogHeaders";
import {LoadingMeasurementTable} from "components/general/LoadingMeasurementTable/LoadingMeasurementTable";
import {Tooltip} from "react-tooltip";

type DataWriterConfigurationButtonProps = {
    editingReader: any
}

const DataWriterConfigurationButton = ({editingReader}:DataWriterConfigurationButtonProps) => {
    const id = useId()
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
            <Button data-tooltip-id={id}
                    data-tooltip-content={"Display data writings based on current configuration"}
                    icon={"pi pi-chart-bar"}
                    className={"p-button-sm p-button-secondary"}
                    onClick={() => setVisible(true)}
                    disabled={editingReader == null}
            />

            {visible&&
                <Dialog
                    visible={visible}
                    onHide={() => setVisible(false)}
                    breakpoints={{'960px': '75vw', '640px': '100vw'}}
                    style={{
                        minWidth: "min(1000px, 40vw)",
                        maxWidth: "90vw",
                        width: "65vw"
                    }}
                    header={TestHeaderHandler(editingReader)}
                >
                    <LoadingMeasurementTable readerId={editingReader?.readerInfo.id}
                                             showProgressIndicator={ShowLoadingIndicator}
                                             hideProgressIndicator={HideLoadingIndicator}
                    />
                </Dialog>

            }
        </>
    );
};

export default DataWriterConfigurationButton;