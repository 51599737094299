import React from 'react';

const ServerInfo = () => {
    const version = process.env.REACT_APP_VERSION;
    const buildDate = process.env.REACT_APP_BUILD_DATE;
    console.log("Version: " + version + ", Build Date: " + buildDate)
    return (
        <div className={"flex flex-column gap-2"}>
            <div><b>Build Version:</b> v{version}</div>
            <div><b>Build Date:</b> {buildDate}</div>
        </div>
    );
};

export default ServerInfo;