import React, {useEffect, useState} from 'react';
import {ManagementApi} from "../../../serviceapi/dataPusherApi";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import NotificationPopUp from "../../../util/NotificationPopUp";
import Utils from "../../../util/Utils";
import {apisStore} from "../../../context/GlobalStates";

import './PipelineAdd.scss';

const PipelineAdd = ({updateWriters, closeWindow}: {
    updateWriters(): void,
    closeWindow(): void
}) => {
    const managementApi: ManagementApi = (apisStore.getState().dataPusher as any).managementApiConst;

    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [inputSchema, setInputSchema] = useState<string[]>([]);
    const [selectedInputSchema, setSelectedInputSchema] = useState<string | null>();
    const [processSchema, setProcessSchema] = useState<string[]>([]);
    const [selectedProcessSchema, setSelectedProcessSchema] = useState<string | null>();
    const [outputSchema, setOutputSchema] = useState<string[]>([]);
    const [selectedOutputSchema, setSelectedOutputSchema] = useState<string | null>();

    const inputSchemas = [];
    const processSchemas = [];
    const outputSchemas = [];

    const fullObject: any = {enabled: false};

    useEffect(() => {
        managementApi.getAllSchemas("INPUT")
            .then((res) => {
                setInputSchema(Object.keys(res.data));
                inputSchemas.push(...Object.values(res.data));
            })
            .catch((err) => {
                console.error(err);
                NotificationPopUp.show("Error trying to fetch input schemas");
            });

        managementApi.getAllSchemas("PROCESSING")
            .then((res) => {
                setProcessSchema(Object.keys(res.data));
                processSchemas.push(...Object.values(res.data));
            })
            .catch((err) => {
                console.error(err);
                NotificationPopUp.show("Error trying to fetch processing schemas");
            });

        managementApi.getAllSchemas("OUTPUT")
            .then((res) => {
                setOutputSchema(Object.keys(res.data));
                outputSchemas.push(...Object.values(res.data));
            })
            .catch((err) => {
                console.error(err);
                NotificationPopUp.show("Error trying to fetch output schemas");
            });
    }, []);

    const doesSchemaExists = () => {
        managementApi.doesPipelineExist(id)
            .then((res) => {
                if (res.data) {
                    NotificationPopUp.show("Writer with this id already exists");
                } else {
                    createNewWriter();
                }
            })
            .catch((res) => {
                console.error(res);
                NotificationPopUp.show(res.message, 'error');
            });
    };

    const validateInputs = () => {
        if (!id || id.replaceAll(" ", "") === "") {
            NotificationPopUp.show("Id field is empty");
            return;
        } else if (!name || name.replaceAll(" ", "") === "") {
            NotificationPopUp.show("Name field is empty");
            return;
        } else if (!selectedInputSchema) {
            NotificationPopUp.show("Choose input schema");
            return;
        } else if (!selectedProcessSchema) {
            NotificationPopUp.show("Choose processing schema");
            return;
        } else if (!selectedOutputSchema) {
            NotificationPopUp.show("Choose output schema");
            return;
        } else {
            doesSchemaExists();
        }
    };

    const createNewWriter = async () => {
        if (!selectedInputSchema) {
            NotificationPopUp.show("Input schema is not selected");
            return;
        }

        if (!selectedProcessSchema) {
            NotificationPopUp.show("Processing schema is not selected");
            return;
        }

        if (!selectedOutputSchema) {
            NotificationPopUp.show("Output schema is not selected");
            return;
        }

        try {
            const inputConfig = await managementApi.createEmptyConfig(selectedInputSchema, "INPUT");
            fullObject.inputConfig = inputConfig.data;

            const processingConfig = await managementApi.createEmptyConfig(selectedProcessSchema, "PROCESSING");
            fullObject.processorConfig = Utils.setDefaultProcessingConfig(processingConfig.data);

            const outputConfig = await managementApi.createEmptyConfig(selectedOutputSchema, "OUTPUT");
            fullObject.outputConfig = outputConfig.data;
            fullObject.id = id.replaceAll(" ", "_");
            fullObject.name = name;

            await managementApi.parseConfig(JSON.stringify(fullObject));
            await managementApi.setPipelineConfig(id, false, true, JSON.stringify(fullObject));

            NotificationPopUp.show("Pipeline was created successfully", 'success');

            await managementApi.reloadPipeline(id);
            updateWriters();
            closeWindow();
        } catch (err) {
            console.error(err);
            NotificationPopUp.show("Error", 'error');
        }
    };

    return (
        <div className={"writer-add-full-window"}>
            <div className={"top-side"}>
                <div className="p-float-label id-input">
                    <InputText
                        id="writerId"
                        value={id}
                        onChange={(e) => setId(e.target.value.replaceAll(" ", "_"))}
                    />
                    <label htmlFor="writerId">Id</label>
                </div>
                <div className="p-float-label name-input">
                    <InputText
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <label htmlFor="name">Name</label>
                </div>
            </div>
            <div className={"bottom-side"}>
                <div className="p-float-label input-schema-wrapper">
                    <Dropdown
                        value={selectedInputSchema}
                        options={inputSchema}
                        onChange={(e) => setSelectedInputSchema(e.value)}
                        filter
                        showClear
                        filterBy="name"
                        id={"input-schema-picker"}
                        style={{width: "100%", minWidth: "200px"}}
                        disabled={inputSchema.length === 0}
                    />
                    <label htmlFor="input-schema-picker">Input Schema</label>
                </div>
                <div className="p-float-label process-schema-wrapper">
                    <Dropdown
                        value={selectedProcessSchema}
                        options={processSchema}
                        onChange={(e) => setSelectedProcessSchema(e.value)}
                        filter
                        showClear
                        filterBy="name"
                        id={"process-schema-picker"}
                        style={{width: "100%", minWidth: "200px"}}
                        disabled={processSchema.length === 0}
                    />
                    <label htmlFor="process-schema-picker">Process Schema</label>
                </div>
                <div className="p-float-label output-schema-wrapper">
                    <Dropdown
                        value={selectedOutputSchema}
                        options={outputSchema}
                        onChange={(e) => setSelectedOutputSchema(e.value)}
                        filter
                        showClear
                        filterBy="name"
                        id={"output-schema-picker"}
                        style={{width: "100%", minWidth: "200px"}}
                        disabled={outputSchema.length === 0}
                    />
                    <label htmlFor="output-schema-picker">Output Schema</label>
                </div>
            </div>
            <div className={"button-wrapper"}>
                <Button label={"Add"} onClick={validateInputs}/>
            </div>
        </div>
    );
};

export default PipelineAdd;
