import React from 'react';

import NotificationPopUp from "util/NotificationPopUp";
import notificationPopUp from "util/NotificationPopUp";
import {Tree, TreeEventNodeParams, TreeSelectionKeys} from "primereact/tree";
import {ReaderView} from "reader/ReaderView";
import ReaderToggleButton from "./ReaderToggleButton/ReaderToggleButton";
import TreeNode from "primereact/treenode";
import {Reader} from "reader/Reader";
import Utils from "util/Utils";
import {ProgressSpinner} from "primereact/progressspinner";
import Apis, {apisType} from "serviceapi/ApiImports";
import PageDisablerOnLoading from "components/general/PageDisablerOnLoading/PageDisablerOnLoading";
import {ActionDTO} from "serviceapi";
import {apisStore} from "context/GlobalStates";
import {AxiosRequestConfig} from "axios";

import CreateReaderButton from "components/data-collector/DataCollector/Buttons/CreateReaderButton";
import SaveReaderButton from "components/data-collector/DataCollector/Buttons/SaveReaderButton";
import RestartReaderButton from "components/data-collector/DataCollector/Buttons/RestartReaderButton";
import DataWriterConfigurationButton
    from "components/data-collector/DataCollector/Buttons/DataWriterConfigurationButton";
import CollectorLogsButton from "components/data-collector/DataCollector/Buttons/CollectorLogsButton";
import CollectorHistoryButton from "components/data-collector/DataCollector/Buttons/CollectorHistoryButton";
import RemoveCollectorButton from "components/data-collector/DataCollector/Buttons/RemoveCollectorButton";
import ReaderActionsButton from "components/data-collector/DataCollector/Buttons/ReaderActionsButton";
import CollectorCheatSheetButton from "components/data-collector/DataCollector/Buttons/CollectorCheatSheetButton";

import './DataCollector.scss';
import 'App.css';
import {CLIENT_TIMEOUT, DEFAULT_OPTIONS} from "../../../service/HttpService";

class TreeData {
    reader: Reader;
    isReader: boolean;

    constructor(reader: Reader, isReader: boolean) {
        this.reader = reader;
        this.isReader = isReader;
    }
}

type DataCollectorProps = {}

class DataCollectorState {
    nodes: TreeNode[] = [];
    loading: boolean = true;
    selectedNodeKey: TreeSelectionKeys = null;
    readers: Reader[] = [];
    editingReader?: Reader = undefined;
    editorEdited?: boolean = true;
    meeterSelected?: string = undefined;
    editorJsonSchema?: Object;
    errorFromEdit: boolean = false
    display: boolean = false;
    checked: boolean = false
    readerRemoveDialog: boolean = false;
    readerManualDialog: boolean = false;
    test_selectedReaderForLog?: string = undefined;
    errorMessage: string = "";
    reloadButtonLoading: boolean = false;
    pageLoading: boolean = false;
    pageLoadingText: string = "";
    readerActionData: ActionDTO = {};
    items: Array<any> = []
    editorMounted: boolean = false;
    revealLine: boolean = true;
}

class DataCollector extends React.Component<DataCollectorProps, DataCollectorState> {
    private apis: apisType = Apis("datacollector")
    private editorHasError: boolean = false;
    private rendered: boolean = false;

    constructor(props: Readonly<DataCollectorProps> | DataCollectorProps) {
        super(props);
        this.state = new DataCollectorState();
    }

    componentDidMount() {
        if (!this.rendered) {
            this.updateReaders();
            this.rendered = true;
            if (!apisStore.getState().initialized) {
                apisStore.setState({
                    dataCollector: this.apis,
                    dataPusher: Apis("datapusher"),
                    initialized: true
                })
            }
        }
    }

    componentDidUpdate(_: Readonly<DataCollectorProps>, prevState: Readonly<DataCollectorState>) {
        if (prevState.nodes !== this.state.nodes && this.state.loading) {
            this.setState({loading: false})
        }

        if (this.state.editorMounted && this.state.readers.length === 1) {
            this.onTreeVirtualSelect(this.state.readers[0])
            this.setState({editorMounted: false})
        }
    }

    private updateReaders = (readerId?: string) => {
        this.apis.objectsApi.getReaders(DEFAULT_OPTIONS as AxiosRequestConfig)!.then((res: any) => {
            if (!res) {
                this.setState({errorMessage: "Invalid URL"})
                return
            } else if (res.status !== 200) {
                this.setState({errorMessage: res.status})
                return
            } else if (!res.data) {
                this.setState({errorMessage: res.status})
                return
            }

            let readers = this.state.readers.slice()

            readers.splice(0);

            res.data
                .map((reader: any) => new Reader(reader, this.apis.managementApi))
                .forEach((r: any) => readers.push(r));

            let readerNodes = readers.map(reader => {
                let node: TreeNode = {
                    key: reader.readerInfo.id,
                    label: reader.readerInfo.id,
                    icon: "",
                    data: new TreeData(reader, true),
                    children: reader.readerInfo.meters?.map(meter => {
                        let node: TreeNode = {
                            key: meter.id,
                            label: (meter.meterName ? meter.meterName + "(" + meter.id + ")" : meter.id),
                            data: new TreeData(reader, false)
                        }
                        return node;
                    })
                }
                return node;
            })

            this.setState({
                readers: readers,
                nodes: readerNodes.sort((a, b) => {
                    if (a.data.reader.readerInfo.readerName && b.data.reader.readerInfo.readerName) {
                        if (a.data.reader.readerInfo.readerName.toLowerCase() < b.data.reader.readerInfo.readerName.toLowerCase()) {
                            return -1;
                        } else {
                            return 1;
                        }
                    } else if (a.data.reader.readerInfo.readerName) {
                        return 1;
                    } else if (b.data.reader.readerInfo.readerName) {
                        return -1;
                    }
                    return 0;
                })
            })

            if (!readerId) {
                this.onUnselectTree()
            } else {
                let readerForEditor = readers.find(a => a.readerInfo.id === readerId);
                if (readerForEditor) {
                    this.onTreeVirtualSelect(readerForEditor);
                }
            }
        }).catch((er: any) => {
            this.setState({errorMessage: er.message})
        })

    }

    private saveReader = () => {
        if (this.state.editorEdited) {
            NotificationPopUp.show("No change in config")
            return
        }
        const er = this.state.editingReader;
        if (!er) {
            return;
        }
        this.setState({editorEdited: true})
        try {
            Utils.editorHaveErrors(this.editorHasError)
        } catch (errorObj: any) {
            NotificationPopUp.show(errorObj.message, 'error')
            return
        }

        const erConfig = JSON.parse(er?.config as string);

        try {
            Utils.checkConfigValidation(erConfig, er.readerInfo)
        } catch (errorObj: any) {
            NotificationPopUp.showHTML(errorObj.message, 'error')
            return
        }
        this.setState({pageLoading: true, pageLoadingText: "Saving"})
        this.setState({meeterSelected: undefined})

        this.saveAsync(er)
    }

    private saveAsync = async (reader?: Reader) => {
        if (reader) {
            try {
                await reader.saveConfig()
                await reader.restart()

                this.updateReaders(reader.readerInfo.id);
                this.setState({pageLoading: false, pageLoadingText: ""});
                NotificationPopUp.show("Reader saved", 'success');
            } catch (er: any) {
                this.updateReaders(reader.readerInfo.id);
                this.setState({pageLoading: false, pageLoadingText: ""});
                NotificationPopUp.show(er.message, 'error');
            }
        }
    }

    private onSelectTree = (reader: Reader) => {
        if (this.state.editingReader !== reader) {
            this.getSchemaAsync(reader)
                .then(() => {
                    this.setState({
                        editingReader: reader,
                        editorEdited: true
                    });
                })
                .catch(() => {
                    notificationPopUp.show("Couldn't fetch reader schema", "error")
                })

            this.setState({test_selectedReaderForLog: reader.readerInfo.id});
        }
    }

    private getSchemaAsync = async (reader: Reader) => {
        await this.apis.managementApi.getSchema(reader.readerInfo.implementationKey!)
            .then((res: any) => {
                if (res.data) {
                    this.setState({editorJsonSchema: res.data})

                }
            });
    }

    restartCollector = () => {
        this.setState({pageLoading: true, pageLoadingText: "Collector restarting"})
        this.apis.managementApi.reloadAll({timeout: CLIENT_TIMEOUT * 5})
            .then(() => {
                NotificationPopUp.show("Collector restarted successfully", 'success');
                this.setState({pageLoading: false})
            })
            .catch((er: any) => {
                NotificationPopUp.show("Collector failed to restart", 'error');
                console.error(er.message)
                this.setState({pageLoading: false})
            });
    }

    shutdownReader = () => {
        this.setState({pageLoading: true, pageLoadingText: "Reader shutting down"})
        this.apis.managementApi.shutdownDatacollector({timeout: CLIENT_TIMEOUT * 5})
            .then(() => {
                NotificationPopUp.show("Reader shutdown successfully", 'success');
                this.setState({pageLoading: false})
            })
            .catch((er: any) => {
                NotificationPopUp.show("Reader failed to shutdown", 'error');
                console.error(er.message)
                this.setState({pageLoading: false})
            });
    }

    private onUnselectTree = () => {
        this.setState({
            editingReader: undefined,
            selectedNodeKey: "",
            editorEdited: true,
            test_selectedReaderForLog: undefined
        });
    }

    private onTreeVirtualSelect = (reader: Reader) => {
        if (this.state.editingReader !== reader) {
            let temp = reader.readerInfo.id ? reader.readerInfo.id as TreeSelectionKeys : "";
            this.apis.managementApi.getSchema(reader.readerInfo.implementationKey!, DEFAULT_OPTIONS)
                .then((res: any) => {
                    this.setState({editorJsonSchema: res.data})
                    this.setState({
                        editingReader: reader,
                        editorEdited: true,
                        test_selectedReaderForLog: reader.readerInfo.id,
                        selectedNodeKey: temp
                    });
                });
        }
    }

    private onTreeNodeSelectHandler = (event: TreeEventNodeParams) => {
        this.setState({
            meeterSelected: !event.node.data.isReader ? event.node.key as string : undefined,
            revealLine: true
        });
        this.onSelectTree(event.node.data.reader);
    }

    private nodeTemplate = (node: any, options: any) => {
        if (node.data.isReader) {
            if (node.data.reader.readerInfo.readerName) {
                return (
                    <span className={options.className}
                          style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%"
                          }}>
                    <span className="long-name-tooltip" style={{wordBreak: "break-all"}}>
                         {node.data.reader.readerInfo.readerName}
                    </span>

                    <div>
                        {<ReaderToggleButton reader={node.data.reader}
                                             onReaderToggled={this.updateReaders}/>
                        }
                    </div>
                </span>
                )
            } else {
                return (
                    <span className={options.className}
                          style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%"
                          }}>
                        <span>
                             [{node.label}]
                        </span>

                        <div>
                            {<ReaderToggleButton reader={node.data.reader} onReaderToggled={this.updateReaders}/>}
                        </div>
                </span>
                )
            }

        } else {
            let returnName: string = this.extractMeeterName(node);
            return (
                <span className={options.className} style={{wordBreak: "break-all"}}>
                    {returnName}
                </span>
            )
        }
    }

    private extractMeeterName = (node: any) => {
        if (node.label.includes("(" + node.key + ")")) {
            return node.label.replace("(" + node.key + ")", "");
        } else {
            return "[" + node.label + "]";
        }
    }

    private saveErrorHandler = (error: boolean) => {
        this.editorHasError = error;
    }

    private editorEdited = (b: boolean) => {
        this.setState({editorEdited: b})
    }

    render() {
        if (this.state.errorMessage) {
            return <div style={{textAlign: "center"}}>
                <h3>Error</h3>
                {this.state.errorMessage}
            </div>
        } else if (this.state.loading && !this.state.errorMessage) {
            return <div style={{height: "100%", width: "100%", position: "relative"}}>
                <ProgressSpinner
                    style={{width: '50px', height: '50px', position: "absolute", left: "47%", top: "45%"}}
                    strokeWidth="8"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"/>
            </div>
        } else if (!this.state.errorMessage && !this.state.loading) {
            return <div className={"data-collector"}>
                <div className={"leftSide"}>
                    <div className={"treeList"}>
                        {this.state.readers.length === 0 &&
                            <div style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translateX(-50%)",
                                zIndex: "100"
                            }}>No Readers</div>
                        }

                        <Tree value={this.state.nodes}
                              className={"readers-tree-container"}
                              onSelect={this.onTreeNodeSelectHandler}
                              selectionMode={"single"}
                              filter
                              filterPlaceholder={"Search"}
                              nodeTemplate={this.nodeTemplate}
                              selectionKeys={this.state.selectedNodeKey}
                              onSelectionChange={e => this.setState({selectedNodeKey: e.value})}
                              loading={this.state.loading}
                        />
                    </div>
                </div>

                <div className={"middle-side"}>
                    <div className={"tool-box-top-container"}>
                        <CreateReaderButton updateReaders={this.updateReaders}
                                            onUnselectTree={this.onUnselectTree}/>
                        <SaveReaderButton editingReader={this.state.editingReader}
                                          saveReader={this.saveReader}/>
                        <RestartReaderButton editingReader={this.state.editingReader}/>
                        <DataWriterConfigurationButton editingReader={this.state.editingReader}/>
                        <CollectorLogsButton editingReader={this.state.editingReader}/>
                        <ReaderActionsButton editingReader={this.state.editingReader}/>
                        <CollectorHistoryButton updateReaders={this.updateReaders}
                                                editingReader={this.state.editingReader}/>
                        <RemoveCollectorButton editingReader={this.state.editingReader}
                                               updateReaders={this.updateReaders}/>
                    </div>
                    <div className={"tool-box-bottom-container"}>
                        <CollectorCheatSheetButton editingReader={this.state.editingReader}/>
                    </div>
                </div>

                <div className={"rightSide"}>
                    <div className={"json-window"}>
                        <ReaderView reader={this.state.editingReader}
                                    schema={this.state.editorJsonSchema}
                                    errorIsInEditor={this.saveErrorHandler}
                                    changed={this.editorEdited}
                                    mounted={(e) => this.setState({editorMounted: e})}
                                    meeterId={this.state.meeterSelected}
                                    saveCallback={() => this.saveReader()}
                                    revealLine={this.state.revealLine}
                                    setRevealLine={() => this.setState({revealLine: false})}
                        />
                    </div>
                </div>

                {this.state.pageLoading &&
                    <PageDisablerOnLoading text={this.state.pageLoadingText}/>
                }
            </div>
        } else {
            return <div style={{textAlign: "center"}}>
                <h3>Error</h3>
                Something went wrong
            </div>
        }
    }
}

export default DataCollector;