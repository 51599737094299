import HttpService from "service/HttpService";
import {
    BackupApi,
    LogsApi,
    ManagementApi,
    MeasurementApi,
    ObjectApi,
    TestingApi,
    ToolsApi,
    ActionsApi
} from "./dataCollectorApi";
import {
    ManagementApi as ManagementApiConst,
    BackupApi as BackupApiConst,
    LogsApi as writerLogsConst,
    ActionsApi as ActionsApiWriter
} from "./dataPusherApi";
import {ApiConfig} from "../axios/Config";
import {apisStore} from "context/GlobalStates";

export type apisType = {
    objectsApi: ObjectApi;
    managementApi: ManagementApi;
    testingApi: TestingApi;
    measurementApi: MeasurementApi;
    logsApi: LogsApi;
    toolsApi: ToolsApi;
    backupApi: BackupApi;
    actionsApi: ActionsApi;
    writerActionsApi: ActionsApiWriter;
    writerBackupApi: BackupApiConst;
    managementApiConst: ManagementApiConst;
    writerLogs: writerLogsConst;
}

const Apis = (page?: string): apisType => {
    let baseURL = apisStore.getState().serviceId ?
        process.env.REACT_APP_BACKEND_BASE_URL + `${page}` as string :
        ApiConfig.baseUrl;


    if (page === "datacollector") {
        baseURL = baseURL.replaceAll("datapusher", page)
    } else if (page === "datapusher") {
        baseURL = baseURL.replaceAll("datacollector", page)
    }


    const dataCollectorAxios = HttpService.get_dataCollectorAxios(apisStore.getState().serviceId);
    const objectsApi = new ObjectApi(undefined, baseURL, dataCollectorAxios);
    const managementApi = new ManagementApi(undefined, baseURL, dataCollectorAxios);
    const testingApi = new TestingApi(undefined, baseURL, dataCollectorAxios);
    const measurementApi = new MeasurementApi(undefined, baseURL, dataCollectorAxios);
    const logsApi = new LogsApi(undefined, baseURL, dataCollectorAxios);
    const toolsApi = new ToolsApi(undefined, baseURL, dataCollectorAxios);
    const backupApi = new BackupApi(undefined, baseURL, dataCollectorAxios);
    const actionsApi = new ActionsApi(undefined, baseURL, dataCollectorAxios);

    const dataWriterAxios = HttpService.get_dataPusherAxios(apisStore.getState().serviceId);
    const managementApiConst = new ManagementApiConst(undefined, baseURL, dataWriterAxios)
    const writerActionsApi = new ActionsApiWriter(undefined, baseURL, dataWriterAxios);
    const writerBackupApi = new BackupApiConst(undefined, baseURL, dataWriterAxios);
    const writerLogs = new writerLogsConst(undefined, baseURL, dataWriterAxios);

    return Object.freeze({
        objectsApi,
        managementApi,
        testingApi,
        measurementApi,
        logsApi,
        toolsApi,
        backupApi,
        managementApiConst,
        writerBackupApi,
        actionsApi,
        writerLogs,
        writerActionsApi
    })
}


export default Apis;