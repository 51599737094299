import React, {Component} from 'react';
import {Reader} from "reader/Reader";
import {DataTable} from "primereact/datatable";
import NotificationPopUp from "util/NotificationPopUp";
import {Column} from "primereact/column";
import Utils from "util/Utils";
import {Button} from "primereact/button";
import './ReaderHistory.scss';
import {confirmPopup, ConfirmPopup} from "primereact/confirmpopup";
import EditorReadonly from "components/general/EditorReadonly";
import {apisType} from "serviceapi/ApiImports";
import {apisStore} from "context/GlobalStates";
import {CLIENT_TIMEOUT, DEFAULT_OPTIONS} from "../../../service/HttpService";

type Props = {
    reader: Reader,
    updateReaders(): void,
    closeDialog(dialog: string): void
}

class State {
    backups: Array<any> = [];
    updated: boolean = false;
    selected: any = undefined;
    text: string = "";
    loading: boolean = true;
}

class ReaderHistory extends Component<Props, State> {
    private apis: apisType;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = new State();
        this.remove = this.remove.bind(this);
        this.apis = apisStore.getState().dataCollector
    }

    componentDidMount() {
        this.getBackups();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.state.updated) {
            this.getBackups();
            this.setState({updated: false})
        }
        if (this.state.selected !== prevState.selected) {
            this.getConfigText();
        }
    }

    private getBackups = () => {
        this.apis.backupApi?.getBackups([this.props.reader.readerInfo.id as string], DEFAULT_OPTIONS)
            .then(res => this.setState({backups: res.data, loading: false}))
            .catch(err => NotificationPopUp.show(err.message, 'error'))
    }

    private timestampToDate = (rowData: any) => {

        return Utils.timestampToReadableDate(rowData.timestamp)
    }

    private restore(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to restore reader with this backup?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.restoreReader(id)
            }
        });
    }

    private restoreReader = (id: number) => {

        this.apis.backupApi?.restoreBackup(this.props.reader.readerInfo.id!!, id, DEFAULT_OPTIONS)
            .then(() => {
                NotificationPopUp.show("Reader was restored successfully", "success")
                this.props.updateReaders();
                this.props.closeDialog("history")
            })
            .catch(err => NotificationPopUp.show(err.message, 'error'))
    }

    private remove(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to remove reader back up?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.accept(id)
            }
        });
    }

    private accept = (id: number) => {
        let tempArr: number[] = [id]
        this.apis.backupApi?.deleteBackup(tempArr, DEFAULT_OPTIONS)
            .then(() => this.setState({updated: true}))
            .catch(err => console.log(err))
    }

    private getConfigText = () => {
        this.apis.backupApi?.getConfigurationBackup(this.state.selected.id, DEFAULT_OPTIONS)
            .then(res => {
                this.setState({text: JSON.stringify(res.data, null, 4)})
            })
            .catch(err => NotificationPopUp.show(err.message, 'error'))
    }

    private functionality = (rowData: any) => {
        return <>
            <Button icon={"pi pi-history"}
                    className={"button p-button-info"}
                    onClick={event => this.restore(event, rowData.id)}
                    tooltip={"Restore reader config"}
            />
            <Button icon={"pi pi-trash"}
                    className={"button p-button-danger"}
                    onClick={event => this.remove(event, rowData.id)}
                    tooltip={"Remove reader config backup"}
            />
        </>

    }

    render() {
        return (
            <div className={"reader-backup"}>
                <div className={"data-table"}>
                    <DataTable value={this.state.backups}
                               style={{maxHeight: "50vw"}}
                               sortField={"timestamp"}
                               sortOrder={-1}
                               selectionMode={"single"}
                               selection={this.state.selected}
                               onSelectionChange={e => {
                                   this.setState({selected: e.value});
                               }}
                               loading={this.state.loading}
                    >
                        <Column field="timestamp" header={"Date"} body={this.timestampToDate}/>
                        <Column className={"column"} body={this.functionality}/>
                    </DataTable>
                </div>
                <div className={"editor"}>
                    <EditorReadonly text={this.state.text}/>
                </div>
                <ConfirmPopup/>
            </div>
        );
    }
}

export default ReaderHistory;