import Keycloak from "keycloak-js";
import Utils from "util/Utils";

const configFile = Utils.isDevMode() ? '/oidcDev.json' : '/oidc.json';

const _kc = new Keycloak(configFile);

type Permission = string
export const Permission = {
    DATAHUB_USE: "datahub.use",
}


/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void) => {
    _kc.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        pkceMethod: 'S256',
    })
        .then((authenticated) => {
            if (!authenticated) {
                console.log("User is not authenticated! Redirecting...");
                doLogin().then(() => {
                    console.log("Login successful!");
                    onAuthenticatedCallback();
                });
            } else {
                console.log(`User ${getUsername()} is authenticated.`);
                onAuthenticatedCallback();
            }
        })
        .catch((e) => {
            console.log("Failed to initialize Keycloak.")
            console.log(e)
        });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const getIdTokenParsed = () => _kc.idTokenParsed;

const isLoggedIn = () => !!_kc.token;

const isAuthenticated = () => _kc.authenticated

const getGroupPaths = () => _kc.tokenParsed?.membership;

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getName = () => _kc.tokenParsed?.name;

const getLastName = () => _kc.tokenParsed?.lastName;

const hasRole = (role: string) => _kc.hasRealmRole(role);

const getRoles = () => _kc.resourceAccess?.[_kc.clientId!!]?.roles ?? []

const isPermited = (permission: Permission) => _kc.hasResourceRole(permission);

const updateToken = () =>
    _kc.updateToken(1)
        .then(function(refreshed) {
            if (refreshed) {
                console.log('Token was successfully refreshed');
            } else {
                console.log('Token is still valid');
            }
        }).catch(function() {
        console.log('Failed to refresh the token, or the session has expired')
        doLogin()
    });


const getCompanyGroupPaths = () => {
    let groups = getGroupPaths()

    if(groups && groups.length > 0) {
        return groups.filter((groupPath: string) => groupPath.startsWith("/Companies/"))
    }

    return []
}


const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    isAuthenticated,
    getToken,
    getTokenParsed,
    getIdTokenParsed,
    getCompanyGroupPaths,
    getGroups: getGroupPaths,
    updateToken,
    getUsername,
    hasRole,
    getName,
    getLastName,
    isPermited,
    getRoles,
};

export default UserService;
