import React, {useId} from 'react';
import {Dialog} from "primereact/dialog";
import PipelineInputTester from "components/data-writer/PipelineConfigTester/PipelineInputTester";
import {Button} from "primereact/button";
import {Tooltip} from "react-tooltip";

type WriterTesterButtonProps = {
    editorFullConfig: any
}

const WriterTesterButton = ({editorFullConfig}: WriterTesterButtonProps) => {
    const id = useId()
    const [visible, setVisible] = React.useState(false);

    return <>
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <div data-tooltip-id={id}
             data-tooltip-content="Tester"
        >
            <Button icon={"pi pi-chart-bar"}
                    className={"p-button-sm p-button-secondary"}
                    onClick={() => setVisible(true)}
                // disabled={(!this.state.selectedPipeline || (this.state.editorFullConfig &&
                //     (!this.state.editorFullConfig.inputConfig.basePath ||
                //         !this.state.editorFullConfig.inputConfig.authToken ||
                //         this.state.editorFullConfig.inputConfig.implementationKey !== "ea-easas")))}
                    disabled={true}
            />
        </div>


        {visible &&
            <Dialog visible={visible}
                    onHide={() => setVisible(false)}
                    header={"Data Writer Tester"}
                    modal={false}
                    style={{minWidth: "400px", minHeight: "220px", maxHeight: "90vh", maxWidth: "95vw"}}
            >
                <div>
                    {editorFullConfig &&
                        <>
                            {editorFullConfig.inputConfig.basePath &&
                                editorFullConfig.inputConfig.authToken &&
                                editorFullConfig.inputConfig.implementationKey === "ea-easas" &&
                                <PipelineInputTester
                                    datasets={editorFullConfig.inputConfig.datasets}
                                    basePath={editorFullConfig.inputConfig.basePath}
                                    authToken={editorFullConfig.inputConfig.authToken}
                                />
                            }

                            {/*TODO PipelineOutputTester*/}
                        </>
                    }
                </div>
            </Dialog>
        }
    </>
};

export default WriterTesterButton;