class ApiConfig {
    static get baseUrl() {
        return this.baseUrlUnsafe as string;
    }

    static get baseUrlUnsafe() {
        let path = window.location.pathname;
        const [_, proto, url] = path.split("/");

        if (!(proto && url)) {
            return null;
        }
        let page = "datacollector";

        let hash = window.location.hash;
        if (hash === "#/data_writer") {
            page = "datapusher"
        }

        return `${proto}://${url}/${page}`
    }

    static getUrlParams() {
        if (!window.location.href.includes("?")||!window.location.href.includes("serviceId")) {
            return
        }

        console.log(window.location)
    }

}

export {ApiConfig};