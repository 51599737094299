import React, {RefObject} from 'react';
import {Toast, ToastSeverityType} from "primereact/toast";

class NotificationPopUp {
    static toast: RefObject<Toast> = React.createRef<Toast>();
    public static show = (details: any, status?: ToastSeverityType, title?: string) => {
        let s: ToastSeverityType;
        let t;

        if (status === undefined) {
            s = 'info';
        } else {
            s = status;
        }

        if (!title) {
            t = ""
        } else {
            t = title;
        }

        NotificationPopUp.toast.current?.show({severity: s, summary: t, detail: details});
    }

    public static showMultiple = (notificationArray: Array<any>, status?: ToastSeverityType) => {
        //DEFAULTS
        if (!status) {
            status = 'info';
        }

        let arr:Array<any> = notificationArray.map(obj => {
            return {
                severity: status,
                detail: obj
            }
        })
      console.log(arr)

    }

    public static showHTML = (c: any, status?: ToastSeverityType) => {
        let s: ToastSeverityType;

        if (status === undefined) {
            s = 'info';
        } else {
            s = status;
        }

        NotificationPopUp.toast.current?.show({severity: s, detail: <pre>{c}</pre>});
    }
}

export default NotificationPopUp;