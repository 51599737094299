import React, {useState} from 'react';
import {Card} from "primereact/card";
import {SelectButton} from "primereact/selectbutton";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

function UrlInputForm() {
    const [url, setUrl] = useState("")
    const [protocol, setProtocol] = useState("http")
    const [page, setPage] = useState("datacollector")

    const protocolOptions = ['http', 'https'];
    const optionsOfPages = [
        {name: 'Data Collector', value: "datacollector"},
        {name: 'Data Writer', value: "datawriter"}
    ];

    const goToPage = () => {
        if (page === "datacollector") {
            window.location.href = protocol + "/" + url;
        } else if (page === "datawriter") {
            window.location.href = protocol + "/" + url + "#/data_writer";
        }
    }

    const titleDiv = () => {
        return <div className={"title-and-image"}>
            Connection properties
            <img alt="logo"
                 src={process.env.PUBLIC_URL + "/EASAS.png"}
                 height={"45px"}
                 className="mr-1"/>
        </div>
    }

    const footer = (<div className="flex justify-content-between">
        <Button label="Proceed"
            icon="pi pi-reply"
            onClick={() => goToPage()}
            className="p-button-sm p-button-success disable-color-change"
            disabled={url.length === 0}
        />
    </div>);


    return <div className={"choose-window-full-size"}>
        <Card title={titleDiv()} footer={footer}>
            <div className={"inner-picker"}>
                            <span>
                                <label htmlFor="protocol"><div className={"text-padding"}>Page</div></label>
                                <SelectButton value={page}
                                              optionLabel="name"
                                              optionValue="value"
                                              options={optionsOfPages}
                                              onChange={(e) => {
                                                  if (e.value !== null) {
                                                      setPage(e.value)
                                                  }
                                              }}/>
                            </span>
                <div className={"select-div"}>
                                <span>
                                    <label htmlFor="protocol">
                                        <div className={"text-padding"}>URL</div>
                                    </label>
                                    <SelectButton value={protocol}
                                                  options={protocolOptions}
                                                  onChange={(e) => {
                                                      if (e.value !== null) {
                                                          setProtocol(e.value)
                                                      }
                                                  }}
                                                  id="protocol"
                                                  unselectable
                                                  className={"selection-button"}
                                    />
                                </span>
                    <div className={"input-class"}>
                        <InputText id="url-id"
                                   autoFocus
                                   value={url}
                                   onChange={(e) => setUrl(e.target.value)}
                                   placeholder={"Enter Server URL"}
                                   onKeyDown={(e) => {
                                       if ((e.code === "Enter" || e.code === "NumpadEnter") && url.length > 0) {
                                           goToPage()
                                       }
                                   }}
                        />
                    </div>
                </div>
            </div>
        </Card>
    </div>
}

export default UrlInputForm;