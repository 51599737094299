import React, {Component} from 'react';
import Editor, {Monaco} from "@monaco-editor/react";

type ReaderReadonlyProps = {
    text?: string,
}

type ReaderReadonlyState = {
}

class EditorReadonly extends Component<ReaderReadonlyProps,ReaderReadonlyState> {
    private editor?: any;

    private monaco?: Monaco;

    constructor(props: ReaderReadonlyProps) {
        super(props);
        this.state = {
        }
    }

    componentDidUpdate(prevProps: Readonly<ReaderReadonlyProps>, prevState: Readonly<ReaderReadonlyState>, snapshot?: any) {
        this.editor?.setScrollPosition({scrollTop: 0});
    }

    private onEditorMounted = (
        editor: any,
        monaco: Monaco
    ) => {
        this.editor = editor;
        this.monaco = monaco;

        editor.updateOptions({readOnly:true});
    }

    render() {
        return (
            <Editor
                height={"100%"}
                width={"100%"}
                defaultLanguage={"json"}
                value={this.props.text?this.props.text:""}
                theme={"vs-dark"}
                onMount={this.onEditorMounted}
            />
        );
    }
}

export default EditorReadonly;