import React, {useId} from 'react';
import {Button} from "primereact/button";
import {Tooltip} from "react-tooltip";

type WriterPipelineSaveButtonProps = {
    selectedPipeline: any,
    saveCallback: () => void
}

const WriterPipelineSaveButton = ({saveCallback,selectedPipeline}:WriterPipelineSaveButtonProps) => {
    const id = useId()

    return   <>
        <Tooltip id={id} style={{zIndex:9999}} place={"right"}/>
        <Button
        className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
        icon={"pi pi-save"}
        data-tooltip-id={id}
        data-tooltip-content="Save"
        onClick={() => saveCallback()}
        disabled={!selectedPipeline}
    />
    </>
};

export default WriterPipelineSaveButton;