import React from "react";
import "./Encoder.scss";
import Editor from "@monaco-editor/react";
import { ManagementApi } from "serviceapi/dataPusherApi";
import Utils from "../../../util/Utils";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

type EncoderProps = {
  config: any;
  updateConfig(config: any): void;
  id: string;
  managementApi: ManagementApi;
  readScript?: boolean;
  closeWindow(): void;
  setReadScript(value: boolean): void;
  updateWriters(): void;
};

class EncoderState {
  scriptCode: string = "";
  showConfigEditor: boolean = false;
  processorConfig: string = "";
}

class Base64 extends React.Component<EncoderProps, EncoderState> {
  constructor(props: Readonly<EncoderProps> | EncoderProps) {
    super(props);
    this.state = new EncoderState();
  }

  componentDidMount() {
    this.setState({
      scriptCode: Utils.base64Decode(
        this.props.config.processorConfig.script || ""
      ),
      processorConfig: JSON.stringify(
        this.props.config.processorConfig,
        null,
        4
      ),
      showConfigEditor: this.props.readScript || false,
    });
  }

  componentDidUpdate(prevProps: EncoderProps) {
    if (prevProps.readScript !== this.props.readScript) {
      this.setState({
        showConfigEditor: this.props.readScript || false,
        processorConfig: JSON.stringify(
          this.props.config.processorConfig,
          null,
          4
        ),
      });
    }
  }

  closeConfigEditor = () => {
    this.setState({ showConfigEditor: false });
    this.props.closeWindow();
    this.props.setReadScript(false);
  };

  handleSave = () => {
    const updatedConfig = {
      ...this.props.config,
      processorConfig: JSON.parse(this.state.processorConfig),
    };
    updatedConfig.processorConfig.script = Utils.base64Encode(
      this.state.scriptCode
    );

    this.props.updateConfig(updatedConfig);
    this.props.setReadScript(false);
    this.closeConfigEditor();
  };

  render() {
    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ flex: 1 }}>
          <Editor
            height="100%"
            width="100%"
            language="python"
            value={this.state.scriptCode}
            onChange={(val) => {
              this.setState({ scriptCode: val || "" });
            }}
            theme="vs-dark"
          />
        </div>
        <Dialog
          header="Edit Processor Config"
          visible={this.state.showConfigEditor}
          style={{ width: "50vw" }}
          onHide={this.closeConfigEditor}
        >
          <Editor
            height="400px"
            language="json"
            value={this.state.processorConfig}
            onChange={(val) => this.setState({ processorConfig: val || "" })}
            theme="vs-dark"
          />
          <div className="p-dialog-footer">
            <Button label="Save" icon="pi pi-check" onClick={this.handleSave} />
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={this.closeConfigEditor}
              className="p-button-secondary"
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Base64;
