import React from "react";
import {Reader} from "./Reader";
import Editor, {Monaco, loader} from "@monaco-editor/react";
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

type ReaderViewProps = {
    reader?: Reader,
    schema?: any,
    errorIsInEditor?(isThereError: any): void,
    readonly?: boolean,
    changed?(b: boolean): void
}

type ReaderViewState = {
    disableEditorChangeLister: boolean
}

loader.config({paths: {vs: '/vs'}});
loader.init();

class ReaderViewer extends React.Component<ReaderViewProps, ReaderViewState> {

    private ignoreNextTextUpdate: boolean = false;

    private editor?: monaco.editor.IStandaloneCodeEditor;

    private monaco?: Monaco;

    constructor(props: ReaderViewProps) {
        super(props);
        this.state = {
            disableEditorChangeLister: false
        }
    }

    componentDidUpdate(prevProps: Readonly<ReaderViewProps>, prevState: Readonly<ReaderViewState>, snapshot?: any) {
        if (prevProps.reader !== this.props.reader && this.props.reader) {
            this.setEditorValidation()
                .then(() => {
                    this.updateTextFromReader()
                        .then(() => {
                            if (this.monaco && this.editor) {
                                if (this.props.reader && this.props.reader.config) {
                                    this.editor.setModel(this.monaco.editor.createModel(this.props.reader.config, "json"));
                                }
                            }
                            this.forceUpdate();
                        })
                })
            if (prevProps.reader?.readerInfo.id !== this.props.reader.readerInfo.id) {
                this.editor?.setScrollPosition({scrollTop: 0});
            }
        }
    }

    private setEditorValidation = async () => {
        if (this.monaco && this.props.schema) {
            this.monaco.languages.json.jsonDefaults.setDiagnosticsOptions(
                {
                    validate: true,
                    enableSchemaRequest: true,
                    schemas: [
                        {
                            uri: "https://json-schema.org/",
                            fileMatch: ["*"],
                            schema: {
                                properties: this.props.schema.properties,
                                type: this.props.schema.type
                            }
                        }
                    ]
                })
        }
    }

    private updateTextFromReader = async () => {
        const reader = this.props.reader;
        if (!reader) {
            return;
        }

        if (!reader.modified && !reader.new) {
            return reader.reloadConfig()
        }
    }

    private onEditorMounted = (
        editor: any,
        monaco: Monaco
    ) => {
        this.editor = editor;
        this.monaco = monaco;
        editor.onDidChangeModelContent(() => {
            if (this.ignoreNextTextUpdate) {
                this.ignoreNextTextUpdate = false;
                return;
            }
            if (this.props.reader) {
                if (this.props.reader.config !== this.editor?.getValue()) {
                    this.props.reader.config = this.editor ? this.editor.getValue() : ""
                    if (this.props.changed) {
                        this.props.changed(false)
                    }
                }
            }
        })
    }

    private errorHandler = (e: any) => {
        if (e.length) {
            if (this.props.errorIsInEditor) {
                this.props.errorIsInEditor(true)
            }

        } else {
            if (this.props.errorIsInEditor) {
                this.props.errorIsInEditor(false)
            }
        }
    }

    render() {
        return (
            <Editor
                value={JSON.stringify(this.props.reader?.readerInfo, null, 3)}
                onValidate={this.errorHandler}
                height={"100%"}
                width={"100%"}
                defaultLanguage={"json"}
                theme={"vs-dark"}
                onMount={this.onEditorMounted}
            />
        );
    }

}

export {ReaderViewer}
