import React from "react";
import {Reader} from "./Reader";
import Editor, {loader, Monaco} from "@monaco-editor/react";
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import Utils from "../util/Utils";

type ReaderViewProps = {
    reader?: Reader,
    schema?: any,
    errorIsInEditor?(isThereError: any): void,
    readonly?: boolean,
    changed?(b: boolean): void
    mounted?(m: boolean): void
    meeterId?: string
    revealLine:boolean
    setRevealLine: () => void
    saveCallback: () => void
}

type ReaderViewState = {
    disableEditorChangeLister: boolean
}

loader.config({paths: {vs: '/vs'}});
loader.init();

class ReaderView extends React.Component<ReaderViewProps, ReaderViewState> {

    private ignoreNextTextUpdate: boolean = false;

    private editor?: monaco.editor.IStandaloneCodeEditor;

    private monaco?: Monaco;

    constructor(props: ReaderViewProps) {
        super(props);
        this.state = {
            disableEditorChangeLister: false
        }
    }

    componentDidUpdate(prevProps: Readonly<ReaderViewProps>, prevState: Readonly<ReaderViewState>, snapshot?: any) {
        if (this.props.reader) {
            if (prevProps.reader !== this.props.reader) {
                this.setEditorValidation()
                    .then(() => {
                        this.updateTextFromReader()
                            .then(() => {
                                if (this.monaco && this.editor) {
                                    if (this.props.reader && this.props.reader.config) {
                                        const scrollTop = this.editor.getScrollTop();
                                        this.editor.setModel(this.monaco.editor.createModel(this.props.reader.config, "json"));
                                        this.editor.setScrollTop(scrollTop);

                                        if(this.props.revealLine) {
                                            this.editor?.revealLine(Utils.lineOf(this.props.reader?.config as string, `"id": "${this.props.meeterId}"`) + 1);
                                            this.props.setRevealLine()
                                        }
                                    }
                                }
                                this.forceUpdate();

                            })
                    })
            } else {
                if (this.editor && this.props.meeterId && prevProps.meeterId !== this.props.meeterId) {
                    this.editor.revealLine(Utils.lineOf(this.props.reader?.config as string, `"id": "${this.props.meeterId}"`) + 1);
                    this.props.setRevealLine()
                }else if(this.props.revealLine && this.editor && !this.props.meeterId) {
                    this.editor.revealLine(1);
                    this.props.setRevealLine()
                }
            }

        } else {
            if (this.editor && this.monaco) {
                this.editor.setModel(this.monaco.editor.createModel("", "json"));
            }
        }
    }

    private setEditorValidation = async () => {
        if (this.monaco && this.props.schema) {
            this.monaco.languages.json.jsonDefaults.setDiagnosticsOptions(
                {
                    validate: true,
                    enableSchemaRequest: true,
                    schemas: [
                        {
                            uri: "https://json-schema.org/",
                            fileMatch: ["*"],
                            schema: {
                                properties: this.props.schema.properties,
                                type: this.props.schema.type
                            }
                        }
                    ]
                })
        }
    }

    private updateTextFromReader = async () => {
        const reader = this.props.reader;
        if (!reader) {
            return;
        }

        if (!reader.modified && !reader.new) {
            await reader.reloadConfig()
        }
    }

    private onEditorMounted = (
        editor: any,
        monaco: Monaco
    ) => {
        this.editor = editor;
        this.monaco = monaco;

        this.editor?.addCommand(this.monaco.KeyMod.CtrlCmd | this.monaco.KeyCode.KeyS, () => {
            this.props.saveCallback();
        })

        if (this.props.mounted) {
            this.props.mounted(true)
        }

        editor.onDidChangeModelContent(() => {
            if (this.ignoreNextTextUpdate) {
                this.ignoreNextTextUpdate = false;
                return;
            }
            if (this.props.reader && this.props.reader.config !== this.editor?.getValue()) {
                this.props.reader.config = this.editor ? this.editor.getValue() : ""
                if (this.props.changed) this.props.changed(false)
            }
        })
    }

    private errorHandler = (e: any) => {
        if (this.props.errorIsInEditor) {
            if (e.length) {
                this.props.errorIsInEditor(true)
            } else {
                this.props.errorIsInEditor(false)
            }
        }
    }

    render() {
        return (
            <Editor onValidate={this.errorHandler}
                    height={"100%"}
                    width={"100%"}
                    defaultLanguage={"json"}
                    theme={"vs-dark"}
                    onMount={this.onEditorMounted}
            />
        );
    }

}

export {ReaderView}
