import React from 'react';
import {ProgressSpinner} from "primereact/progressspinner";
import {Dialog} from "primereact/dialog";
import './PageDisablerOnLoading.scss';

type PageDisablerOnLoadingProps = {
    text: string;
}

class PageDisablerOnLoading extends React.Component<PageDisablerOnLoadingProps> {
    render() {
        return (
                <Dialog visible={true}
                        className={"page-load-dialog"}
                        onHide={() => {
                        }}
                        keepInViewport={false}
                        closeOnEscape={false}
                        closable={false}
                        blockScroll={true}
                        focusOnShow={false}
                        resizable={false}
                        draggable={false}
                        showHeader={false}
                >
                    <div className={"page-load-context"}>
                        <h3>
                            {this.props.text}
                        </h3>
                        <ProgressSpinner/>
                    </div>
                </Dialog>
        );
    }
}

export default PageDisablerOnLoading;