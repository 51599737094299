import React from 'react';
import LogViewer from "components/general/LogViewer/LogViewer";
import {apisStore} from "context/GlobalStates";
import {apisType} from "serviceapi/ApiImports";
import {LogLevel} from "serviceapi";

type WriterLogViewerProps = {
    from?: Date,
    to?: Date,
    autoUpdate?: boolean,
    pipelineId?: string,
    showLoggerName?: boolean,

    showProgressIndicator(): void,
    hideProgressIndicator(): void,
}

const PipelineLogViewer = ({
                               pipelineId,
                               showProgressIndicator,
                               hideProgressIndicator,
                               showLoggerName
                           }: WriterLogViewerProps) => {
    const apis: apisType = apisStore.getState().dataPusher;

    const fetchData = (from: number, to: number, level: LogLevel) => {
        let logQuery = {
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
            minLevel: level
        };

        if (pipelineId) {
            return apis.writerLogs!.getPipelineLog(pipelineId, true, true, true, true, logQuery)
        } else {
            return apis.writerLogs!.getGeneralLog(logQuery);
        }
    }

    if (!apis.writerLogs) {
        return <></>
    }

    return <LogViewer fetchData={fetchData}
                      showLoggerName={showLoggerName}
                      showProgressIndicator={showProgressIndicator}
                      hideProgressIndicator={hideProgressIndicator}
    />
};

export default PipelineLogViewer;