import React from 'react';
import {Tooltip} from "primereact/tooltip";
import {ProgressSpinner} from "primereact/progressspinner";
import {Reader} from "reader/Reader";

export const ReaderNameChecker = (editingReader?: any) => {
    const returnObject:any = { tooltip: false };

    const readerInfo = editingReader?.readerInfo;

    if (readerInfo) {
        const { readerName, id } = readerInfo;

        const string = readerName ?? `[${id}]`;
        const tooltipMessage = readerName || id;

        if (tooltipMessage && tooltipMessage.length > 30) {
            returnObject.string = tooltipMessage.slice(0, 30) + "...";
            returnObject.tooltipMessage = tooltipMessage;
            returnObject.tooltip = true;
        } else {
            returnObject.string = string;
        }
    }

    return returnObject;
};

export const LogHeaderHandler = (readerId?: string) => {
    let obj = Object({tooltip: false, name: "All logs"});

    if (readerId) {
        if (readerId.length > 30) {
            obj.name = "Reader " + readerId.slice(0, 30) + "... log";
            obj.tooltipMessage = readerId;
            obj.tooltip = true;
        } else {
            obj.name = "Reader " + readerId + " log";
        }
    }
    return SpinnerHeader(obj)
}
export const DialogHeaderHandler = (dialogName: string,name?:Reader) => {
    let obj = ReaderNameChecker(name);
    obj.name = "Reader " + obj.string + ` ${dialogName}`;
    return NormalHeader(obj)
}

export const TestHeaderHandler = (name?:Reader) => {
    let obj = ReaderNameChecker(name);
    obj.name = "Reader " + obj.string + " data writings based on current configuration";
    return SpinnerHeader(obj)
}

export const NormalHeader = (obj: any) => {
    return <>
        {obj.tooltip &&
            <Tooltip target=".long-name-tooltip"/>
        }
        <span className={"long-name-tooltip"} data-pr-tooltip={obj.tooltipMessage}>
            {obj.name}
        </span>
    </>
}
export const SpinnerHeader = (obj: any) => {
    return <div style={{
        display: "flex",
        gap: "30px",
        justifyContent: "space-between",
        height: "30px",
        marginRight: "10px"
    }}>
        {obj.tooltip &&
            <Tooltip target=".long-name-tooltip" />
        }
        <div className={"long-name-tooltip"} data-pr-tooltip={obj.tooltipMessage}>
            {obj.name}
        </div>
        <div>
            <ProgressSpinner id={"progress-spinner-indicator-id"}
                             style={{width: '30px', height: '30px'}}
                             strokeWidth="7"
                             animationDuration=".8s"/>
        </div>
    </div>
}

export const ShowLoadingIndicator = () => {
    if (document.getElementById("progress-spinner-indicator-id")) {
        document.getElementById("progress-spinner-indicator-id")!.style.display = "block";
    }
}

export const HideLoadingIndicator = () => {
    if (document.getElementById("progress-spinner-indicator-id")) {
        document.getElementById("progress-spinner-indicator-id")!.style.display = "none";
    }
}