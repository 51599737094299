import React, {Component} from 'react';
import './DataExport.scss';
import {ListBox} from "primereact/listbox";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import NotificationPopUp from "../../../util/NotificationPopUp";
import Utils from "../../../util/Utils";
import {apisType} from "../../../serviceapi/ApiImports";
import {ProgressSpinner} from "primereact/progressspinner";
import {apisStore} from "../../../context/GlobalStates";

type DataExportProps = {
    closeDialog(dialogName: string): void
}

class DataExportState {
    readers: Array<Wrapper> = [];
    writers: Array<Wrapper> = [];
    loading: boolean = true;
}

class Wrapper {
    constructor(item: any) {
        this.item = item
    }

    item: any;
    selected: boolean = true;
}

class DataExport extends Component<DataExportProps, DataExportState> {
    private mountedOnce = false
    private atLeastOne = false;
    private dataCollectorApi: apisType;
    private dataWriterApi: apisType;

    constructor(props: Readonly<DataExportProps>) {
        super(props);
        this.state = new DataExportState();
        this.readerTemplate = this.readerTemplate.bind(this);
        this.dataCollectorApi = apisStore.getState().dataCollector
        this.dataWriterApi = apisStore.getState().dataPusher
    }

    componentDidMount() {
        if (!this.mountedOnce) {
            if (this.dataCollectorApi.objectsApi && this.dataCollectorApi.objectsApi.getReaders) {
                this.dataCollectorApi.objectsApi.getReaders()!!
                    .then(res => {
                        if (res.data.length > 0) {
                            let resNewArr = res.data.map((obj) => {
                                return {
                                    item: obj,
                                    selected: true
                                }
                            })
                            this.setState({readers: resNewArr})
                        }
                    })
                    .finally(() => {
                        if (!this.atLeastOne) {
                            this.atLeastOne = true;
                        } else {
                            this.setState({loading: false})
                        }
                    })
            }

            if (this.dataWriterApi.managementApiConst && this.dataWriterApi.managementApiConst.getPipelines) {
                this.dataWriterApi.managementApiConst.getPipelines()
                    .then(res => {
                        if (res.data.length > 0) {
                            let resNewArr = res.data.map((obj) => {
                                return {
                                    item: obj,
                                    selected: true
                                }
                            })
                            this.setState({writers: resNewArr})
                        }
                    })
                    .finally(() => {
                        if (!this.atLeastOne) {
                            this.atLeastOne = true;
                        } else {
                            this.setState({loading: false})
                        }
                    })
            }
            this.mountedOnce = true;
        }
    }

    private readonly readerTemplate = (option: any) => {
        console.log(option)
        return (
            <div className={"item-template"}>
                <div className={"item-title"} style={{wordBreak: "break-all", maxWidth: "1000px"}}>
                    {
                        (option.item.readerName) ?
                            option.item.readerName
                            :
                            `[${option.item.id}]`
                    }
                </div>
                <div className={"item-checkbox"}>
                    <Checkbox checked={option.selected} onChange={e => {
                        this.setState({
                            readers: this.state.readers.map(r => {
                                if (r.item.id === option.item.id) {
                                    return {
                                        item: r.item,
                                        selected: e.checked
                                    }
                                }
                                return r
                            })
                        })
                    }}/>
                </div>
            </div>
        );
    }

    private readonly writerTemplate = (option: any) => {
        return (
            <div className={"item-template"}>
                <div className={"item-title"} style={{wordBreak: "break-all", maxWidth: "1000px"}}>
                    {
                        (option.item.name) ?
                            option.item.name
                            :
                            `[${option.item.id}]`
                    }
                </div>
                <div className={"item-checkbox"}>
                    <Checkbox checked={option.selected} onChange={e => {
                        this.setState({
                            writers: this.state.writers.map(r => {
                                if (r.item.id === option.item.id) {
                                    return {
                                        item: r.item,
                                        selected: e.checked
                                    }
                                }
                                return r
                            })
                        })
                    }}/>
                </div>
            </div>
        );
    }

    private selectAll = () => {
        let tempReadersArr = this.state.readers.map(item => {
            item.selected = true;
            return item;
        });

        let tempWritersArr = this.state.writers.map(item => {
            item.selected = true;
            return item;
        });

        this.setState({readers: tempReadersArr, writers: tempWritersArr})
        this.forceUpdate()
    }

    private deselectAll = () => {
        let tempReadersArr = this.state.readers.map(item => {
            item.selected = false;
            return item;
        });
        let tempWritersArr = this.state.writers.map(item => {
            item.selected = false;
            return item;
        });

        this.setState({readers: tempReadersArr, writers: tempWritersArr})
        this.forceUpdate()
    }

    private downloadData = () => {
        let collectorArr: Array<any> = [];
        let writerArr: Array<any> = [];

        Promise.all(
            this.state.readers.filter(item => item.selected).map(item => this.dataCollectorApi.managementApi?.getReaderConfig(item.item.id))
        )
            .then(res => {
                collectorArr = res.map(item => item?.data)

                let tempObj: any = {};
                if (collectorArr.length > 0) {
                    tempObj.dataCollector = collectorArr;
                }
                if (this.state.writers.length > 0) {
                    Promise.all(
                        this.state.writers.filter(item => item.selected).map(item => this.dataWriterApi.managementApiConst?.getPipelineConfig(item.item.id))
                    )
                        .then(res => {
                            writerArr = res.map(item => item?.data)
                            if (writerArr.length > 0) {
                                tempObj.dataWriter = writerArr;
                            }


                            this.exportJSON(tempObj);
                            NotificationPopUp.show("Export successful", "success");
                            this.props.closeDialog("export");

                        })
                        .catch((err) => {
                            NotificationPopUp.show(err.message, 'error');
                        })
                } else {
                    this.exportJSON(tempObj);
                    NotificationPopUp.show("Export successful", "success");
                    this.props.closeDialog("export");
                }
            })
            .catch((err) => {
                NotificationPopUp.show(err.message, 'error');
            })
    }

    private exportJSON = (arr: any) => {
        let fileName: string = "export.json";


        const file = new File([JSON.stringify(arr, null, 4)], fileName, {
            type: 'application/json',
        })

        Utils.downloadFile(file);
    }

    render() {
        if (this.state.loading) {
            return (<div className={"data-export-loading"}>
                <ProgressSpinner style={{overflow: "hidden"}}/>
            </div>)
        } else {
            if (this.state.readers.length > 0 || this.state.writers.length > 0) {
                return (
                    <div className={"reader-export-full-window"}>
                        <div className={"button-container"}>
                            <Button label={"Select all"}
                                    icon={"pi pi-plus"}
                                    onClick={this.selectAll}/>
                            <Button label={"Deselect all"}
                                    icon={"pi pi-minus"}
                                    onClick={this.deselectAll}/>
                        </div>
                        <div className={"both-lists"}>
                            {this.state.readers.length > 0 &&
                                <div className={"list-container"}>
                                    <div className={"table-name-text"}>Readers</div>
                                    <div className={"list-box"}>
                                        <ListBox options={this.state.readers}
                                                 filter
                                                 filterBy={"item.readerName,item.id"}
                                                 itemTemplate={this.readerTemplate}
                                                 filterPlaceholder={"Search By Id, Name"}
                                        />
                                    </div>
                                </div>
                            }
                            {this.state.writers.length > 0 &&
                                <div className={"list-container"}>
                                    <div className={"table-name-text"}>Writers</div>
                                    <div className={"list-box"}>
                                        <ListBox options={this.state.writers}
                                                 filter
                                                 filterBy={"item.name,item.id"}
                                                 itemTemplate={this.writerTemplate}
                                                 filterPlaceholder={"Search By Id, Name"}
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={"button-container"}>
                            <Button label={"Download"}
                                    icon={"pi pi-download"}
                                    onClick={this.downloadData}
                            />
                            <Button label={"Cancel"}
                                    className="p-button-secondary"
                                    icon={"pi pi-times"}
                                    onClick={() => this.props.closeDialog("export")}
                            />
                        </div>
                    </div>
                );
            } else {
                return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "20vh"}}>
                    <h2>No Readers or Pipelines to export</h2>
                </div>
            }
        }
    }
}

export default DataExport;