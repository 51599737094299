import React, {useId, useState} from 'react';
import {Button} from "primereact/button";
import {Reader} from "reader/Reader";
import {Dialog} from "primereact/dialog";
import {DialogHeaderHandler} from "components/dialogs/dialogHeaders";
import ReaderHistory from "components/data-collector/ReaderHistory/ReaderHistory";
import {Tooltip} from "react-tooltip";

type CollectorHistoryProps = {
    editingReader?: Reader,
    updateReaders: (readerId?: string) => void
}

const CollectorHistoryButton = ({editingReader, updateReaders}: CollectorHistoryProps) => {
    const id = useId()
    const [visible, setVisible] = useState(false);

    return <>
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <Button
            className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
            icon={"pi pi-history"}
            data-tooltip-id={id}
            data-tooltip-content="Archive of all previous reader configurations"
            onClick={() => setVisible(true)}
            disabled={editingReader == null}
        />

        {visible && editingReader &&
            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                header={DialogHeaderHandler("history", editingReader)}
                style={{width: "min(900px, 100vw)"}}
            >
                <ReaderHistory reader={editingReader}
                               closeDialog={() => setVisible(false)}
                               updateReaders={() => updateReaders(editingReader?.readerInfo.id)}
                />

            </Dialog>
        }
    </>
};

export default CollectorHistoryButton;