import React from 'react';
import {InputTextarea} from "primereact/inputtextarea";
import './Base64.scss';
import {Button} from "primereact/button";
import NotificationPopUp from "../../../util/NotificationPopUp";
import Editor from "@monaco-editor/react";
import Utils from "../../../util/Utils";

type Base64Props = {}

class Base64State {
    textogram:string ="";
    ciphertext:string="";

    error:boolean = false;
}

class Base64 extends React.Component<Base64Props, Base64State> {
    constructor(props: Readonly<Base64Props> | Base64Props) {
        super(props);
        this.state = new Base64State();
    }

    componentDidUpdate(prevProps: Readonly<Base64Props>, prevState: Readonly<Base64State>, snapshot?: any) {
    }

    private encryptButtonClicked = () => {
        if(this.state.textogram.length===0) {
            NotificationPopUp.show("Code field is empty")
        } else if(this.state.error) {
            NotificationPopUp.show("Code field have errors")
        } else {
            this.setState({ciphertext:Utils.base64Encode(this.state.textogram)})
        }
    }

    private encrypt = (value:any) => {
       this.setState({textogram:value})
    }

    private decryptButtonClicked = () => {
        if(this.state.ciphertext.length===0) {
            NotificationPopUp.show("Encode text field is empty")
        } else {
            this.setState({textogram:Utils.base64Decode(this.state.ciphertext)})
        }
    }

    private editorValidator = (e:any) => {
        if(e.length>0 && !this.state.error){
            this.setState({error:true})
        } else {
            if(this.state.error) {
                this.setState({error:false})
            }
        }
    }

    render() {
        return (
            <div className={"full-window"}>
                <div className={"text-area"}>
                    <div className={"text-container"}>
                        <b>Code</b>
                        <Button className="p-button-sm custom-button"
                                icon={"pi pi-copy"}
                                tooltip={"Copy"}
                                onClick={() => {
                                    if(this.state.textogram.length>0){
                                        NotificationPopUp.show("Code copied successfully",'success')
                                        return navigator.clipboard.writeText(this.state.textogram)
                                    } else {
                                        NotificationPopUp.show("Code field is empty")
                                    }

                                }}
                        />
                    </div>
                    <Editor
                        height={"min(100%, 350px)"}
                        width={"100%"}
                        defaultLanguage={"python"}
                        value={this.state.textogram}
                        onChange={this.encrypt}
                        theme={"vs-dark"}
                        onValidate={this.editorValidator}
                    />
                </div>
                <div className={"button-container"}>
                    <Button label={"Encode"}
                            className="p-button-sm custom-button"
                            icon={"pi pi-arrow-right"}
                            iconPos={"right"}
                            onClick={()=>this.encryptButtonClicked()}
                    />
                    <Button label={"Decode"}
                            className="p-button-sm custom-button"
                            icon={"pi pi-arrow-left"}
                            onClick={()=>this.decryptButtonClicked()}
                    />
                </div>
                <div className={"text-area"}>
                    <div className={"text-container"}>
                        <b>Encoded Text</b>
                        <Button className="p-button-sm custom-button"
                                icon={"pi pi-copy"}
                                tooltip={"Copy"}
                                onClick={() => {
                                    if(this.state.ciphertext.length>0) {
                                        NotificationPopUp.show("Encoded text copied successfully",'success')
                                        return navigator.clipboard.writeText(this.state.ciphertext)
                                    } else {
                                        NotificationPopUp.show("Encoded text field is empty")
                                    }
                                }}
                        />
                    </div>
                    <InputTextarea style={{width:"100%",height:"100%",minWidth:"350px",resize:"none",backgroundColor:"black"}}
                                   value={this.state.ciphertext}
                                   onChange={(e:any) => this.setState({ciphertext:e.target.value})}
                                   spellCheck={false}
                    />
                </div>
            </div>
        );
    }
}

export default Base64;