import React from "react";
import Editor, {Monaco} from "@monaco-editor/react";

type WriterViewProps = {
    writer?: any,
    schema?: any,
    errorIsInEditor?(isThereError: any): void,
    readonly?: boolean,
    changed?(b: boolean): void

    saveCallback: () => void
}

type WriterViewState = {
    text: string
    disableEditorChangeLister: boolean
}

class WriterView extends React.Component<WriterViewProps, WriterViewState> {

    private ignoreNextTextUpdate: boolean = false;

    editor?: any;

    private monaco?: Monaco;

    constructor(props: WriterViewProps) {
        super(props);
        this.state = {
            text: "",
            disableEditorChangeLister: false
        }
    }

    componentDidUpdate(prevProps: Readonly<WriterViewProps>, prevState: Readonly<WriterViewState>, snapshot?: any) {
        if (prevProps.writer !== this.props.writer) {
            if (this.props.writer === "") {
                this.setState({text: ""})
                this.editor?.setScrollPosition({scrollTop: 0});
                this.editor.updateOptions({readOnly: true});
            } else {
                this.setEditorValidation()
                    .then(() => {
                        if (this.monaco) {
                            this.editor.setModel(this.monaco.editor.createModel(JSON.stringify(this.props.writer, null, 4), "json"));
                        }
                        this.setState({text: JSON.stringify(this.props.writer, null, 4)})
                        this.editor?.updateOptions({readOnly: false});
                        this.editor?.setScrollPosition({scrollTop: 0});
                    })

            }
        }
    }

    private setEditorValidation = async () => {
        if (this.monaco && this.props.schema) {
            this.monaco.languages.json.jsonDefaults.setDiagnosticsOptions(
                {
                    validate: true,
                    enableSchemaRequest: true,
                    schemas: [
                        {
                            uri: "https://json-schema.org/",
                            fileMatch: ["*"],
                            schema: {
                                properties: this.props.schema.properties,
                                type: this.props.schema.type
                            }
                        }
                    ]
                })
        }
    }

    private onEditorMounted = (editor: any, monaco: Monaco) => {
        this.editor = editor;
        this.monaco = monaco;

        this.editor?.addCommand(this.monaco.KeyMod.CtrlCmd | this.monaco.KeyCode.KeyS, () => {
            this.props.saveCallback();
        })

        editor.onDidChangeModelContent(() => {
            if (this.ignoreNextTextUpdate) {
                this.ignoreNextTextUpdate = false;
                return;
            }
            if (this.props.writer) {
                if (this.props.changed) {
                    if (JSON.stringify(this.props.writer, null, 4) !== this.editor.getValue()) {
                        this.props.changed(true)
                    } else {
                        this.props.changed(false)
                    }
                }
            }
        })
    }

    private errorHandler = (e: any) => {
        if (e.length) {
            if (this.props.errorIsInEditor) {
                this.props.errorIsInEditor(true)
            }

        } else {
            if (this.props.errorIsInEditor) {
                this.props.errorIsInEditor(false)
            }
        }
    }

    render() {
        return (
            <Editor
                onValidate={this.errorHandler}
                height={"100%"}
                width={"100%"}
                defaultLanguage={"json"}
                value={this.state.text}
                theme={"vs-dark"}
                onMount={this.onEditorMounted}
            />
        );
    }

}

export {WriterView}