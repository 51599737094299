import React, {useId} from 'react';
import {Button} from "primereact/button";
import {Tooltip} from "react-tooltip";
import NotificationPopUp from "util/NotificationPopUp";
import {Reader} from "reader/Reader";

type RestartReaderButtonProps = {
    editingReader?: Reader
}

const RestartReaderButton = ({editingReader}: RestartReaderButtonProps) => {
    const id = useId()

    const [loading, setLoading] = React.useState(false)

    const restartReader = async() => {
        if (!editingReader) {
            NotificationPopUp.show("No reader selected", 'error');
            return
        }

        try {
            setLoading(true)
            await editingReader.restart()
            NotificationPopUp.show("Reader restarted", 'success')
        } catch (er:any) {
            NotificationPopUp.show(er.message, 'error')
        }

        setLoading(false)
    }

    return <>
        <Tooltip id={id} style={{zIndex: 9999}} place={"right"}/>
        <Button
            className={"function-buttons-container-container-button p-button-secondary p-button-sm"}
            icon={"pi pi-refresh"}
            data-tooltip-id={id}
            data-tooltip-content={"Restart"}
            onClick={() => restartReader()}
            disabled={editingReader == null}
            loading={loading}
        /></>
};

export default RestartReaderButton;